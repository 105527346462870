import { dashboardActions } from '../../constants/dashboardActionTypes';

const getSuperAdminDashboard = (payload) => {
  return {
    type: dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA,
    payload: payload,
  };
};

const getSuperAdminDashboardSuccess = (payload) => {
  return {
    type: dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA_SUCCESS,
    payload: payload,
  };
};

const getSuperAdminDashboardFail = (error) => {
  return {
    type: dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA_FAIL,
    payload: error,
  };
};

const getSuperAdminDashboardReset = (payload) => {
  return {
    type: dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA_RESET,
    payload: payload,
  };
};

const getTenantAdminDashboard = (payload) => {
  return {
    type: dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA,
    payload: payload,
  };
};

const getTenantAdminDashboardSuccess = (payload) => {
  return {
    type: dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA_SUCCESS,
    payload: payload,
  };
};

const getTenantAdminDashboardFail = (error) => {
  return {
    type: dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA_FAIL,
    payload: error,
  };
};

const getTenantAdminDashboardReset = (payload) => {
  return {
    type: dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA_RESET,
    payload: payload,
  };
};

const getProctorDashboard = (payload) => {
  return {
    type: dashboardActions.GET_PROCTORS_DASHBOARD_DATA,
    payload: payload,
  };
};

const getProctorDashboardSuccess = (payload) => {
  return {
    type: dashboardActions.GET_PROCTORS_DASHBOARD_DATA_SUCCESS,
    payload: payload,
  };
};

const getProctorDashboardFail = (error) => {
  return {
    type: dashboardActions.GET_PROCTORS_DASHBOARD_DATA_FAIL,
    payload: error,
  };
};

const getProctorDashboardReset = (payload) => {
  return {
    type: dashboardActions.GET_PROCTORS_DASHBOARD_DATA_RESET,
    payload: payload,
  };
};

const getUpcomingAssessment = (payload) => {
  return {
    type: dashboardActions.UPCOMING_ASSESSMENT_DATA,
    payload: payload,
  };
};

const getUpcomingAssessmentSuccess = (payload) => {
  return {
    type: dashboardActions.UPCOMING_ASSESSMENT_DATA_SUCCESS,
    payload: payload,
  };
};

const getUpcomingAssessmentFail = (error) => {
  return {
    type: dashboardActions.UPCOMING_ASSESSMENT_DATA_FAIL,
    payload: error,
  };
};

const getUpcomingAssessmentReset = (payload) => {
  return {
    type: dashboardActions.UPCOMING_ASSESSMENT_DATA_RESET,
    payload: payload,
  };
};

export {
  getSuperAdminDashboard,
  getSuperAdminDashboardSuccess,
  getSuperAdminDashboardFail,
  getSuperAdminDashboardReset,
  getTenantAdminDashboard,
  getTenantAdminDashboardSuccess,
  getTenantAdminDashboardFail,
  getTenantAdminDashboardReset,
  getProctorDashboard,
  getProctorDashboardSuccess,
  getProctorDashboardFail,
  getProctorDashboardReset,
  getUpcomingAssessment,
  getUpcomingAssessmentSuccess,
  getUpcomingAssessmentFail,
  getUpcomingAssessmentReset,
};
