import { proctoringActions } from '../../constants/proctoringActionTypes';

const getCandidateDetails = (payload) => {
  return {
    type: proctoringActions.GET_CANDIDATE_DETAIL,
    payload: payload,
  };
};
const getCandidateDetailsSuccess = (payload) => {
  return {
    type: proctoringActions.GET_CANDIDATE_DETAIL_SUCCESS,
    payload: payload,
  };
};
const getCandidateDetailsFail = (payload) => {
  return {
    type: proctoringActions.GET_CANDIDATE_DETAIL_FAIL,
    payload: payload,
  };
};
const getCandidateDetailsReset = (payload) => {
  return {
    type: proctoringActions.GET_CANDIDATE_DETAIL_RESET,
    payload: payload,
  };
};

const setDetailViewCandidate = (payload) => {
  return {
    type: proctoringActions.SET_DETAIL_VIEW_CANDIDATE,
    payload: payload,
  };
};
const setDetailViewCandidateSuccess = (payload) => {
  return {
    type: proctoringActions.SET_DETAIL_VIEW_CANDIDATE_SUCCESS,
    payload: payload,
  };
};
const setDetailViewCandidateFail = (payload) => {
  return {
    type: proctoringActions.SET_DETAIL_VIEW_CANDIDATE_FAIL,
    payload: payload,
  };
};
const setDetailViewCandidateReset = (payload) => {
  return {
    type: proctoringActions.SET_DETAIL_VIEW_CANDIDATE_RESET,
    payload: payload,
  };
};

const getExamDetails = (payload) => {
  return {
    type: proctoringActions.GET_EXAM_DETAILS,
    payload: payload,
  };
};
const getExamDetailsSuccess = (payload) => {
  return {
    type: proctoringActions.GET_EXAM_DETAILS_SUCCESS,
    payload: payload,
  };
};
const getExamDetailsFail = (payload) => {
  return {
    type: proctoringActions.GET_EXAM_DETAILS_FAIL,
    payload: payload,
  };
};
const getExamDetailsReset = (payload) => {
  return {
    type: proctoringActions.GET_EXAM_DETAILS_RESET,
    payload: payload,
  };
};

const getAlertProof = (payload) => {
  return {
    type: proctoringActions.GET_ALERT_PROOFS,
    payload: payload,
  };
};
const getAlertProofSuccess = (payload) => {
  return {
    type: proctoringActions.GET_ALERT_PROOFS_SUCCESS,
    payload: payload,
  };
};
const getAlertProofFail = (payload) => {
  return {
    type: proctoringActions.GET_ALERT_PROOFS_FAIL,
    payload: payload,
  };
};
const getAlertProofReset = (payload) => {
  return {
    type: proctoringActions.GET_ALERT_PROOFS_RESET,
    payload: payload,
  };
};
const postExamDetails = (payload) => {
  return {
    type: proctoringActions.POST_EXAM_DETAILS,
    payload: payload,
  };
};
const postExamDetailsSuccess = (payload) => {
  return {
    type: proctoringActions.POST_EXAM_DETAILS_SUCCESS,
    payload: payload,
  };
};
const postExamDetailsFail = (payload) => {
  return {
    type: proctoringActions.POST_EXAM_DETAILS_FAIL,
    payload: payload,
  };
};
const postExamDetailsReset = (payload) => {
  return {
    type: proctoringActions.POST_EXAM_DETAILS_RESET,
    payload: payload,
  };
};
export {
  getAlertProof,
  getAlertProofSuccess,
  getAlertProofFail,
  getAlertProofReset,
  getCandidateDetails,
  getCandidateDetailsSuccess,
  getCandidateDetailsFail,
  getCandidateDetailsReset,
  setDetailViewCandidate,
  setDetailViewCandidateSuccess,
  setDetailViewCandidateFail,
  setDetailViewCandidateReset,
  getExamDetails,
  getExamDetailsSuccess,
  getExamDetailsFail,
  getExamDetailsReset,
  postExamDetails,
  postExamDetailsSuccess,
  postExamDetailsFail,
  postExamDetailsReset,
};
