export const assessmentActions = {
  GET_ASSESSMENTS: 'GET_ASSESSMENTS',
  GET_ASSESSMENTS_SUCCESS: 'GET_ASSESSMENTS_SUCCESS',
  GET_ASSESSMENTS_FAIL: 'GET_ASSESSMENTS_FAIL',

  GET_ONGOING_EXAM: 'GET_ONGOING_EXAM',
  GET_ONGOING_EXAM_SUCCESS: 'GET_ONGOING_EXAM_SUCCESS',
  GET_ONGOING_EXAM_FAIL: 'GET_ONGOING_EXAM_FAIL',

  GET_ASSESSMENT_DETAILS: 'GET_ASSESSMENT_DETAILS',
  GET_ASSESSMENT_DETAILS_SUCCESS: 'GET_ASSESSMENT_DETAILS_SUCCESS',
  GET_ASSESSMENT_DETAILS_FAIL: 'GET_ASSESSMENT_DETAILS_FAIL',
  GET_ASSESSMENT_DETAILS_RESET: 'GET_ASSESSMENT_DETAILS_RESET',

  GET_ASSESSMENT_INFO: 'GET_ASSESSMENT_INFO',
  GET_ASSESSMENT_INFO_SUCCESS: 'GET_ASSESSMENT_INFO_SUCCESS',
  GET_ASSESSMENT_INFO_FAIL: 'GET_ASSESSMENT_INFO_FAIL',
  GET_ASSESSMENT_INFO_RESET: 'GET_ASSESSMENT_INFO_RESET',

  GET_ASSESSMENT_JANUS_DETAILS: 'GET_ASSESSMENT_JANUS_DETAILS',
  GET_ASSESSMENT_JANUS_DETAILS_SUCCESS: 'GET_ASSESSMENT_JANUS_DETAILS_SUCCESS',
  GET_ASSESSMENT_JANUS_DETAILS_FAIL: 'GET_ASSESSMENT_JANUS_DETAILS_FAIL',
  GET_ASSESSMENT_JANUS_DETAILS_RESET: 'GET_ASSESSMENT_JANUS_DETAILS_RESET',
  SET_JANUS_DETAILS: 'SET_JANUS_DETAILS',

  END_ASSESSMENTS: 'END_ASSESSMENTS',
  END_ASSESSMENTS_SUCCESS: 'END_ASSESSMENTS_SUCCESS',
  END_ASSESSMENTS_FAIL: 'END_ASSESSMENTS_FAIL',
  END_ASSESSMENTS_RESET: 'END_ASSESSMENTS_RESET',
  RESET_ASSESSMENT_REDUCER: 'RESET_ASSESSMENT_REDUCER',

  EXAM_EVENT: 'EXAM_EVENT',
  EXAM_EVENT_SUCCESS: 'EXAM_EVENT_SUCCESS',
  EXAM_EVENT_FAIL: 'EXAM_EVENT_FAIL',
  EXAM_EVENT_RESET: 'EXAM_EVENT_RESET',
};
