import { tenantActions } from '../../constants/actionTypes';

const INITIAL_STATE = {
  tenantList: [],
  error: {
    message: '',
  },
  tenantId: undefined,
  isTenantNameAvailable: false,
  tenantGeneralDetailsData: {},
  isTenantGeneralDetailsLoading: false,
  isTenantGeneralDetailsDataFetched: false,
  tenantGeneralDetailsSuccess: false,
  tenantGeneralDetailsFailure: false,
  tenantInfrastructureData: {},
  isTenantInfrastructureLoading: false,
  isTenantInfrastructureDetailsDataFetched: false,
  tenantInfrastructureSuccess: false,
  tenantInfrastructureFailure: false,
  isCandidateOnboardingAndVerificationRulesLoading: false,
  candidateOnboardingAndVerificationRules: [],
  candidateOnboardingAndVerificationSuccess: false,
  candidateOnboardingAndVerificationFailure: false,
  isProctoringRulesLoading: false,
  proctoringRules: [],
  proctoringRulesSuccess: false,
  proctoringRulesFailure: false,
  tenantInfrastructureSetupSuccess: false,
};

const tenantReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case tenantActions.GET_TENANT_LIST_SUCCESS: {
      return {
        ...state,
        tenantList: action?.payload,
      };
    }
    case tenantActions.GET_TENANT_LIST_FAIL: {
      return {
        ...state,
        error: action.error,
      };
    }
    case tenantActions.TENANT_GENERAL_DETAILS_ACTION: {
      return {
        ...state,
        isTenantGeneralDetailsLoading: true,
      };
    }
    case tenantActions.TENANT_GENERAL_DETAILS_ACTION_SUCCESS: {
      return {
        ...state,
        tenantId: action.payload.tenant_id,
        isTenantGeneralDetailsLoading: false,
        tenantGeneralDetailsSuccess: true,
        tenantGeneralDetailsFailure: false,
      };
    }
    case tenantActions.TENANT_GENERAL_DETAILS_ACTION_ERROR: {
      return {
        ...state,
        tenantGeneralDetailsFailure: true,
        isTenantGeneralDetailsLoading: false,
        tenantGeneralDetailsSuccess: false,
      };
    }
    case tenantActions.TENANT_GENERAL_DETAILS_ACTION_RESET: {
      return {
        ...state,

        tenantGeneralDetailsData: {},
        isTenantGeneralDetailsLoading: false,
        tenantGeneralDetailsFailure: false,
        tenantGeneralDetailsSuccess: false,
      };
    }
    case tenantActions.TENANT_INFRA_ACTION: {
      return {
        ...state,
        isTenantInfrastructureLoading: true,
      };
    }
    case tenantActions.TENANT_INFRA_ACTION_SUCCESS: {
      return {
        ...state,
        tenantInfrastructureData: action.payload,
        isTenantInfrastructureLoading: false,
        tenantInfrastructureSuccess: true,
        tenantInfrastructureFailure: false,
      };
    }
    case tenantActions.TENANT_INFRA_ACTION_ERROR: {
      return {
        ...state,
        tenantInfrastructureFailure: true,
        isTenantInfrastructureLoading: false,
        tenantInfrastructureSuccess: false,
      };
    }
    case tenantActions.TENANT_INFRA_ACTION_RESET: {
      return {
        ...state,
        isTenantInfrastructureLoading: false,
        tenantInfrastructureFailure: false,
        tenantInfrastructureSuccess: false,
      };
    }
    case tenantActions.GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST: {
      return {
        ...state,
        isCandidateOnboardingAndVerificationRulesLoading: true,
      };
    }
    case tenantActions.GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_SUCCESS: {
      return {
        ...state,
        isCandidateOnboardingAndVerificationRulesLoading: false,
        candidateOnboardingAndVerificationRules: action.payload,
      };
    }
    case tenantActions.GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_FAIL: {
      return {
        ...state,
        isCandidateOnboardingAndVerificationRulesLoading: false,
      };
    }
    case tenantActions.GET_PROCTORING_RULES_LIST: {
      return {
        ...state,
        isProctoringRulesLoading: true,
      };
    }
    case tenantActions.GET_PROCTORING_RULES_LIST_SUCCESS: {
      return {
        ...state,
        isProctoringRulesLoading: false,
        proctoringRules: action.payload,
      };
    }
    case tenantActions.GET_TENANT_GENERAL_DETAILS: {
      return {
        ...state,
        isTenantGeneralDetailsDataFetched: true,
      };
    }
    case tenantActions.GET_TENANT_GENERAL_DETAILS_SUCCESS: {
      return {
        ...state,
        isTenantGeneralDetailsDataFetched: false,
        tenantGeneralDetailsData: action.payload,
      };
    }
    case tenantActions.GET_TENANT_GENERAL_DETAILS_FAIL: {
      return {
        ...state,
        isTenantGeneralDetailsDataFetched: false,
      };
    }
    case tenantActions.GET_TENANT_INFRA_DETAILS: {
      return {
        ...state,
        isTenantInfrastructureDetailsDataFetched: true,
      };
    }
    case tenantActions.GET_TENANT_INFRA_DETAILS_SUCCESS: {
      return {
        ...state,
        isTenantInfrastructureDetailsDataFetched: false,
        tenantInfrastructureData: action.payload,
      };
    }
    case tenantActions.GET_TENANT_INFRA_DETAILS_FAIL: {
      return {
        ...state,
        tenantInfrastructureData: {},
        isTenantInfrastructureDetailsDataFetched: false,
      };
    }
    case tenantActions.TENANT_PROCTORING_RULES_ACTION: {
      return {
        ...state,
        isProctoringRulesLoading: true,
      };
    }
    case tenantActions.TENANT_PROCTORING_RULES_ACTION_SUCCESS: {
      return {
        ...state,
        isProctoringRulesLoading: false,
        proctoringRulesSuccess: true,
      };
    }
    case tenantActions.TENANT_PROCTORING_RULES_ACTION_ERROR: {
      return {
        ...state,
        isProctoringRulesLoading: false,
        proctoringRulesFailure: true,
      };
    }
    case tenantActions.TENANT_PROCTORING_RULES_ACTION_RESET: {
      return {
        ...state,
        proctoringRules: [],
        isProctoringRulesLoading: false,
        proctoringRulesFailure: false,
        proctoringRulesSuccess: false,
      };
    }
    case tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION: {
      return {
        ...state,
        isCandidateOnboardingAndVerificationRulesLoading: true,
      };
    }
    case tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_SUCCESS: {
      return {
        ...state,
        isCandidateOnboardingAndVerificationRulesLoading: false,
        candidateOnboardingAndVerificationSuccess: true,
      };
    }
    case tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_ERROR: {
      return {
        ...state,
        isCandidateOnboardingAndVerificationRulesLoading: false,
        candidateOnboardingAndVerificationFailure: true,
      };
    }
    case tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_RESET: {
      return {
        ...state,
        candidateOnboardingAndVerificationRules: [],
        isCandidateOnboardingAndVerificationRulesLoading: false,
        candidateOnboardingAndVerificationFailure: false,
        candidateOnboardingAndVerificationSuccess: false,
      };
    }
    case tenantActions.TENANT_ID_RESET: {
      return {
        ...state,
        tenantId: undefined,
      };
    }
    case tenantActions.TENANT_NAME_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        isTenantNameAvailable: action.payload,
      };
    }
    case tenantActions.TENANT_NAME_AVAILABILITY_RESET: {
      return {
        ...state,
        isTenantNameAvailable: false,
      };
    }
    case tenantActions.TENANT_INFRA_SETUP_SUCCESS: {
      return {
        ...state,
        tenantInfrastructureSetupSuccess: true,
      };
    }
    case tenantActions.TENANT_INFRA_SETUP_ERROR: {
      return {
        ...state,
        tenantInfrastructureSetupSuccess: false,
      };
    }
    case tenantActions.TENANT_INFRA_SETUP_RESET: {
      return {
        ...state,
        tenantInfrastructureSetupSuccess: false,
      };
    }

    default:
      return state;
  }
};

export default tenantReducer;
