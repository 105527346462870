import { put, takeLatest, all, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { reportActions } from '../../constants/reportActionTypes';
import { statusCode } from '../../constants/apis/apiStatus';
import {
  getAssessmentReportSuccess,
  getAssessmentReportFail,
  getCandidateDetailReportSuccess,
  getCandidateDetailReportFail,
  getCandidateViolationListSuccess,
  getCandidateViolationListFail,
  getViolationListSuccess,
  getViolationListFail,
  getReviewSessionDetailsSuccess,
  getReviewSessionDetailsFail,
  getAiAlertListSuccess,
  getAiAlertListFail,
  candidateStatusSuccess,
  candidateStatusFail,
  getAssessmentCandidatesSuccess,
  getAssessmentCandidatesFail,
  getReportCandidatesSuccess,
  getReportCandidatesFail,
  downloadCandidatesReportSuccess,
  downloadCandidatesReportFail,
  getExamVideoLinkSuccess,
  getExamVideoLinkFail,
  downloadCandidatesInDateRangeReportSuccess,
  downloadCandidatesInDateRangeReportFail,
} from '../actions/reportActions';
import {
  getAssessmentReportData as getAssessmentReportAPI,
  getDetailReport,
  getCandidateViolations,
  getViolationReport,
  getReviewSessionReport,
  getAIAlerts,
  setCandidateProctoringStatus,
  getAssessmentCandidates as getAssessmentCandidatesAPI,
  getReportCandidates as getReportCandidatesAPI,
  downloadCandidatesReportApi,
  getExamVideoLink as getExamVideoLinkAPI,
  downloadCandidatesInDateRangeReportApi,
} from '../api/reportApi';

import { setNotification } from '../actions';

export function* getAssessmentReportDataFunction(action) {
  const response = yield call(getAssessmentReportAPI, action.payload);
  try {
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getAssessmentReportSuccess(response));
    } else {
      yield put(getAssessmentReportSuccess(response));
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* getDetailReportFunction(action) {
  try {
    const response = yield call(getDetailReport, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(getCandidateDetailReportSuccess(response));
    } else {
      yield put(getCandidateDetailReportFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getCandidateViolationListFunction(action) {
  try {
    const response = yield call(getCandidateViolations, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getCandidateViolationListSuccess(response));
    } else {
      yield put(getCandidateViolationListFail(response?.response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getCandidateViolationsFunction(action) {
  try {
    const response = yield call(getViolationReport, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getViolationListSuccess(response));
    } else {
      yield put(getViolationListFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getReviewSessionReportFunction(action) {
  try {
    const response = yield call(getReviewSessionReport, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(getReviewSessionDetailsSuccess(response));
    } else {
      yield put(getReviewSessionDetailsFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getAIAlertListFunction(action) {
  try {
    const response = yield call(getAIAlerts, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(getAiAlertListSuccess(response));
    } else {
      yield put(getAiAlertListFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* setCandidateStatusFunction(action) {
  try {
    const response = yield call(setCandidateProctoringStatus, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(candidateStatusSuccess(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(candidateStatusFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getAssessmentCandidatesFunction(action) {
  const response = yield call(getAssessmentCandidatesAPI, action.payload);
  try {
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getAssessmentCandidatesSuccess(response));
    } else {
      yield put(getAssessmentCandidatesFail(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* getReportCandidatesFunction(action) {
  const response = yield call(getReportCandidatesAPI, action.payload);
  try {
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getReportCandidatesSuccess(response));
    } else {
      yield put(getReportCandidatesFail(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* downloadCandidatesReportFunction(action) {
  const response = yield call(downloadCandidatesReportApi, action.payload);
  try {
    if (response?.status?.code === statusCode.Ok200) {
      yield put(downloadCandidatesReportSuccess(response));
      yield put(
        setNotification({
          data: response?.status?.message || i18n.t('something.wrong'),
          severity: 'success',
        })
      );
    } else {
      yield put(downloadCandidatesReportFail(response));
      yield put(
        setNotification({
          data: i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* getExamVideoLinkFunction(action) {
  const response = yield call(getExamVideoLinkAPI, action.payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(getExamVideoLinkSuccess(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'success',
        })
      );
    } else {
      yield put(getExamVideoLinkFail(response));
      yield put(
        setNotification({
          data: i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    console.log('Error', err);
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* downloadCandidatesInDateRangeReportFunction(action) {
  const response = yield call(downloadCandidatesInDateRangeReportApi, action.payload);
  try {
    if (response?.status?.code === statusCode.Ok200) {
      yield put(downloadCandidatesInDateRangeReportSuccess(response));
      yield put(
        setNotification({
          data: response?.status?.message || i18n.t('something.wrong'),
          severity: 'success',
        })
      );
    } else {
      yield put(downloadCandidatesInDateRangeReportFail(response));
      yield put(
        setNotification({
          data: i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(
    reportActions.GET_ASSESSMENT_REPORT_DATA,
    getAssessmentReportDataFunction
  );
  yield takeLatest(
    reportActions.GET_CANDIDATE_DETAIL_REPORT,
    getDetailReportFunction
  );
  yield takeLatest(
    reportActions.GET_CANDIDATE_VIOLATION_LIST,
    getCandidateViolationListFunction
  );
  yield takeLatest(
    reportActions.GET_VIOLATION_LIST,
    getCandidateViolationsFunction
  );
  yield takeLatest(
    reportActions.GET_REVIEW_SESSION_DETAILS,
    getReviewSessionReportFunction
  );
  yield takeLatest(reportActions.GET_AI_ALERT_LIST, getAIAlertListFunction);
  yield takeLatest(
    reportActions.CANDIDATE_PROCTORING_STATUS,
    setCandidateStatusFunction
  );

  yield takeLatest(
    reportActions.GET_ASSESSMENT_CANDIDATES_DATA,
    getAssessmentCandidatesFunction
  );

  yield takeLatest(
    reportActions.GET_CANDIDATES_DATA,
    getReportCandidatesFunction
  );

  yield takeLatest(
    reportActions.DOWNLOAD_CANDIDATES_REPORT,
    downloadCandidatesReportFunction
  );

  yield takeLatest(reportActions.GET_EXAM_VIDEO_LINK, getExamVideoLinkFunction);

  yield takeLatest(
    reportActions.DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT,
    downloadCandidatesInDateRangeReportFunction
  );
}

export default function* reportSaga() {
  yield all([watchDummy()]);
}
