import React from 'react';
import './NotFoundPage.scss';

const NotFoundPage = () => {
  return (
    <>
      <div className="">NotFoundPage</div>
    </>
  );
};

export default NotFoundPage;
