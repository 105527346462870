import * as React from 'react';

const SettingsSvg = () => {
  return (
    <svg
      id="ico_settings"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="bx-cog" transform="translate(-0.077)">
        <path
          id="Path_43627"
          data-name="Path 43627"
          d="M12,16a4,4,0,1,0-4-4A4,4,0,0,0,12,16Zm0-6a2,2,0,1,1-2,2A2.027,2.027,0,0,1,12,10Z"
          fill="#fff"
        />
        <path
          id="Path_43628"
          data-name="Path 43628"
          d="M2.845,16.136l1,1.73a2.056,2.056,0,0,0,2.73.73L7.1,18.29A8.1,8.1,0,0,0,9,19.4V20a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2v-.6a8.132,8.132,0,0,0,1.9-1.111l.529.306a2.057,2.057,0,0,0,2.731-.731l1-1.729a2,2,0,0,0-.731-2.732l-.5-.292a7.718,7.718,0,0,0,0-2.224l.5-.292a2,2,0,0,0,.731-2.732l-1-1.729A2.053,2.053,0,0,0,17.425,5.4L16.9,5.71A8.1,8.1,0,0,0,15,4.6V4a2,2,0,0,0-2-2H11A2,2,0,0,0,9,4v.6A8.132,8.132,0,0,0,7.1,5.709L6.575,5.4a2.055,2.055,0,0,0-2.731.732l-1,1.729A2,2,0,0,0,3.576,10.6l.505.292a7.683,7.683,0,0,0,0,2.223l-.5.292a2,2,0,0,0-.731,2.733Zm3.326-2.758a5.654,5.654,0,0,1,0-2.756A1,1,0,0,0,5.7,9.514l-1.123-.65,1-1.729L6.72,7.8a1,1,0,0,0,1.188-.142,6.071,6.071,0,0,1,2.384-1.4A1,1,0,0,0,11,5.3V4h2V5.3a1,1,0,0,0,.708.956,6.083,6.083,0,0,1,2.384,1.4A1,1,0,0,0,17.28,7.8l1.144-.661,1,1.729L18.3,9.514a1,1,0,0,0-.47,1.108,5.654,5.654,0,0,1,0,2.756,1,1,0,0,0,.471,1.108l1.123.649-1,1.729L17.28,16.2a1,1,0,0,0-1.188.142,6.071,6.071,0,0,1-2.384,1.4A1,1,0,0,0,13,18.7L13,20H11V18.7a1,1,0,0,0-.708-.956,6.083,6.083,0,0,1-2.384-1.4A.992.992,0,0,0,6.72,16.2l-1.144.662-1-1.729L5.7,14.486a1,1,0,0,0,.471-1.108Z"
          fill="#fff"
        />
      </g>
      <rect
        id="Rectangle_3871"
        data-name="Rectangle 3871"
        width="24"
        height="24"
        fill="none"
      />
    </svg>
  );
};

export default SettingsSvg;
