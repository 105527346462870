import { dashboardActions } from '../../constants/dashboardActionTypes';

const INITIAL_STATE = {
  superAdminData: {},
  isSuperAdminDataLoading: false,
  tenantAdminData: {},
  isTenantAdminDataLoading: false,
  proctorsDashboardData: {},
  isProctorDashboardDataLoading: false,
  upcomingAssessmentData: [],
  isUpcomingAssessmentDataLoading: false,
};

const dashboardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA: {
      return {
        ...state,
        isSuperAdminDataLoading: true,
      };
    }
    case dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        isSuperAdminDataLoading: false,
        superAdminData: action.payload,
      };
    }
    case dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA_FAIL: {
      return {
        ...state,
        isSuperAdminDataLoading: false,
        superAdminData: {},
      };
    }
    case dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA_RESET: {
      return {
        ...state,
        isSuperAdminDataLoading: false,
        superAdminData: {},
      };
    }
    case dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA: {
      return {
        ...state,
        isTenantAdminDataLoading: true,
      };
    }
    case dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        isTenantAdminDataLoading: false,
        tenantAdminData: action.payload,
      };
    }
    case dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA_FAIL: {
      return {
        ...state,
        isTenantAdminDataLoading: false,
        tenantAdminData: {},
      };
    }
    case dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA_RESET: {
      return {
        ...state,
        isTenantAdminDataLoading: false,
        tenantAdminData: {},
      };
    }
    case dashboardActions.GET_PROCTORS_DASHBOARD_DATA: {
      return {
        ...state,
        isProctorDashboardDataLoading: true,
      };
    }
    case dashboardActions.GET_PROCTORS_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        isProctorDashboardDataLoading: false,
        proctorsDashboardData: action.payload,
      };
    }
    case dashboardActions.GET_PROCTORS_DASHBOARD_DATA_FAIL: {
      return {
        ...state,
        isProctorDashboardDataLoading: false,
        proctorsDashboardData: {},
      };
    }
    case dashboardActions.GET_PROCTORS_DASHBOARD_DATA_RESET: {
      return {
        ...state,
        isProctorDashboardDataLoading: false,
        proctorsDashboardData: {},
      };
    }
    case dashboardActions.UPCOMING_ASSESSMENT_DATA: {
      return {
        ...state,
        isUpcomingAssessmentDataLoading: true,
      };
    }
    case dashboardActions.UPCOMING_ASSESSMENT_DATA_SUCCESS: {
      return {
        ...state,
        isUpcomingAssessmentDataLoading: false,
        upcomingAssessmentData: action.payload,
      };
    }
    case dashboardActions.UPCOMING_ASSESSMENT_DATA_FAIL: {
      return {
        ...state,
        isUpcomingAssessmentDataLoading: false,
      };
    }
    case dashboardActions.UPCOMING_ASSESSMENT_DATA_RESET: {
      return {
        ...state,
        isUpcomingAssessmentDataLoading: false,
        upcomingAssessmentData: [],
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
