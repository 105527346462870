import React from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'iiht-proctoring-ui-components/build/components/Divider';
import { useTheme } from 'iiht-proctoring-ui-components/build/components/Styles';
import Typography from 'iiht-proctoring-ui-components/build/components/Typography';
import Container from 'iiht-proctoring-ui-components/build/components/Container';
import './footerStyle.scss';

const FooterView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <footer>
      <Container className="footer" maxWidth="xl">
        <Divider sx={{ m: 0 }} variant="middle" />
        <div className="display-flex">
          <div className="flex2">
            <Typography
              sx={{ mt: 0.5, ml: 6 }}
              color={theme.palette.grey.P600}
              display="flex"
              variant="caption"
            >
              {t('proctiringFooterText')}
            </Typography>
          </div>
          <div className="flex2">
            <Typography
              sx={{ mt: 0.5, mr: 2, justifyContent: 'end' }}
              color={theme.palette.grey.P600}
              display="flex"
              variant="caption"
            >
              {t('copyright')}
            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default FooterView;
