export const userAuthenticationAPI = {
  login: 'user_manager/api/v1/login',
  reset_password: 'user_manager/api/v1/forgot_password',
  logoutEndpoint: 'user_manager/api/v1/logout',
  refreshTokenEndpoint: 'user_manager/api/v1/token/refresh',
  changePassword: 'user_manager/api/v1/change_password',
  profileDetails: 'user_manager/api/v1/user',
};

export const onboardingFormAPI = {
  proctors: 'user_manager/api/v1/users',
  proctor: 'user_manager/api/v1/user',
  addProctor: 'user_manager/api/v1/register',
  updateProctor: 'user_manager/api/v1/user',
  deleteProctor: 'user_manager/api/v1/user',
};

export const tenantAPI = {
  tenantList: 'tenant-onboarding/api/v1/tenants',
  tenant_name_availability: 'tenant-onboarding/api/v1/tenant_name',
  tenant_general_details: 'tenant-onboarding/api/v1/tenant',
  tenant_infrastructure: 'tenant-onboarding/api/v1/tenant_infra',
  tenant_infrastructure_: 'tenant-onboarding/api/v1/tenantinfra',
  get_candidate_onboarding_and_verification_rules:
    'rules_manager/api/v1/onboarding_and_verification_rules_params',
  candidate_onboarding_and_verification_rule:
    'rules_manager/api/v1/onboarding_and_verification_rules_param',
  candidate_onboarding_and_verification_rule_post:
    'rules_manager/api/v1/onboarding_and_verification_rules_param_post',
  candidate_onboarding_and_verification_rule_put:
    'rules_manager/api/v1/onboarding_and_verification',
  proctoring_rules: 'rules_manager/api/v1/proctoring_rules_params',
  proctoring_rule: 'rules_manager/api/v1/proctoring_rules_param',
  proctoring_rule_put: 'rules_manager/api/v1/proctoring_rules',
  proctoring_rule_post: 'rules_manager/api/v1/proctoring_rules_param_post',
  tenant_infrastructure_setup: 'infra_manager/api/v1/tenant_infra_onboarding',
};

export const tenantAdminAPI = {
  tenantAdminList: 'user_manager/api/v1/users',
  tenantAdmin: 'user_manager/api/v1/user',
  addTenantAdmin: 'user_manager/api/v1/register',
  updateTenantAdmin: 'user_manager/api/v1/user',
  deleteTenantAdmin: 'user_manager/api/v1/user',
};

export const assessmentAPI = {
  assessments: 'exam_manager/api/v1/assessments',
  onGoingExam: 'dashboard/api/v1/ongoing_exams/',
  assessmentDetails: 'exam_manager/api/v1/test_takers_in_waiting',
  assessmentInfo: 'exam_manager/api/v1/exam_details',
  assessmentJanusDetails: 'exam_manager/api/v1/room_id',
  endAssessment: 'exam_manager/api/v1/end_exam',
  examEvent: 'exam_manager/api/v1/exam_event',
  onGoingExamRoomDetails: 'exam_manager/api/v1/ongoing_exams',
};

export const approverAPI = {
  roomScanChecklist: 'exam_manager/api/v1/check_list_superset',
  matchFaceScanData: 'exam_manager/api/v1/verification/face',
  candidateDetails: 'exam_manager/api/v1/test_taker',
  testTakerVerificationStatus: 'exam_manager/api/v1/test_taker_verification',
};

export const proctoringAPI = {
  candidateDetails: 'exam_manager/api/v1/test_taker',
  examDetails: 'exam_manager/api/v1/exam_details',
  alertProof: 'exam_manager/api/v1/alert',
  getWaitingCandidates: 'exam_manager/api/v1/test_takers_waiting_for_verification',
};

export const reportAPI = {
  assessmentReport: 'reports/api/v1/assessments',
  detailReportView: 'reports/api/v1/report_details',
  violationList: 'reports/api/v1/violations',
  reviewSessionDetail: 'reports/api/v1/review_session_details',
  getAIAlert: 'reports/api/v1/alerts',
  candidateStatus: 'exam_manager/api/v1/test_taker_onboarding',
  assessmentCandidates: 'reports/api/v1/candidates',
  getExamVideoLink: 'exam_manager/api/v1/exam_video',
  postExamComment: 'reports/api/v1/notepad',
  getExamComment: 'reports/api/v1/get_comments',
};

export const dashboardAPI = {
  superAdmin: 'dashboard/api/v1/super_admin',
  tenantAdmin: 'dashboard/api/v1/tenant_admin',
  proctors: 'dashboard/api/v1/proctor',
  upcomingAssessment: 'exam_manager/api/v1/assessments',
};

export const thirdAeye = {
  candidateMobDetails: 'lms/live_updates/api/v1/mob',
  getJanusDetailsMob: 'lms/test_taker_module/api/v1/room'
}