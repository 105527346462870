import { assessmentActions } from '../../constants/assessmentActionTypes';

const INITIAL_STATE = {
  assessmentList: [],
  onGoingExamList:[],
  assessmentTestTakerList: [],
  assessmentInfo: {},
  loading: false,
  error: '',
  janusDetails: {},
  isAssessmentEndedLoading: undefined,
  isAssessmentEnded: false,
  loadingDetails: false,
};

const assessmentReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case assessmentActions.GET_ASSESSMENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case assessmentActions.GET_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentList: action?.payload?.data,
      };
    }
    case assessmentActions.GET_ASSESSMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }

    case assessmentActions.GET_ONGOING_EXAM: {
      return {
        ...state,
        loading: true,
      };
    }
    case assessmentActions.GET_ONGOING_EXAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        onGoingExamList: action?.payload?.data,
      };
    }
    case assessmentActions.GET_ONGOING_EXAM_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }

    case assessmentActions.GET_ASSESSMENT_DETAILS: {
      return {
        ...state,
        loading: true,
      };
    }
    case assessmentActions.GET_ASSESSMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentTestTakerList: action?.payload?.data?.data,
      };
    }
    case assessmentActions.GET_ASSESSMENT_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case assessmentActions.GET_ASSESSMENT_DETAILS_RESET: {
      return {
        ...state,
        loading: false,
        assessmentTestTakerList: {},
      };
    }
    case assessmentActions.GET_ASSESSMENT_INFO: {
      return {
        ...state,
        loading: false,
        assessmentTestTakerList: {},
      };
    }
    case assessmentActions.GET_ASSESSMENT_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentInfo: action.payload.data.data,
      };
    }
    case assessmentActions.GET_ASSESSMENT_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        assessmentInfo: {},
      };
    }
    case assessmentActions.GET_ASSESSMENT_INFO_RESET: {
      return {
        ...state,
        loading: false,
        assessmentInfo: {},
      };
    }

    case assessmentActions.GET_ASSESSMENT_JANUS_DETAILS: {
      return {
        ...state,
        loadingDetails: true,
        janusDetails: {},
      };
    }
    case assessmentActions.GET_ASSESSMENT_JANUS_DETAILS_SUCCESS: {
      return {
        ...state,
        loadingDetails: false,
        janusDetails: action.payload?.data?.data,
      };
    }
    case assessmentActions.GET_ASSESSMENT_JANUS_DETAILS_FAIL: {
      return {
        ...state,
        loadingDetails: false,
        janusDetails: {},
      };
    }
    case assessmentActions.GET_ASSESSMENT_JANUS_DETAILS_RESET: {
      return {
        ...state,
        loadingDetails: false,
        janusDetails: {},
      };
    }
    case assessmentActions.SET_JANUS_DETAILS: {
      return {
        ...state,
        loadingDetails: false,
        janusDetails: action.payload,
      };
    }
    case assessmentActions.END_ASSESSMENTS: {
      return {
        ...state,
        isAssessmentEndedLoading: true,
      };
    }
    case assessmentActions.END_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        isAssessmentEndedLoading: false,
        isAssessmentEnded: true,
      };
    }

    case assessmentActions.END_ASSESSMENTS_FAIL: {
      return {
        ...state,
        isAssessmentEndedLoading: false,
        isAssessmentEnded: false,
      };
    }
    case assessmentActions.END_ASSESSMENTS_RESET: {
      return {
        ...state,
        isAssessmentEndedLoading: undefined,
        isAssessmentEnded: false,
      };
    }

    case assessmentActions.RESET_ASSESSMENT_REDUCER: {
      return {
        ...INITIAL_STATE,
        assessmentInfo: state.assessmentInfo,
      };
    }

    default:
      return state;
  }
};
export default assessmentReducer;
