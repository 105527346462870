export const drawerWidth = 250;
export const translator = {
  localhost3000: 'http://localhost:3000',
  localhost3001: 'http://localhost:3001',
  localhost3002: 'http://localhost:3002',
  baseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://dev.aeye.pro/apps',
  qaBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://qa.aeye.pro',
  stageBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://staging.aeye.pro/apps',
  prodBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://aeye.pro/apps',
  uatBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://uat.aeye.pro/apps',
  hotfixBaseURL: process?.env?.REACT_APP_API_ENDPOINT || 'https://uat.aeye.pro/apps',
  wsUrl: process?.env?.REACT_APP_WEBSOCKET_ENDPOINT || `wss://dev.aeye.pro/apps/`,
  storageAccountName: process?.env?.REACT_APP_STORAGE_ACCOUNT_NAME || 'porctoringnonprodsa',
};

export const durationToStoreMedia = [8, 9, 10];

export const dashboardActions = {
  apiAction: 'FETCH_DATA',
};

export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';

export const loginActions = {
  LOGIN_ACTION_ERROR: 'LOGIN_ACTION_ERROR',
  LOGIN_ACTION: 'LOGIN_ACTION',
  LOGIN_ACTION_SUCCESS: 'LOGIN_ACTION_SUCCESS',
  LOGIN_ACTION_RESET: 'LOGIN_ACTION_RESET',

  LOGOUT_ACTION: 'LOGOUT_ACTION',
  LOGOUT_ACTION_SUCCESS: 'LOGOUT_ACTION_SUCCESS',
  LOGOUT_ACTION_ERROR: 'LOGOUT_ACTION_ERROR',
  LOGOUT_ACTION_RESET: 'LOGOUT_ACTION_RESET',

  FORGET_PASSWORD_ACTION: 'FORGET_PASSWORD_ACTION',
  FORGET_PASSWORD_ACTION_ERROR: 'FORGET_PASSWORD_ACTION_ERROR',
  FORGET_PASSWORD_ACTION_SUCCESS: 'FORGET_PASSWORD_ACTION_SUCCESS',
  FORGET_PASSWORD_ACTION_RESET: 'FORGET_PASSWORD_ACTION_RESET',

  RESET_PASSWORD_ACTION: 'RESET_PASSWORD_ACTION',
  RESET_PASSWORD_ACTION_ERROR: 'RESET_PASSWORD_ACTION_ERROR',
  RESET_PASSWORD_ACTION_SUCCESS: 'RESET_PASSWORD_ACTION_SUCCESS',
  RESET_PASSWORD_ACTION_RESET: 'RESET_PASSWORD_ACTION_RESET',

  CHANGE_PASSWORD_ACTION: 'CHANGE_PASSWORD_ACTION',
  CHANGE_PASSWORD_ACTION_ERROR: 'CHANGE_PASSWORD_ACTION_ERROR',
  CHANGE_PASSWORD_ACTION_SUCCESS: 'CHANGE_PASSWORD_ACTION_SUCCESS',
  CHANGE_PASSWORD_ACTION_RESET: 'CHANGE_PASSWORD_ACTION_RESET',

  EDIT_PROFILE_DETAILS_ACTION: 'EDIT_PROFILE_DETAILS_ACTION',
  EDIT_PROFILE_DETAILS_ERROR: 'EDIT_PROFILE_DETAILS_ERROR',
  EDIT_PROFILE_DETAILS_SUCCESS: 'EDIT_PROFILE_DETAILS_SUCCESS',
  EDIT_PROFILE_DETAILS_RESET: 'EDIT_PROFILE_DETAILS_RESET',
};

export const notificationActions = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',
};

export const tenantActions = {
  GET_TENANT_LIST: 'GET_TENANT_LIST',
  GET_TENANT_LIST_SUCCESS: 'GET_TENANT_LIST_SUCCESS',
  GET_TENANT_LIST_FAIL: 'GET_TENANT_LIST_FAIL',

  GET_TENANT_GENERAL_DETAILS: 'GET_TENANT_GENERAL_DETAILS',
  GET_TENANT_GENERAL_DETAILS_SUCCESS: 'GET_TENANT_GENERAL_DETAILS_SUCCESS',
  GET_TENANT_GENERAL_DETAILS_FAIL: 'GET_TENANT_GENERAL_DETAILS_FAIL',

  TENANT_GENERAL_DETAILS_ACTION: 'TENANT_GENERAL_DETAILS_ACTION',
  TENANT_GENERAL_DETAILS_ACTION_SUCCESS:
    'TENANT_GENERAL_DETAILS_ACTION_SUCCESS',
  TENANT_GENERAL_DETAILS_ACTION_ERROR: 'TENANT_GENERAL_DETAILS_ACTION_ERROR',
  TENANT_GENERAL_DETAILS_ACTION_RESET: 'TENANT_GENERAL_DETAILS_ACTION_RESET',

  GET_TENANT_INFRA_DETAILS: 'GET_TENANT_INFRA_DETAILS',
  GET_TENANT_INFRA_DETAILS_SUCCESS: 'GET_TENANT_INFRA_DETAILS_SUCCESS',
  GET_TENANT_INFRA_DETAILS_FAIL: 'GET_TENANT_INFRA_DETAILS_FAIL',

  TENANT_INFRA_ACTION: 'TENANT_INFRA_ACTION',
  TENANT_INFRA_ACTION_SUCCESS: 'TENANT_INFRA_ACTION_SUCCESS',
  TENANT_INFRA_ACTION_ERROR: 'TENANT_INFRA_ACTION_ERROR',
  TENANT_INFRA_ACTION_RESET: 'TENANT_INFRA_ACTION_RESET',

  TENANT_INFRA_SETUP: 'TENANT_INFRA_SETUP',
  TENANT_INFRA_SETUP_SUCCESS: 'TENANT_INFRA_SETUP_SUCCESS',
  TENANT_INFRA_SETUP_ERROR: 'TENANT_INFRA_SETUP_ERROR',
  TENANT_INFRA_SETUP_RESET: 'TENANT_INFRA_SETUP_RESET',

  GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST:
    'GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST',
  GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_SUCCESS:
    'GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_SUCCESS',
  GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_FAIL:
    'GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_FAIL',

  GET_PROCTORING_RULES_LIST: 'GET_PROCTORING_RULES_LIST',
  GET_PROCTORING_RULES_LIST_SUCCESS: 'GET_PROCTORING_RULES_LIST_SUCCESS',
  GET_PROCTORING_RULES_LIST_FAIL: 'GET_PROCTORING_RULES_LIST_FAIL',

  TENANT_PROCTORING_RULES_ACTION: 'TENANT_PROCTORING_RULES_ACTION',
  TENANT_PROCTORING_RULES_ACTION_SUCCESS:
    'TENANT_PROCTORING_RULES_ACTION_SUCCESS',
  TENANT_PROCTORING_RULES_ACTION_ERROR: 'TENANT_PROCTORING_RULES_ACTION_ERROR',
  TENANT_PROCTORING_RULES_ACTION_RESET: 'TENANT_PROCTORING_RULES_ACTION_RESET',

  TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION:
    'TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION',
  TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_SUCCESS:
    'TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_SUCCESS',
  TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_ERROR:
    'TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_ERROR',
  TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_RESET:
    'TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_RESET',

  TENANT_ID_RESET: 'TENANT_ID_RESET',

  TENANT_NAME_AVAILABILITY: 'TENANT_NAME_AVAILABILITY',
  TENANT_NAME_AVAILABILITY_SUCCESS: 'TENANT_NAME_AVAILABILITY_SUCCESS',
  TENANT_NAME_AVAILABILITY_FAIL: 'TENANT_NAME_AVAILABILITY_FAIL',
  TENANT_NAME_AVAILABILITY_RESET: 'TENANT_NAME_AVAILABILITY_RESET',

  GET_PROCTORING_SEGREGATED_RULES_LIST: 'GET_PROCTORING_SEGREGATED_RULES_LIST',
  GET_PROCTORING_SEGREGATED_RULES_LIST_SUCCESS:
    'GET_PROCTORING_SEGREGATED_RULES_LIST_SUCCESS',
  GET_PROCTORING_SEGREGATED_RULES_LIST_FAIL:
    'GET_PROCTORING_SEGREGATED_RULES_LIST_FAIL',

  GET_PROCTORING_SEGREGATED_RULES_LIST_RESET:
    'GET_PROCTORING_SEGREGATED_RULES_LIST_RESET',
};

export const janusActions = {
  START: 'START',
  CONNECT_LOCAL: 'CONNECT_LOCAL',
  ADD_PARTICIPANT: 'ADD_PARTICIPANT',
  REMOVE_PARTICIPANT: 'REMOVE_PARTICIPANT',
  RESET_PARTICIPANT: 'RESET_PARTICIPANT',
  ADD_SCREEN_SHARING: 'ADD_SCREEN_SHARING',
  SET_PARTICIPANT_ALERT: 'SET_PARTICIPANT_ALERT',
  RESET_PARTICIPANT_ALERT: 'RESET_PARTICIPANT_ALERT',
  RESET_PARTICIPANT_ALL_ALERTS: 'RESET_PARTICIPANT_ALL_ALERTS',
  END: 'END',
  RESET: 'RESET',
  RESET_JANUS_REDUCER: 'RESET_JANUS_REDUCER',
  CANDIDATE_REMOVE: 'CANDIDATE_REMOVE',
  CANDIDATE_REMOVE_RESET: 'CANDIDATE_REMOVE_RESET',
};

export const approverAction = {
  GET_ROOM_SCAN_CHECK_LIST_ACTION: 'GET_ROOM_SCAN_CHECK_LIST_ACTION',
  GET_ROOM_SCAN_CHECK_LIST_ACTION_SUCCESS:
    'GET_ROOM_SCAN_CHECK_LIST_ACTION_SUCCESS',
  GET_ROOM_SCAN_CHECK_LIST_ACTION_ERROR:
    'GET_ROOM_SCAN_CHECK_LIST_ACTION_ERROR',

  MATCH_FACE_SCAN_DATA: 'MATCH_FACE_SCAN_DATA',
  MATCH_FACE_SCAN_DATA_SUCCESS: 'MATCH_FACE_SCAN_DATA_SUCCESS',
  MATCH_FACE_SCAN_DATA_FAIL: 'MATCH_FACE_SCAN_DATA_FAIL',
  MATCH_FACE_SCAN_DATA_RESET: 'MATCH_FACE_SCAN_DATA_RESET',

  GET_CANDIDATE_DETAILS: 'GET_CANDIDATE_DETAILS',
  GET_CANDIDATE_DETAILS_SUCCESS: 'GET_CANDIDATE_DETAILS_SUCCESS',
  GET_CANDIDATE_DETAILS_FAIL: 'GET_CANDIDATE_DETAILS_FAIL',
  GET_CANDIDATE_DETAILS_RESET: 'GET_CANDIDATE_DETAILS_RESET',

  UPDATE_TEST_TAKER_VERIFICATION_STATUS:
    'UPDATE_TEST_TAKER_VERIFICATION_STATUS',
  UPDATE_TEST_TAKER_VERIFICATION_STATUS_SUCCESS:
    'UPDATE_TEST_TAKER_VERIFICATION_STATUS_SUCCESS',
  UPDATE_TEST_TAKER_VERIFICATION_STATUS_FAIL:
    'UPDATE_TEST_TAKER_VERIFICATION_STATUS_FAIL',
  UPDATE_TEST_TAKER_VERIFICATION_STATUS_RESET:
    'UPDATE_TEST_TAKER_VERIFICATION_STATUS_RESET',
};
