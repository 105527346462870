import { assessmentActions } from '../../constants/assessmentActionTypes';

const getAssessments = (payload) => {
  return {
    type: assessmentActions.GET_ASSESSMENTS,
    payload: payload,
  };
};
const getAssessmentsSuccess = (assessments) => {
  return {
    type: assessmentActions.GET_ASSESSMENTS_SUCCESS,
    payload: assessments,
  };
};
const getAssessmentsFail = (error) => {
  return {
    type: assessmentActions.GET_ASSESSMENTS_FAIL,
    payload: error,
  };
};

const getOnGoingExam = (payload) => {
  return {
    type: assessmentActions.GET_ONGOING_EXAM,
    payload: payload,
  };
};
const getOnGoingExamSuccess = (assessments) => {
  return {
    type: assessmentActions.GET_ONGOING_EXAM_SUCCESS,
    payload: assessments,
  };
};
const getOnGoingExamFail = (error) => {
  return {
    type: assessmentActions.GET_ONGOING_EXAM_FAIL,
    payload: error,
  };
};

const getAssessmentInfo = (id) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_INFO,
    payload: id,
  };
};
const getAssessmentInfoSuccess = (details) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_INFO_SUCCESS,
    payload: details,
  };
};
const getAssessmentInfoFail = (error) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_INFO_FAIL,
    payload: error,
  };
};
const getAssessmentInfoReset = (payload) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_INFO_RESET,
    payload: payload,
  };
};

const getAssessmentDetails = (id) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_DETAILS,
    payload: id,
  };
};
const getAssessmentDetailsSuccess = (details) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_DETAILS_SUCCESS,
    payload: details,
  };
};
const getAssessmentDetailsFail = (error) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_DETAILS_FAIL,
    payload: error,
  };
};
const getAssessmentDetailsReset = (payload) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_DETAILS_RESET,
    payload: payload,
  };
};

const getAssessmentJanusDetails = (id) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_JANUS_DETAILS,
    payload: id,
  };
};
const getAssessmentJanusDetailsSuccess = (details) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_JANUS_DETAILS_SUCCESS,
    payload: details,
  };
};
const getAssessmentJanusDetailsFail = (error) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_JANUS_DETAILS_FAIL,
    payload: error,
  };
};
const getAssessmentJanusDetailsReset = (payload) => {
  return {
    type: assessmentActions.GET_ASSESSMENT_JANUS_DETAILS_RESET,
    payload: payload,
  };
};
const setJanusDetails = (payload) => {
  return {
    type: assessmentActions.SET_JANUS_DETAILS,
    payload: payload,
  };
};

const endAssessments = (payload) => {
  return {
    type: assessmentActions.END_ASSESSMENTS,
    payload: payload,
  };
};
const endAssessmentsSuccess = (payload) => {
  return {
    type: assessmentActions.END_ASSESSMENTS_SUCCESS,
    payload: payload,
  };
};
const endAssessmentsFail = (payload) => {
  return {
    type: assessmentActions.END_ASSESSMENTS_FAIL,
    payload: payload,
  };
};

const endAssessmentsReset = (payload) => {
  return {
    type: assessmentActions.END_ASSESSMENTS_RESET,
    payload: payload,
  };
};

const assessmentsReset = (payload) => {
  return {
    type: assessmentActions.RESET_ASSESSMENT_REDUCER,
    payload: payload,
  };
};

const examEvent = (payload) => {
  return {
    type: assessmentActions.EXAM_EVENT,
    payload: payload,
  };
};
const examEventSuccess = (payload) => {
  return {
    type: assessmentActions.EXAM_EVENT_SUCCESS,
    payload: payload,
  };
};
const examEventFail = (payload) => {
  return {
    type: assessmentActions.EXAM_EVENT_FAIL,
    payload: payload,
  };
};

const examEventReset = (payload) => {
  return {
    type: assessmentActions.EXAM_EVENT_RESET,
    payload: payload,
  };
};

export {
  getAssessments,
  getAssessmentsSuccess,
  getAssessmentsFail,
  getOnGoingExam,
  getOnGoingExamSuccess,
  getOnGoingExamFail,
  getAssessmentDetails,
  getAssessmentDetailsSuccess,
  getAssessmentDetailsFail,
  getAssessmentDetailsReset,
  getAssessmentInfo,
  getAssessmentInfoSuccess,
  getAssessmentInfoFail,
  getAssessmentInfoReset,
  getAssessmentJanusDetails,
  getAssessmentJanusDetailsSuccess,
  getAssessmentJanusDetailsFail,
  getAssessmentJanusDetailsReset,
  endAssessments,
  endAssessmentsFail,
  endAssessmentsSuccess,
  endAssessmentsReset,
  assessmentsReset,
  examEvent,
  examEventSuccess,
  examEventReset,
  examEventFail,
  setJanusDetails,
};
