import { put, takeLatest, all, call } from 'redux-saga/effects';
import { proctoringActions } from '../../constants/proctoringActionTypes';
import { statusCode } from '../../constants/apis/apiStatus';
import {
  getCandidateDetails,
  getExamDetails,
  getAlertDetails,
  postExamDetails,
} from '../api/proctoring';
import {
  getCandidateDetailsSuccess,
  getCandidateDetailsFail,
  getExamDetailsSuccess,
  getExamDetailsFail,
  getAlertProofSuccess,
  getAlertProofFail,
  postExamDetailsSuccess,
  postExamDetailsFail,
} from '../actions/proctoringActions';
import { setNotification } from '../actions/notificationActions';

export function* getCandidateDetailsFunction(action) {
  try {
    const response = yield call(getCandidateDetails, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(getCandidateDetailsSuccess(response));
    } else {
      yield put(getCandidateDetailsFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getExamDetailsFunction(action) {
  try {
    const response = yield call(getExamDetails, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(getExamDetailsSuccess(response));
    } else {
      yield put(getExamDetailsFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* postExamDetailsFunction(action) {
  try {
    const response = yield call(postExamDetails, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(postExamDetailsSuccess(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(postExamDetailsFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getAlertsFunction(action) {
  try {
    const response = yield call(getAlertDetails, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(getAlertProofSuccess(response));
    } else {
      yield put(getAlertProofFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* watchDummy() {
  yield takeLatest(
    proctoringActions.GET_CANDIDATE_DETAIL,
    getCandidateDetailsFunction
  );
  yield takeLatest(proctoringActions.GET_EXAM_DETAILS, getExamDetailsFunction);
  yield takeLatest(proctoringActions.GET_ALERT_PROOFS, getAlertsFunction);
  yield takeLatest(
    proctoringActions.POST_EXAM_DETAILS,
    postExamDetailsFunction
  );
}

export default function* proctoringSaga() {
  yield all([watchDummy()]);
}
