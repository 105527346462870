export const tenantAdminActions = {
  GET_TENANT_ADMINS: 'GET_TENANT_ADMINS',
  GET_TENANT_ADMINS_SUCCESS: 'GET_TENANT_ADMINS_SUCCESS',
  GET_TENANT_ADMINS_FAIL: 'GET_TENANT_ADMINS_FAIL',

  GET_TENANT_ADMIN_DETAILS: 'GET_TENANT_ADMIN_DETAILS',
  GET_TENANT_ADMIN_DETAILS_SUCCESS: 'GET_TENANT_ADMIN_DETAILS_SUCCESS',
  GET_TENANT_ADMIN_DETAILS_FAIL: 'GET_TENANT_ADMIN_DETAILS_FAIL',

  ADD_TENANT_ADMIN: 'ADD_TENANT_ADMIN',
  ADD_TENANT_ADMIN_SUCCESS: 'ADD_TENANT_ADMIN_SUCCESS',
  ADD_TENANT_ADMIN_FAIL: 'ADD_TENANT_ADMIN_FAIL',
  ADD_TENANT_ADMIN_RESET: 'ADD_TENANT_ADMIN_RESET',

  SET_TENANT_ADMIN_DETAILS: 'SET_TENANT_ADMIN_DETAILS',
  SET_TENANT_ADMIN_DETAILS_SUCCESS: 'SET_TENANT_ADMIN_DETAILS_SUCCESS',
  SET_TENANT_ADMIN_DETAILS_FAIL: 'SET_TENANT_ADMIN_DETAILS_FAIL',
  SET_TENANT_ADMIN_DETAILS_RESET: 'SET_TENANT_ADMIN_DETAILS_RESET',

  DELETE_TENANT_ADMIN: 'DELETE_TENANT_ADMIN',
  DELETE_TENANT_ADMIN_SUCCESS: 'DELETE_TENANT_ADMIN_SUCCESS',
  DELETE_TENANT_ADMIN_FAIL: 'DELETE_TENANT_ADMIN_FAIL',
};
