import { reportActions } from '../../constants/reportActionTypes';

const INITIAL_STATE = {
  assessmentReportData: [],
  isAssessmentReportDataLoading: false,
  candidateDetails: {},
  isCandidateDetailsLoading: false,
  isViolationDetailsLoading: false,
  candidateViolationList: [],
  loading: false,
  violationsList: [],
  reviewSessionDetails: [],
  isReviewSessionDetailsLoading: false,
  alertMessages: [],
  loadingAlertMessages: false,
  isStatusSetSuccessfully: false,
  buttonLoading: false,
  proctoringDetails: {},
  assessmentCandidates: [],
  isAssessmentCandidatesLoading: false,
  reportCandidates: [],
  isReportCandidatesLoading: false,
};

const reportReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case reportActions.GET_ASSESSMENT_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        assessmentReportData: action.payload,
      };
    }
    case reportActions.GET_ASSESSMENT_REPORT_DATA_RESET: {
      return {
        ...state,
        assessmentReportData: [],
      };
    }
    case reportActions.GET_CANDIDATE_DETAIL_REPORT: {
      return {
        ...state,
        isCandidateDetailsLoading: true,
      };
    }
    case reportActions.GET_CANDIDATE_DETAIL_REPORT_SUCCESS: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
        candidateDetails: action.payload?.data?.data,
      };
    }
    case reportActions.GET_CANDIDATE_DETAIL_REPORT_FAIL: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
      };
    }
    case reportActions.GET_CANDIDATE_DETAIL_REPORT_RESET: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
        candidateDetails: {},
      };
    }

    case reportActions.GET_CANDIDATE_VIOLATION_LIST: {
      return {
        ...state,
        isViolationDetailsLoading: true,
      };
    }
    case reportActions.GET_CANDIDATE_VIOLATION_LIST_SUCCESS: {
      return {
        ...state,
        isViolationDetailsLoading: false,
        candidateViolationList: action.payload?.data?.data,
      };
    }
    case reportActions.GET_CANDIDATE_VIOLATION_LIST_FAIL: {
      return {
        ...state,
        isViolationDetailsLoading: false,
      };
    }
    case reportActions.GET_CANDIDATE_VIOLATION_LIST_RESET: {
      return {
        ...state,
        isViolationDetailsLoading: false,
        candidateViolationList: [],
      };
    }

    case reportActions.GET_VIOLATION_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case reportActions.GET_VIOLATION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        violationsList: action.payload?.data?.data,
      };
    }
    case reportActions.GET_VIOLATION_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case reportActions.GET_VIOLATION_LIST_RESET: {
      return {
        ...state,
        loading: false,
        violationsList: [],
      };
    }

    case reportActions.GET_REVIEW_SESSION_DETAILS: {
      return {
        ...state,
        isReviewSessionDetailsLoading: true,
      };
    }
    case reportActions.GET_REVIEW_SESSION_DETAILS_SUCCESS: {
      return {
        ...state,
        isReviewSessionDetailsLoading: false,
        reviewSessionDetails: action?.payload?.data?.data,
      };
    }
    case reportActions.GET_REVIEW_SESSION_DETAILS_FAIL: {
      return {
        ...state,
        isReviewSessionDetailsLoading: false,
      };
    }
    case reportActions.GET_REVIEW_SESSION_DETAILS_RESET: {
      return {
        ...state,
        isReviewSessionDetailsLoading: false,
        reviewSessionDetails: [],
        isStatusSetSuccessfully: false,
        buttonLoading: false,
        proctoringDetails: {},
      };
    }

    case reportActions.GET_AI_ALERT_LIST: {
      return {
        ...state,
        loadingAlertMessages: true,
      };
    }
    case reportActions.GET_AI_ALERT_LIST_SUCCESS: {
      return {
        ...state,
        loadingAlertMessages: false,
        alertMessages: action?.payload?.data?.data,
        buttonLoading: false,
      };
    }
    case reportActions.GET_AI_ALERT_LIST_FAIL: {
      return {
        ...state,
        loadingAlertMessages: false,
      };
    }
    case reportActions.GET_AI_ALERT_LIST_RESET: {
      return {
        ...state,
        loadingAlertMessages: false,
        alertMessages: [],
        isStatusSetSuccessfully: false,
        buttonLoading: false,
        proctoringDetails: {},
      };
    }
    case reportActions.CANDIDATE_PROCTORING_STATUS: {
      return {
        ...state,
        isStatusSetSuccessfully: false,
        buttonLoading: true,
      };
    }
    case reportActions.CANDIDATE_PROCTORING_STATUS_SUCCESS: {
      return {
        ...state,
        isStatusSetSuccessfully: true,
        buttonLoading: false,
        proctoringDetails: action?.payload?.data?.data,
      };
    }
    case reportActions.CANDIDATE_PROCTORING_STATUS_FAIL: {
      return {
        ...state,
        isStatusSetSuccessfully: false,
        buttonLoading: false,
      };
    }
    case reportActions.GET_ASSESSMENT_CANDIDATES_DATA: {
      return {
        ...state,
        isAssessmentCandidatesLoading: false,
      };
    }
    case reportActions.GET_ASSESSMENT_CANDIDATES_DATA_SUCCESS: {
      return {
        ...state,
        assessmentCandidates: action.payload,
        isAssessmentCandidatesLoading: false,
      };
    }
    case reportActions.GET_ASSESSMENT_CANDIDATES_DATA_FAIL: {
      return {
        ...state,
        assessmentCandidates: [],
        isAssessmentCandidatesLoading: false,
      };
    }
    case reportActions.GET_ASSESSMENT_CANDIDATES_DATA_RESET: {
      return {
        ...state,
        assessmentCandidates: [],
        isAssessmentCandidatesLoading: false,
      };
    }

    case reportActions.GET_CANDIDATES_DATA: {
      return {
        ...state,
        isReportCandidatesLoading: true,
      };
    }
    case reportActions.GET_CANDIDATES_DATA_SUCCESS: {
      return {
        ...state,
        reportCandidates: action.payload,
        isReportCandidatesLoading: false,
      };
    }
    case reportActions.GET_CANDIDATES_DATA_FAIL: {
      return {
        ...state,
        reportCandidates: [],
        isReportCandidatesLoading: false,
      };
    }
    case reportActions.GET_CANDIDATES_DATA_RESET: {
      return {
        ...state,
        reportCandidates: [],
        isReportCandidatesLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reportReducer;
