const ROOT = '/';
const DEFAULT = `/${localStorage.getItem('tenant') || 'iiht'}/login`;
const TENANT_ROOT = '/:tenant';
const DASHBOARD = TENANT_ROOT + '/dashboard';
const PAGE_NOT_FOUND = '/pageNotFound';
const SYSTEM_ADMIN = TENANT_ROOT + '/systemAdmin';
const LOGIN = TENANT_ROOT + '/login';
const PROFILE = TENANT_ROOT + '/profile';
const FORGOT_PASSWORD = TENANT_ROOT + '/forgot-password';
const THIRD_AEYE = TENANT_ROOT + '/third-aeye';
const CHANGE_PASSWORD = TENANT_ROOT + '/reset-password/:uid/:token';

const VIEW_PROCTOR_LIST = TENANT_ROOT + '/proctor';
const ADD_PROCTOR = TENANT_ROOT + '/proctor/add';
const VIEW_PROCTOR = TENANT_ROOT + '/proctor/:id';
const EDIT_PROCTOR = TENANT_ROOT + '/proctor/edit/:id';

const VIEW_TENANT_ADMIN_LIST = TENANT_ROOT + '/tenant-admin';
const ADD_TENANT_ADMIN = TENANT_ROOT + '/tenant-admin/add';
const VIEW_TENANT_ADMIN = TENANT_ROOT + '/tenant-admin/:id';
const EDIT_TENANT_ADMIN = TENANT_ROOT + '/tenant-admin/edit/:id';

const TENANT = TENANT_ROOT + '/tenant';
const ADD_TENANT = TENANT + '/add';
const EDIT_TENANT = TENANT + '/edit/:id';
const VIEW_TENANT = TENANT + '/:id';

const PROFILESETTINGS = TENANT_ROOT + '/settings';
const MY_ASSESSMENTS = TENANT_ROOT + '/assessments';
const VIEW_ASSESSMENT = TENANT_ROOT + '/assessments/:id';
const VERIFY_ASSESSMENTS_TEST_TAKER = TENANT_ROOT + '/verify/:aid/:tid/:email';
const VERIFICATION_COMPLETE = TENANT_ROOT + '/verification-complete';

const PROCTORING = TENANT_ROOT + '/proctoring/:id';
const SUPERPROCTORING = TENANT_ROOT + '/superProctoring/:id';
const SQUAD = TENANT_ROOT + '/squad/:id';

const REPORT = TENANT_ROOT + '/report';
const VIEW_CANDIDATES_LIST_REPORT = TENANT_ROOT + '/report/:examId/candidates';
const VIEW_CANDIDATE_DETAIL_REPORT =
  TENANT_ROOT + '/report/:examId/candidates/:candidateId';
const CANDIDATE_REVIEW_SESSION =
  TENANT_ROOT + '/report/:examId/candidates/:candidateId/review';
const REPORT_CANDIDATE = TENANT_ROOT + '/report/candidate';

export const ROUTES = {
  ROOT: ROOT,
  LOGIN: LOGIN,
  DASHBOARD: DASHBOARD,
  PAGE_NOT_FOUND: PAGE_NOT_FOUND,
  SYSTEM_ADMIN: SYSTEM_ADMIN,
  TENANT_ROOT: TENANT_ROOT,
  DEFAULT: DEFAULT,
  PROFILE: PROFILE,
  FORGOT_PASSWORD: FORGOT_PASSWORD,
  THIRD_AEYE: THIRD_AEYE,
  CHANGE_PASSWORD: CHANGE_PASSWORD,
  VIEW_PROCTOR_LIST: VIEW_PROCTOR_LIST,
  ADD_PROCTOR: ADD_PROCTOR,
  VIEW_PROCTOR: VIEW_PROCTOR,
  EDIT_PROCTOR: EDIT_PROCTOR,
  TENANT: TENANT,
  ADD_TENANT: ADD_TENANT,
  EDIT_TENANT: EDIT_TENANT,
  VIEW_TENANT: VIEW_TENANT,
  VIEW_TENANT_ADMIN_LIST: VIEW_TENANT_ADMIN_LIST,
  ADD_TENANT_ADMIN: ADD_TENANT_ADMIN,
  VIEW_TENANT_ADMIN: VIEW_TENANT_ADMIN,
  EDIT_TENANT_ADMIN: EDIT_TENANT_ADMIN,
  PROFILESETTINGS: PROFILESETTINGS,
  MY_ASSESSMENTS: MY_ASSESSMENTS,
  VIEW_ASSESSMENT: VIEW_ASSESSMENT,
  VERIFY_ASSESSMENTS_TEST_TAKER: VERIFY_ASSESSMENTS_TEST_TAKER,
  VERIFICATION_COMPLETE: VERIFICATION_COMPLETE,
  PROCTORING: PROCTORING,
  SUPERPROCTORING: SUPERPROCTORING,
  SQUAD: SQUAD,
  REPORT: REPORT,
  VIEW_CANDIDATES_LIST_REPORT: VIEW_CANDIDATES_LIST_REPORT,
  VIEW_CANDIDATE_DETAIL_REPORT: VIEW_CANDIDATE_DETAIL_REPORT,
  REPORT_CANDIDATE: REPORT_CANDIDATE,
  CANDIDATE_REVIEW_SESSION: CANDIDATE_REVIEW_SESSION,
};
