import axiosInstance from '../../translator';
import { tenantAdminAPI } from '../../constants/apis/apiEndpoints';
import { translator } from '../../constants/actionTypes';

const generateQueryParams = (payload) => {
  const params = {};
  params.limit = payload?.limit || 5;
  params.offset = payload?.offset || 1;
  params.search = payload?.search || '';
  params.ordering = payload?.ordering || '-date_joined';
  return params;
};

// Tenant Admin Onboarding
export const getAllTenantAdmins = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .post(
      `${tenantAdminAPI?.tenantAdminList}`,
      {
        roles: ['tenantadmin'],
      },
      { params }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTenantAdmin = async (id) => {
  return axiosInstance
    .get(`${tenantAdminAPI?.tenantAdmin}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const registerTenantAdmin = async (payload) => {
  return axiosInstance
    .post(`${tenantAdminAPI?.addTenantAdmin}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateTenantAdmin = async (action) => {
  return axiosInstance
    .put(
      `${tenantAdminAPI?.updateTenantAdmin}/${action.payload.id}`,
      action.payload.data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteTenantAdminAPI = async (payload) => {
  const params = generateQueryParams(payload?.payload?.paginatedData);

  return axiosInstance
    .delete(`${tenantAdminAPI?.deleteTenantAdmin}/${payload?.payload?.id}`, {
      params,
      data: {
        roles: ['tenantadmin'],
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
};
