import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNOUT_USER } from "../../constants/actionTypes";
//import { signOutRequest } from "../api";

function* signOut({ payload }) {
  try {
    // const sign_out = yield call(signOutRequest, payload);
    // if (sign_out) {
    //   //yield put(signOutUserSuccess());
    // }
  } catch (error) {}
}


export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signOutUser)]);
}
