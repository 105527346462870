//Vendors
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { translator } from '../../constants/actionTypes';
import { useWebSocket } from '../../customHooks';

const EndAssessment = () => {
  const url = translator.wsUrl;
  const { id } = useParams();
  const { userDetails } = useSelector(
    ({ userAuthentication }) => userAuthentication
  );

  useEffect(() => {
    sendMessage({
      data: {
        alert_type: 'end-assessment',
      },
    });
  }, []);

  const onMessage = useCallback((event) => {
    //On Message
  }, []);

  const onWebSocketOpen = useCallback((ws) => {
    //History API call
    console.log('onWebSocketOpen', ws);
  }, []);

  const { sendMessage } = useWebSocket(
    url,
    `${id}-${userDetails?.id}-common`,
    () => {},
    onWebSocketOpen,
    onMessage
  );

  return <></>;
};

export default EndAssessment;
