export const Role = {
  superadmin: 'systemadmin',
  tenantUser: 'tenantuser',
  tenantadmin: 'tenantadmin',
  examadmin: 'examadmin',
  proctor: 'proctor',
  approver: 'approver',
  authenticator: 'authenticator',
  superproctor: 'superproctor'
};
