import * as React from 'react';

const NotificationSvg = () => {
  return (
    <svg
      id="ico_notification"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_3870"
        data-name="Rectangle 3870"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="bx-bell_1_"
        data-name="bx-bell (1)"
        d="M19,13.586V10a7.006,7.006,0,0,0-5.145-6.742,2,2,0,0,0-3.71,0A7.007,7.007,0,0,0,5,10v3.586L3.293,15.293A1,1,0,0,0,3,16v2a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V16a1,1,0,0,0-.293-.707ZM19,17H5v-.586l1.707-1.707A1,1,0,0,0,7,14V10a5,5,0,0,1,10,0v4a1,1,0,0,0,.293.707L19,16.414Zm-7,5a2.98,2.98,0,0,0,2.818-2H9.182A2.98,2.98,0,0,0,12,22Z"
        fill="#fff"
      />
    </svg>
  );
};

export default NotificationSvg;
