import { put, takeLatest, all, call } from 'redux-saga/effects';
import { approverAction } from '../../constants/actionTypes';
import { statusCode } from '../../constants/apis/apiStatus';
import {
  getRoomScanCheckList,
  matchFaceScan,
  getCandidateDetails,
  updateTestTakerVerificationStatus,
} from '../api/approver';
import {
  getRoomScanChecklistSuccess,
  matchFaceScanDataSuccess,
  matchFaceScanDataFail,
  getCandidateDetailsSuccess,
  getCandidateDetailsFail,
  updateTestTakerVerificationStatusSuccess,
  updateTestTakerVerificationStatusFail,
} from '../actions/approverActions';

export function* getRoomScanCheckListFunction(action) {
  try {
    const response = yield call(getRoomScanCheckList, action.payload);
    yield put(getRoomScanChecklistSuccess(response?.data?.data));
  } catch (error) {
    console.log('Error', error);
  }
}

export function* matchFaceScanDataFunction(action) {
  try {
    const response = yield call(matchFaceScan, action.payload);
    if (response?.status === statusCode?.Ok200) {
      // yield put(matchFaceScanDataSuccess());
    } else {
      yield put(matchFaceScanDataFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getCandidateDetailsFunction(action) {
  try {
    const response = yield call(getCandidateDetails, action.payload);
    if (response?.status === statusCode?.Ok200) {
      yield put(getCandidateDetailsSuccess(response?.data?.data));
    } else {
      yield put(getCandidateDetailsFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* updateTestTakerVerificationStatusFunction(action) {
  try {
    const response = yield call(
      updateTestTakerVerificationStatus,
      action.payload
    );
    if (response?.status === statusCode?.Ok200) {
      yield put(updateTestTakerVerificationStatusSuccess(response?.data?.data));
    } else {
      yield put(updateTestTakerVerificationStatusFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* watchDummy() {
  yield takeLatest(
    approverAction.GET_ROOM_SCAN_CHECK_LIST_ACTION,
    getRoomScanCheckListFunction
  );
  yield takeLatest(
    approverAction.MATCH_FACE_SCAN_DATA,
    matchFaceScanDataFunction
  );
  yield takeLatest(
    approverAction.GET_CANDIDATE_DETAILS,
    getCandidateDetailsFunction
  );
  yield takeLatest(
    approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS,
    updateTestTakerVerificationStatusFunction
  );
}

export default function* approverSaga() {
  yield all([watchDummy()]);
}
