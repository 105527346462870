import axiosInstance from '../../translator';
import { approverAPI } from '../../constants/apis/apiEndpoints';

export const getRoomScanCheckList = async () => {
  return axiosInstance
    .get(`${approverAPI.roomScanChecklist}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return [
        {
          id: 0,
          name: 'Noise Free Room with closed door and Min 3 wall room',
          checked: false,
        },
        {
          id: 1,
          name: 'Candidate must be alone in the room',
          checked: false,
        },
        {
          id: 2,
          name: 'No use of Mobilephone, tablets, notepads',
          checked: false,
        },
        {
          id: 3,
          name: 'No use of headphones, Music set etc.',
          checked: false,
        },
        {
          id: 4,
          name: 'No use of books, calculators',
          checked: false,
        },
        {
          id: 5,
          name: 'Any Bookshelf/whiteboards to be covered',
          checked: false,
        },
        {
          id: 6,
          name: 'No Use of extended monitors or devices',
          checked: false,
        },
      ];
    });
};

export const matchFaceScan = async (payload) => {
  return axiosInstance
    .post(`${approverAPI.matchFaceScanData}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCandidateDetails = async (payload) => {
  return axiosInstance
    .get(`${approverAPI?.candidateDetails}/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateTestTakerVerificationStatus = async (payload) => {
  return axiosInstance
    .patch(
      `${approverAPI.testTakerVerificationStatus}/${payload.id}`,
      payload.data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
