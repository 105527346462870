import { proctoringActions } from '../../constants/proctoringActionTypes';

const INITIAL_STATE = {
  candidateDetails: {},
  isCandidateDetailsLoading: false,
  detailViewCandidate: {},
  isExamDetailsLoading: false,
  examDetails: {},
  fetchingProofs: false,
  isExamEnded: false,
  alertProofs: {},
};

const proctoringReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case proctoringActions.GET_CANDIDATE_DETAIL: {
      return {
        ...state,
        isCandidateDetailsLoading: true,
      };
    }
    case proctoringActions.GET_CANDIDATE_DETAIL_SUCCESS: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
        candidateDetails: action.payload?.data?.data,
      };
    }
    case proctoringActions.GET_CANDIDATE_DETAIL_FAIL: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
      };
    }
    case proctoringActions.GET_CANDIDATE_DETAIL_RESET: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
      };
    }

    case proctoringActions.SET_DETAIL_VIEW_CANDIDATE: {
      return {
        ...state,
        detailViewCandidate: action.payload,
      };
    }

    case proctoringActions.SET_DETAIL_VIEW_CANDIDATE_RESET: {
      return {
        ...state,
        detailViewCandidate: {},
      };
    }

    case proctoringActions.GET_EXAM_DETAILS: {
      return {
        ...state,
        isExamDetailsLoading: true,
      };
    }
    case proctoringActions.GET_EXAM_DETAILS_SUCCESS: {
      return {
        ...state,
        isExamDetailsLoading: false,
        examDetails: action?.payload?.data?.data,
      };
    }
    case proctoringActions.GET_EXAM_DETAILS_FAIL: {
      return {
        ...state,
        isExamDetailsLoading: false,
      };
    }
    case proctoringActions.GET_EXAM_DETAILS_RESET: {
      return {
        ...state,
        isExamDetailsLoading: false,
        examDetails: {},
      };
    }
    case proctoringActions.GET_ALERT_PROOFS: {
      return {
        ...state,
        fetchingProofs: true,
      };
    }
    case proctoringActions.GET_ALERT_PROOFS_SUCCESS: {
      return {
        ...state,
        fetchingProofs: false,
        alertProofs: action?.payload?.data?.data,
      };
    }
    case proctoringActions.GET_ALERT_PROOFS_FAIL: {
      return {
        ...state,
        fetchingProofs: false,
      };
    }
    case proctoringActions.GET_ALERT_PROOFS_RESET: {
      return {
        ...state,
        fetchingProofs: false,
        alertProofs: {},
      };
    }
    case proctoringActions.POST_EXAM_DETAILS_SUCCESS: {
      return {
        ...state,
        isExamEnded: true,
      };
    }
    case proctoringActions.POST_EXAM_DETAILS_FAIL: {
      return {
        ...state,
        isExamEnded: false,
      };
    }
    case proctoringActions.POST_EXAM_DETAILS_RESET: {
      return {
        ...state,
        isExamEnded: false,
      };
    }
    default:
      return state;
  }
};

export default proctoringReducer;
