import { janusActions } from '../../constants/actionTypes';

const INITIAL_STATE = {
  localFeedElementId: null,
  start: false,
  remoteFeedElementId: null,
  pType: null,
  roomId: null,
  numOfPublishers: null,
  videoComponentStyle: {},
  server: null,
  participants: {},
  turn_config: [],
  exam_id: null,
  end: false,
  isCandidateRemoved: false,
};

const janusReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case janusActions.START: {
      return {
        ...state,
        localFeedElementId: action.payload.localFeedElementId,
        start: action.payload.start,
        remoteFeedElementId: action.payload.remoteFeedElementId,
        pType: action.payload.pType,
        roomId: action.payload.roomId,
        exam_id: action.payload.exam_id,
        turn_config: action.payload.turn_config,
        numOfPublishers: action.payload.numOfPublishers,
        videoComponentStyle: action.payload.videoComponentStyle,
        server: action.payload.server,
        addComponent: action.payload.addComponent,
        participantDetails: action.payload.participantDetails,
        end: false,
      };
    }
    case janusActions.ADD_PARTICIPANT: {
      let existingData = state.participants?.[action?.payload?.id]
        ? { ...state.participants[action.payload.id] }
        : {};
      state.participants[action.payload.id] = {
        ...existingData,
        name: action.payload.name,
        id: action.payload.id,
        videoStream: action.payload.videoStream,
      };

      return {
        ...state,
      };
    }
    case janusActions.REMOVE_PARTICIPANT: {
      if (state.participants[action.payload.id])
        delete state.participants[action.payload.id];
      return {
        ...state,
      };
    }
    case janusActions.RESET_PARTICIPANT: {
      state.participants = {};
      return {
        ...state,
      };
    }
    case janusActions.ADD_SCREEN_SHARING: {
      let existingData = state.participants?.[action?.payload?.id]
        ? { ...state.participants[action.payload.id] }
        : {};
      state.participants[action.payload.id] = {
        ...existingData,
        screenSharingStream: action.payload.screenSharingStream,
      };
      return { ...state };
    }

    case janusActions.SET_PARTICIPANT_ALERT: {
      if (state.participants[action.payload.participant_id] !== undefined) {
        let alerts =
          state.participants[action.payload.participant_id]?.alerts ?? [];
        alerts.push(action.payload.alert_type);

        state.participants[action.payload.participant_id] = {
          ...state.participants[action.payload.participant_id],
          alerts: alerts,
        };
      }
      return { ...state };
    }
    case janusActions.RESET_PARTICIPANT_ALERT: {
      let alerts =
        state.participants[action.payload.participant_id]?.alerts ?? [];
      alerts = alerts.filter((alert) => alert !== action.payload.alert_type);

      state.participants[action.payload.participant_id] = {
        ...state.participants[action.payload.participant_id],
        alerts: alerts,
      };

      return { ...state };
    }
    case janusActions.RESET_PARTICIPANT_ALL_ALERTS: {
      state.participants[action.payload.participant_id] = {
        ...state.participants[action.payload.participant_id],
        alerts: [],
      };
      return { ...state };
    }
    case janusActions.END: {
      return {
        ...state,
        end: true,
        start: false,
      };
    }
    case janusActions.RESET: {
      return {
        ...INITIAL_STATE,
      };
    }
    case janusActions.CANDIDATE_REMOVE: {
      return {
        ...state,
        isCandidateRemoved: true,
      };
    }
    case janusActions.CANDIDATE_REMOVE_RESET: {
      return {
        ...state,
        isCandidateRemoved: false,
      };
    }
    default:
      return state;
  }
};

export default janusReducer;
