import { tenantActions } from '../../constants/actionTypes';

const getTenantList = (role) => {
  return {
    type: tenantActions.GET_TENANT_LIST,
    payload: role,
  };
};
const getTenantListSuccess = (proctors) => {
  return {
    type: tenantActions.GET_TENANT_LIST_SUCCESS,
    payload: proctors,
  };
};
const getTenantListFail = (error) => {
  return {
    type: tenantActions.GET_TENANT_LIST_FAIL,
    payload: error,
  };
};

const getTenantGeneralDetails = (payload) => {
  return {
    type: tenantActions.GET_TENANT_GENERAL_DETAILS,
    payload,
  };
};
const getTenantGeneralDetailsSuccess = (payload) => {
  return {
    type: tenantActions.GET_TENANT_GENERAL_DETAILS_SUCCESS,
    payload,
  };
};
const getTenantGeneralDetailsFail = (error) => {
  return {
    type: tenantActions.GET_TENANT_GENERAL_DETAILS_FAIL,
    payload: error,
  };
};

const getTenantInfrastructureDetails = (payload) => {
  return {
    type: tenantActions.GET_TENANT_INFRA_DETAILS,
    payload,
  };
};
const getTenantInfrastructureDetailsSuccess = (payload) => {
  return {
    type: tenantActions.GET_TENANT_INFRA_DETAILS_SUCCESS,
    payload,
  };
};
const getTenantInfrastructureDetailsFail = (error) => {
  return {
    type: tenantActions.GET_TENANT_INFRA_DETAILS_FAIL,
    payload: error,
  };
};

const tenantGeneralDetailsAction = (payload) => ({
  type: tenantActions.TENANT_GENERAL_DETAILS_ACTION,
  payload: payload,
});

const tenantGeneralDetailsActionSuccess = (payload) => ({
  type: tenantActions.TENANT_GENERAL_DETAILS_ACTION_SUCCESS,
  payload: payload,
});

const tenantGeneralDetailsActionError = (payload) => ({
  type: tenantActions.TENANT_GENERAL_DETAILS_ACTION_ERROR,
  payload: payload,
});

const tenantGeneralDetailsActionReset = (payload) => ({
  type: tenantActions.TENANT_GENERAL_DETAILS_ACTION_RESET,
  payload: payload,
});

const tenantInfrastructureAction = (payload) => ({
  type: tenantActions.TENANT_INFRA_ACTION,
  payload: payload,
});

const tenantInfrastructureActionSuccess = (payload) => ({
  type: tenantActions.TENANT_INFRA_ACTION_SUCCESS,
  payload: payload,
});

const tenantInfrastructureActionError = (payload) => ({
  type: tenantActions.TENANT_INFRA_ACTION_ERROR,
  payload: payload,
});

const tenantInfrastructureActionReset = (payload) => ({
  type: tenantActions.TENANT_INFRA_ACTION_RESET,
  payload: payload,
});

const tenantInfrastructureSetup = (payload) => ({
  type: tenantActions.TENANT_INFRA_SETUP,
  payload: payload,
});

const tenantInfrastructureSetupSuccess = (payload) => ({
  type: tenantActions.TENANT_INFRA_SETUP_SUCCESS,
  payload: payload,
});

const tenantInfrastructureSetupError = (payload) => ({
  type: tenantActions.TENANT_INFRA_SETUP_ERROR,
  payload: payload,
});

const tenantInfrastructureSetupReset = (payload) => ({
  type: tenantActions.TENANT_INFRA_SETUP_RESET,
  payload: payload,
});

const getCandidateOnboardingAndVerificationRulesList = (payload) => {
  return {
    type: tenantActions.GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST,
    payload: payload,
  };
};
const getCandidateOnboardingAndVerificationRulesListSuccess = (payload) => {
  return {
    type: tenantActions.GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_SUCCESS,
    payload: payload,
  };
};
const getCandidateOnboardingAndVerificationRulesListFail = (payload) => {
  return {
    type: tenantActions.GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST_FAIL,
    payload: payload,
  };
};

const getProctoringRulesList = (payload) => {
  return {
    type: tenantActions.GET_PROCTORING_RULES_LIST,
    payload: payload,
  };
};
const getProctoringRulesListSuccess = (payload) => {
  return {
    type: tenantActions.GET_PROCTORING_RULES_LIST_SUCCESS,
    payload: payload,
  };
};
const getProctoringRulesListFail = (payload) => {
  return {
    type: tenantActions.GET_PROCTORING_RULES_LIST_FAIL,
    payload: payload,
  };
};

const proctoringRulesAction = (payload) => ({
  type: tenantActions.TENANT_PROCTORING_RULES_ACTION,
  payload: payload,
});

const proctoringRulesActionSuccess = (payload) => ({
  type: tenantActions.TENANT_PROCTORING_RULES_ACTION_SUCCESS,
  payload: payload,
});

const proctoringRulesActionError = (payload) => ({
  type: tenantActions.TENANT_PROCTORING_RULES_ACTION_ERROR,
  payload: payload,
});

const proctoringRulesActionReset = (payload) => ({
  type: tenantActions.TENANT_PROCTORING_RULES_ACTION_RESET,
  payload: payload,
});

const candidateOnboardingAndVerificationRulesAction = (payload) => ({
  type: tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION,
  payload: payload,
});

const candidateOnboardingAndVerificationRulesActionSuccess = (payload) => ({
  type: tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_SUCCESS,
  payload: payload,
});

const candidateOnboardingAndVerificationRulesActionError = (payload) => ({
  type: tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_ERROR,
  payload: payload,
});

const candidateOnboardingAndVerificationRulesActionReset = (payload) => ({
  type: tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION_RESET,
  payload: payload,
});

const tenantIdReset = (payload) => ({
  type: tenantActions.TENANT_ID_RESET,
  payload: payload,
});

const tenantNameAvailability = (payload) => ({
  type: tenantActions.TENANT_NAME_AVAILABILITY,
  payload: payload,
});

const tenantNameAvailabilitySuccess = (payload) => ({
  type: tenantActions.TENANT_NAME_AVAILABILITY_SUCCESS,
  payload: payload,
});

const tenantNameAvailabilityReset = (payload) => ({
  type: tenantActions.TENANT_NAME_AVAILABILITY_RESET,
  payload: payload,
});

export {
  tenantNameAvailability,
  tenantNameAvailabilitySuccess,
  tenantNameAvailabilityReset,
  tenantIdReset,
  tenantGeneralDetailsAction,
  tenantGeneralDetailsActionError,
  tenantGeneralDetailsActionReset,
  tenantGeneralDetailsActionSuccess,
  tenantInfrastructureAction,
  tenantInfrastructureActionError,
  tenantInfrastructureActionReset,
  tenantInfrastructureActionSuccess,
  getTenantList,
  getTenantListSuccess,
  getTenantListFail,
  getCandidateOnboardingAndVerificationRulesList,
  getCandidateOnboardingAndVerificationRulesListFail,
  getCandidateOnboardingAndVerificationRulesListSuccess,
  getProctoringRulesList,
  getProctoringRulesListFail,
  getProctoringRulesListSuccess,
  getTenantGeneralDetails,
  getTenantGeneralDetailsFail,
  getTenantGeneralDetailsSuccess,
  getTenantInfrastructureDetails,
  getTenantInfrastructureDetailsFail,
  getTenantInfrastructureDetailsSuccess,
  proctoringRulesAction,
  proctoringRulesActionError,
  proctoringRulesActionReset,
  proctoringRulesActionSuccess,
  candidateOnboardingAndVerificationRulesAction,
  candidateOnboardingAndVerificationRulesActionError,
  candidateOnboardingAndVerificationRulesActionReset,
  candidateOnboardingAndVerificationRulesActionSuccess,
  tenantInfrastructureSetup,
  tenantInfrastructureSetupSuccess,
  tenantInfrastructureSetupError,
  tenantInfrastructureSetupReset,
};
