import React from 'react';

function RoomScan() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_12619"
        data-name="Group 12619"
        transform="translate(-604 -68)"
      >
        <circle
          id="Ellipse_715"
          data-name="Ellipse 715"
          cx="16"
          cy="16"
          r="16"
          transform="translate(608 72)"
          fill="#04a8a2"
        />
        <g
          id="Icon_Button_40"
          data-name="Icon Button 40"
          transform="translate(604 68)"
        >
          <rect id="Base" width="40" height="40" fill="none" />
          <g id="ico_360" transform="translate(8 8)">
            <rect
              id="Base-2"
              data-name="Base"
              width="24"
              height="24"
              fill="none"
            />
            <g id="bx-street-view">
              <path
                id="Path_43971"
                data-name="Path 43971"
                d="M14,4a2,2,0,1,1-2-2,2,2,0,0,1,2,2Z"
                fill="#fff"
              />
              <path
                id="Path_43972"
                data-name="Path 43972"
                d="M12,18h2V13h2V9a2,2,0,0,0-2-2H10A2,2,0,0,0,8,9v4h2v5Z"
                fill="#fff"
              />
              <path
                id="Path_43973"
                data-name="Path 43973"
                d="M18.446,11.386l-.893,1.789C19.108,13.95,20,14.98,20,16c0,1.892-3.285,4-8,4s-8-2.108-8-4c0-1.02.892-2.05,2.446-2.825l-.893-1.789C3.3,12.512,2,14.193,2,16c0,3.364,4.393,6,10,6s10-2.636,10-6C22,14.193,20.7,12.512,18.446,11.386Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RoomScan;
