import { takeLatest, put, call, all } from 'redux-saga/effects';
import i18n from 'i18next';
import { dashboardActions } from '../../constants/dashboardActionTypes';
import { statusCode } from '../../constants/apis/apiStatus';
import {
  getSuperAdminDashboardApi,
  getTenantAdminDashboardApi,
  getProctorDashboardApi,
  getUpcomingAssignmentApi,
} from '../api/dashboardApi';
import {
  getSuperAdminDashboardSuccess,
  getSuperAdminDashboardFail,
  getTenantAdminDashboardSuccess,
  getTenantAdminDashboardFail,
  getProctorDashboardSuccess,
  getProctorDashboardFail,
  getUpcomingAssessmentSuccess,
  getUpcomingAssessmentFail,
} from '../actions/dashboardActions';
import { setNotification } from '../actions';

export function* getSuperAdminDashboardFunction(action) {
  try {
    const response = yield call(getSuperAdminDashboardApi, action.payload);
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getSuperAdminDashboardSuccess(response?.data));
    } else {
      yield put(getSuperAdminDashboardFail());
    }
  } catch (error) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* getTenantAdminDashboardFunction(action) {
  try {
    const response = yield call(getTenantAdminDashboardApi, action.payload);
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getTenantAdminDashboardSuccess(response?.data));
    } else {
      yield put(getTenantAdminDashboardFail());
    }
  } catch (error) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* getProctorDashboardFunction(action) {
  try {
    const response = yield call(getProctorDashboardApi, action.payload);
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getProctorDashboardSuccess(response?.data));
    } else {
      yield put(getProctorDashboardFail());
    }
  } catch (error) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* getUpcomingAssessmentsFunction(action) {
  try {
    const response = yield call(getUpcomingAssignmentApi, action.payload);
    if (
      response?.status?.code === statusCode.Ok200 &&
      response?.data?.length > 0
    ) {
      yield put(getUpcomingAssessmentSuccess(response?.data));
    } else {
      yield put(getUpcomingAssessmentFail(response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* watchDummy() {
  yield takeLatest(
    dashboardActions.GET_SUPER_ADMIN_DASHBOARD_DATA,
    getSuperAdminDashboardFunction
  );
  yield takeLatest(
    dashboardActions.GET_TENANT_ADMIN_DASHBOARD_DATA,
    getTenantAdminDashboardFunction
  );
  yield takeLatest(
    dashboardActions.GET_PROCTORS_DASHBOARD_DATA,
    getProctorDashboardFunction
  );
  yield takeLatest(
    dashboardActions.UPCOMING_ASSESSMENT_DATA,
    getUpcomingAssessmentsFunction
  );
}

export default function* dashboardSaga() {
  yield all([watchDummy()]);
}
