import axiosInstance from '../../translator';
import { proctoringAPI } from '../../constants/apis/apiEndpoints';

export const getCandidateDetails = async (payload) => {
  return axiosInstance
    .get(`${proctoringAPI?.candidateDetails}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamDetails = async (payload) => {
  return axiosInstance
    .get(`${proctoringAPI?.examDetails}/${payload.exam_id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAlertDetails = async (payload) => {
  let params = {
    image_filename: payload.image,
  };
  return axiosInstance
    .get(`${proctoringAPI?.alertProof}/${payload.id}/${payload.image}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postExamDetails = async (payload) => {
  return axiosInstance
    .patch(`${proctoringAPI?.examDetails}/${payload.exam_id}`, {
      ...payload,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getWaitingCandidateList = async (proctor_id) => {
  return axiosInstance
    .get(
      `${proctoringAPI?.getWaitingCandidates}/${proctor_id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};