import { tenantAdminActions } from '../../constants/tenantAdminActionTypes';

const INITIAL_STATE = {
  tenantAdminList: [],
  tenantAdmin: {},
  loading: false,
  isAddedSuccessfully: false,
  isUpdatedSuccessfully: false,
  deletedStatus: false,
  error: '',
};

const tenantAdminReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case tenantAdminActions.GET_TENANT_ADMINS: {
      return {
        ...state,
        loading: true,
      };
    }
    case tenantAdminActions.GET_TENANT_ADMINS_SUCCESS: {
      return {
        ...state,
        tenantAdminList: action?.payload?.data?.data,
        loading: false,
      };
    }
    case tenantAdminActions.GET_TENANT_ADMINS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case tenantAdminActions.GET_TENANT_ADMIN_DETAILS: {
      return {
        ...state,
        loading: true,
      };
    }
    case tenantAdminActions.GET_TENANT_ADMIN_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        tenantAdmin: action?.payload?.data?.data,
      };
    }
    case tenantAdminActions.GET_TENANT_ADMIN_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case tenantAdminActions.ADD_TENANT_ADMIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case tenantAdminActions.ADD_TENANT_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        isAddedSuccessfully: true,
      };
    }
    case tenantAdminActions.ADD_TENANT_ADMIN_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.payload?.data?.errors?.status?.message,
      };
    }
    case tenantAdminActions.ADD_TENANT_ADMIN_RESET: {
      return {
        ...state,
        loading: false,
        isAddedSuccessfully: false,
      };
    }

    case tenantAdminActions.SET_TENANT_ADMIN_DETAILS: {
      return {
        ...state,
        loading: true,
        isUpdatedSuccessfully: false,
      };
    }
    case tenantAdminActions.SET_TENANT_ADMIN_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        isUpdatedSuccessfully: true,
      };
    }
    case tenantAdminActions.SET_TENANT_ADMIN_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
        isUpdatedSuccessfully: false,
      };
    }
    case tenantAdminActions.SET_TENANT_ADMIN_DETAILS_RESET: {
      return {
        ...state,
        loading: false,
        isUpdatedSuccessfully: false,
        tenantAdmin: {},
      };
    }

    case tenantAdminActions.DELETE_TENANT_ADMIN_SUCCESS: {
      return {
        ...state,
        tenantAdminList: action?.payload?.data,
        loading: false,
        deletedStatus: true,
      };
    }
    case tenantAdminActions.DELETE_TENANT_ADMIN_FAIL: {
      return {
        ...state,
        deletedStatus: false,
        error: action.error,
        changesSavedSuccessfully: false,
      };
    }

    default:
      return state;
  }
};
export default tenantAdminReducer;
