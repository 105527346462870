import { put, takeLatest, all, call } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  getAllTenantAdmins,
  getTenantAdmin,
  registerTenantAdmin,
  updateTenantAdmin,
  deleteTenantAdminAPI,
} from '../api';
import {
  getTenantAdminListSuccess,
  getTenantAdminListFail,
  getTenantByIdSuccess,
  getTenantByIdFail,
  addTenantAdminSuccess,
  addTenantAdminFail,
  updateTenantAdminSuccess,
  updateTenantAdminFail,
  deleteTenantAdminSuccess,
  deleteTenantAdminFail,
} from '../actions/tenantAdminActions';
import { setNotification } from '../actions/notificationActions';
import { statusCode } from '../../constants/apis/apiStatus';
import { tenantAdminActions } from '../../constants/tenantAdminActionTypes';

export function* getTenantAdminsFunction(action) {
  try {
    const response = yield call(getAllTenantAdmins, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getTenantAdminListSuccess(response));
    } else {
      yield put(getTenantAdminListFail(response?.response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getTenantAdminDetails(action) {
  try {
    const response = yield call(getTenantAdmin, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getTenantByIdSuccess(response));
    } else {
      yield put(getTenantByIdFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* registerTenantAdminFunction(action) {
  try {
    const response = yield call(registerTenantAdmin, action.payload);
    if (response?.status === statusCode.Created201) {
      yield put(addTenantAdminSuccess(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(addTenantAdminFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* updateTenantAdminDetails(action) {
  try {
    const response = yield call(updateTenantAdmin, action);
    if (response?.status === statusCode.Ok200) {
      yield put(updateTenantAdminSuccess(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(updateTenantAdminFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* deleteIndividualTenantAdmin(payload) {
  try {
    const response = yield call(deleteTenantAdminAPI, payload);
    if (response?.status?.code === statusCode.Ok200) {
      yield put(deleteTenantAdminSuccess(response));
      yield put(
        setNotification({
          data: response?.status?.message || i18n.t('something.wrong'),
          severity: 'success',
        })
      );
    } else {
      yield put(deleteTenantAdminFail(response?.response));
      yield put(
        setNotification({
          data: response?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* watchDummy() {
  yield takeLatest(
    tenantAdminActions.GET_TENANT_ADMINS,
    getTenantAdminsFunction
  );
  yield takeLatest(
    tenantAdminActions.GET_TENANT_ADMIN_DETAILS,
    getTenantAdminDetails
  );
  yield takeLatest(
    tenantAdminActions.ADD_TENANT_ADMIN,
    registerTenantAdminFunction
  );
  yield takeLatest(
    tenantAdminActions.SET_TENANT_ADMIN_DETAILS,
    updateTenantAdminDetails
  );
  yield takeLatest(
    tenantAdminActions.DELETE_TENANT_ADMIN,
    deleteIndividualTenantAdmin
  );
}

export default function* tenantAdminSaga() {
  yield all([watchDummy()]);
}
