import { tenantAdminActions } from '../../constants/tenantAdminActionTypes';

const getTenantAdminList = (payload) => {
  return {
    type: tenantAdminActions.GET_TENANT_ADMINS,
    payload: payload,
  };
};
const getTenantAdminListSuccess = (users) => {
  return {
    type: tenantAdminActions.GET_TENANT_ADMINS_SUCCESS,
    payload: users,
  };
};
const getTenantAdminListFail = (error) => {
  return {
    type: tenantAdminActions.GET_TENANT_ADMINS_FAIL,
    payload: error,
  };
};

const getTenantById = (id) => {
  return {
    type: tenantAdminActions.GET_TENANT_ADMIN_DETAILS,
    payload: id,
  };
};
const getTenantByIdSuccess = (user) => {
  return {
    type: tenantAdminActions.GET_TENANT_ADMIN_DETAILS_SUCCESS,
    payload: user,
  };
};
const getTenantByIdFail = (error) => {
  return {
    type: tenantAdminActions.GET_TENANT_ADMIN_DETAILS_FAIL,
    payload: error,
  };
};

const addTenantAdmin = (user) => {
  return {
    type: tenantAdminActions.ADD_TENANT_ADMIN,
    payload: user,
  };
};
const addTenantAdminSuccess = () => {
  return {
    type: tenantAdminActions.ADD_TENANT_ADMIN_SUCCESS,
  };
};
const addTenantAdminFail = (error) => {
  return {
    type: tenantAdminActions.ADD_TENANT_ADMIN_FAIL,
    payload: error,
  };
};
const addTenantAdminReset = (payload) => {
  return {
    type: tenantAdminActions.ADD_TENANT_ADMIN_RESET,
    payload: payload,
  };
};

const updateTenantAdmin = (data) => {
  return {
    type: tenantAdminActions.SET_TENANT_ADMIN_DETAILS,
    payload: data,
  };
};
const updateTenantAdminSuccess = (data) => {
  return {
    type: tenantAdminActions.SET_TENANT_ADMIN_DETAILS_SUCCESS,
    payload: data,
  };
};
const updateTenantAdminFail = (error) => {
  return {
    type: tenantAdminActions.SET_TENANT_ADMIN_DETAILS_FAIL,
    payload: error,
  };
};
const updateTenantAdminReset = (payload) => {
  return {
    type: tenantAdminActions.SET_TENANT_ADMIN_DETAILS_RESET,
    payload: payload,
  };
};

const deleteTenantAdmin = (paginatedData, id) => {
  return {
    type: tenantAdminActions.DELETE_TENANT_ADMIN,
    payload: { paginatedData, id },
  };
};

const deleteTenantAdminSuccess = (proctor) => {
  return {
    type: tenantAdminActions.DELETE_TENANT_ADMIN_SUCCESS,
    payload: proctor,
  };
};

const deleteTenantAdminFail = (error) => {
  return {
    type: tenantAdminActions.DELETE_TENANT_ADMIN_FAIL,
    payload: error,
  };
};

export {
  getTenantAdminList,
  getTenantAdminListSuccess,
  getTenantAdminListFail,
  getTenantById,
  getTenantByIdSuccess,
  getTenantByIdFail,
  addTenantAdmin,
  addTenantAdminSuccess,
  addTenantAdminFail,
  addTenantAdminReset,
  updateTenantAdmin,
  updateTenantAdminSuccess,
  updateTenantAdminFail,
  updateTenantAdminReset,
  deleteTenantAdmin,
  deleteTenantAdminSuccess,
  deleteTenantAdminFail,
};
