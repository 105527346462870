import axios from 'axios';

export function placeParams(pathRegex, params) {
  var segments = pathRegex.split('/');
  return segments
    .map((segment) => {
      var offset = segment.indexOf(':') + 1;
      if (!offset) return segment;

      var key = segment.slice(offset);
      return params[key];
    })
    .join('/');
}

export const PhoneNumber = (phoneNo) => {
  return phoneNo;
};

export const PhoneNumberInversion = (phoneNo) => {
  return phoneNo;
};

export const CheckIfImageExists = async (path) => {
  return axios
    .get(path)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const generateRandomFileNameWithExt = (format = '.png') => {
  return `${Math.random().toString(36).slice(2, 7)}${format}`;
};

export const hasAccess = (requiredRole, roles) => {
  return requiredRole?.some((item) => {
    return roles?.includes(item);
  });
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (hours === 0) {
    return `${minutes} minutes`;
  } else {
    return `${hours} hour ${minutes} minutes`;
  }
};
