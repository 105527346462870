import { onboardingActions } from '../../constants/proctorOnboardingFromActionTypes';

const INITIAL_STATE = {
  proctors: [],
  proctor: {},
  loadingProctors: false,
  loadingAddProctor: false,
  loadingUpdateProctor: false,
  isAddedSuccessfully: false,
  changesSavedSuccessfully: false,
  deletedStatus: false,
  error: {
    message: '',
  },
};

const onboardingFormReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case onboardingActions.GET_PROCTORS: {
      return { ...state, loadingProctors: true };
    }
    case onboardingActions.GET_PROCTORS_SUCCESS: {
      return {
        ...state,
        proctors: action?.payload,
        loadingProctors: false,
      };
    }
    case onboardingActions.GET_PROCTORS_FAIL: {
      return {
        ...state,
        error: action.error,
        loadingProctors: false,
      };
    }

    case onboardingActions.GET_PROCTOR_DETAILS: {
      return {
        ...state,
        proctor: {},
        changesSavedSuccessfully: false,
      };
    }

    case onboardingActions.GET_PROCTOR_DETAILS_SUCCESS: {
      return {
        ...state,
        proctor: action?.payload?.data?.data,
        changesSavedSuccessfully: false,
      };
    }
    case onboardingActions.GET_PROCTOR_DETAILS_FAIL: {
      return {
        ...state,
        error: action.error,
        changesSavedSuccessfully: false,
      };
    }

    case onboardingActions.ADD_PROCTOR: {
      return { ...state, loadingAddProctor: true, isAddedSuccessfully: false };
    }
    case onboardingActions.ADD_PROCTOR_SUCCESS: {
      return {
        ...state,
        loadingAddProctor: false,
        isAddedSuccessfully: true,
      };
    }
    case onboardingActions.ADD_PROCTOR_FAIL: {
      return {
        ...state,
        loadingAddProctor: false,
        isAddedSuccessfully: false,
        error: action?.payload?.data?.errors?.status?.message,
      };
    }
    case onboardingActions.ADD_PROCTOR_RESET: {
      return {
        ...state,
        loadingAddProctor: false,
        isAddedSuccessfully: false,
      };
    }

    case onboardingActions.SET_PROCTOR_DETAILS: {
      return {
        ...state,
        loadingUpdateProctor: true,
        changesSavedSuccessfully: false,
      };
    }
    case onboardingActions.SET_PROCTOR_DETAILS_SUCCESS: {
      return {
        ...state,
        loadingUpdateProctor: false,
        changesSavedSuccessfully: true,
      };
    }
    case onboardingActions.SET_PROCTOR_DETAILS_FAIL: {
      return {
        ...state,
        loadingUpdateProctor: false,
        changesSavedSuccessfully: false,
        error: action.error,
      };
    }
    case onboardingActions.SET_PROCTOR_DETAILS_RESET: {
      return {
        ...state,
        loadingUpdateProctor: false,
        changesSavedSuccessfully: false,
        proctor: {},
      };
    }

    case onboardingActions.DELETE_PROCTOR_SUCCESS: {
      return {
        ...state,
        proctors: action?.payload,
        loadingProctors: false,
      };
    }
    case onboardingActions.DELETE_PROCTOR_FAIL: {
      return {
        ...state,
        deletedStatus: false,
        error: action.error,
        changesSavedSuccessfully: false,
      };
    }

    default:
      return state;
  }
};

export default onboardingFormReducer;
