import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './AppRoute';
import { theme } from './themes';
import { ThemeProvider } from 'iiht-proctoring-ui-components/build/components/Styles';
import CssBaseline from 'iiht-proctoring-ui-components/build/components/CssBaseline';
import './App.scss';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppRoute />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
