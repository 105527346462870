import { approverAction } from '../../constants/actionTypes';

const INITIAL_STATE = {
  isRoomScanCheckListLoading: undefined,
  roomScanCheckList: [],
  isMatchedSuccessfully: null,
  isImgMatchedLoading: null,
  candidateDetails: {},
  isCandidateDetailsLoading: false,
  isVerificationCancel: false,
};

const approverReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case approverAction.GET_ROOM_SCAN_CHECK_LIST_ACTION: {
      return {
        ...state,
        isRoomScanCheckListLoading: true,
      };
    }
    case approverAction.GET_ROOM_SCAN_CHECK_LIST_ACTION_SUCCESS: {
      return {
        ...state,
        isRoomScanCheckListLoading: false,
        roomScanCheckList: action.payload,
      };
    }
    case approverAction.GET_ROOM_SCAN_CHECK_LIST_ACTION_ERROR: {
      return {
        ...state,
        isRoomScanCheckListLoading: false,
        roomScanCheckList: action.payload,
      };
    }
    case approverAction.MATCH_FACE_SCAN_DATA: {
      return {
        ...state,
        isImgMatchedLoading: true,
      };
    }
    case approverAction.MATCH_FACE_SCAN_DATA_SUCCESS: {
      return {
        ...state,
        isImgMatchedLoading: false,
        isMatchedSuccessfully: true,
      };
    }
    case approverAction.MATCH_FACE_SCAN_DATA_FAIL: {
      return {
        ...state,
        isImgMatchedLoading: false,
        isMatchedSuccessfully: false,
      };
    }
    case approverAction.MATCH_FACE_SCAN_DATA_RESET: {
      return {
        ...state,
        isMatchedSuccessfully: null,
        isImgMatchedLoading: null,
      };
    }

    case approverAction.GET_CANDIDATE_DETAILS: {
      return {
        ...state,
        isCandidateDetailsLoading: true,
      };
    }
    case approverAction.GET_CANDIDATE_DETAILS_SUCCESS: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
        candidateDetails: action.payload,
      };
    }
    case approverAction.GET_CANDIDATE_DETAILS_FAIL: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
      };
    }
    case approverAction.GET_CANDIDATE_DETAILS_RESET: {
      return {
        ...state,
        isCandidateDetailsLoading: false,
      };
    }
    case approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS_SUCCESS: {
      return {
        ...state,
        isVerificationCancel: true,
      };
    }
    case approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS_FAIL: {
      return {
        ...state,
        isVerificationCancel: false,
      };
    }
    case approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS_RESET: {
      return {
        ...state,
        isVerificationCancel: false,
      };
    }
    default:
      return state;
  }
};

export default approverReducer;
