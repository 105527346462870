import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LayoutView from '../components/common/basicLayout/layoutView';
import { ROUTES } from '../constants/routesConstants';

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  const { loginDetails } = useSelector((state) => state.userAuthentication);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (_.isEmpty(loginDetails)) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: ROUTES.DEFAULT, state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(user.role) === -1) {
          // role not authorized so redirect to home page
          return <Redirect to={{ pathname: ROUTES.DEFAULT }} />;
        }

        // authorized so return component
        return (
          <LayoutView>
            <Component {...props} />
          </LayoutView>
        );
      }}
    />
  );
};

export default ProtectedRoute;
