import { put, takeLatest, all, call } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  getLoginDetails,
  resetPassword,
  forgotPassword,
  changePassword,
  editProfileDetails,
  logoutUser as logoutUserApi,
} from '../api';
import { loginActions } from '../../constants/actionTypes';
import {
  loginUserError,
  loginUserSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  forgetPasswordSuccess,
  forgetPasswordError,
  changePasswordError,
  changePasswordSuccess,
  editProfileDetailsError,
  editProfileDetailsSuccess,
  logoutUserSuccess,
  logoutUserError,
} from '../actions/loginActions';
import { setNotification } from '../actions/notificationActions';
import { statusCode } from '../../constants/apis/apiStatus';
import { localStorageHelper } from '../../utils/storageHelper';

export function* loginFunction({ payload }) {
  const response = yield call(getLoginDetails, payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(loginUserSuccess(response?.data?.data));
      localStorageHelper.set('access', response?.data?.data?.token?.access);
      localStorageHelper.set('refresh', response?.data?.data?.token?.refresh);
    } else {
      yield put(loginUserError(response?.data?.data));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* resetPasswordFunction({ payload }) {
  const response = yield call(resetPassword, payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(resetPasswordSuccess(response?.data?.data));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(resetPasswordError(response?.data?.data));
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* changePasswordFunction({ payload }) {
  const response = yield call(changePassword, payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(changePasswordSuccess(response?.data?.data));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(changePasswordError(response?.data));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('server-error'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(changePasswordError(err));
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* editProfileDetailsFunction({ payload }) {
  const response = yield call(editProfileDetails, payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(editProfileDetailsSuccess(response?.data?.data));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(editProfileDetailsError(response?.data));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('server-error'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(editProfileDetailsError(err));
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* forgotPasswordFunction({ payload }) {
  const response = yield call(forgotPassword, payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(forgetPasswordSuccess());
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(forgetPasswordError());
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* logoutFunction({ payload }) {
  const response = yield call(logoutUserApi, payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(logoutUserSuccess());
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(logoutUserError());
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(loginActions.LOGIN_ACTION, loginFunction);
  yield takeLatest(loginActions.LOGOUT_ACTION, logoutFunction);
  yield takeLatest(loginActions.RESET_PASSWORD_ACTION, resetPasswordFunction);
  yield takeLatest(loginActions.FORGET_PASSWORD_ACTION, forgotPasswordFunction);
  yield takeLatest(loginActions.CHANGE_PASSWORD_ACTION, changePasswordFunction);
  yield takeLatest(
    loginActions.EDIT_PROFILE_DETAILS_ACTION,
    editProfileDetailsFunction
  );
}

export default function* loginSaga() {
  yield all([watchDummy()]);
}
