import { reportActions } from '../../constants/reportActionTypes';

const getAssessmentReport = (role) => {
  return {
    type: reportActions.GET_ASSESSMENT_REPORT_DATA,
    payload: role,
  };
};
const getAssessmentReportSuccess = (reportData) => {
  return {
    type: reportActions.GET_ASSESSMENT_REPORT_DATA_SUCCESS,
    payload: reportData,
  };
};
const getAssessmentReportFail = (error) => {
  return {
    type: reportActions.GET_ASSESSMENT_REPORT_DATA_FAIL,
    payload: error,
  };
};

const getCandidateDetailReport = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_DETAIL_REPORT,
    payload: payload,
  };
};
const getCandidateDetailReportSuccess = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_DETAIL_REPORT_SUCCESS,
    payload: payload,
  };
};
const getCandidateDetailReportFail = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_DETAIL_REPORT_FAIL,
    payload: payload,
  };
};
const getCandidateDetailReportReset = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_DETAIL_REPORT_RESET,
    payload: payload,
  };
};

const getCandidateViolationList = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_VIOLATION_LIST,
    payload: payload,
  };
};
const getCandidateViolationListSuccess = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_VIOLATION_LIST_SUCCESS,
    payload: payload,
  };
};
const getCandidateViolationListFail = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_VIOLATION_LIST_FAIL,
    payload: payload,
  };
};
const getCandidateViolationListReset = (payload) => {
  return {
    type: reportActions.GET_CANDIDATE_VIOLATION_LIST_RESET,
    payload: payload,
  };
};

const getViolationList = (payload) => {
  return {
    type: reportActions.GET_VIOLATION_LIST,
    payload: payload,
  };
};
const getViolationListSuccess = (payload) => {
  return {
    type: reportActions.GET_VIOLATION_LIST_SUCCESS,
    payload: payload,
  };
};
const getViolationListFail = (payload) => {
  return {
    type: reportActions.GET_VIOLATION_LIST_FAIL,
    payload: payload,
  };
};
const getViolationListReset = (payload) => {
  return {
    type: reportActions.GET_VIOLATION_LIST_RESET,
    payload: payload,
  };
};

const getReviewSessionDetails = (payload) => {
  return {
    type: reportActions.GET_REVIEW_SESSION_DETAILS,
    payload: payload,
  };
};
const getReviewSessionDetailsSuccess = (payload) => {
  return {
    type: reportActions.GET_REVIEW_SESSION_DETAILS_SUCCESS,
    payload: payload,
  };
};
const getReviewSessionDetailsFail = (payload) => {
  return {
    type: reportActions.GET_REVIEW_SESSION_DETAILS_FAIL,
    payload: payload,
  };
};
const getReviewSessionDetailsReset = (payload) => {
  return {
    type: reportActions.GET_REVIEW_SESSION_DETAILS_RESET,
    payload: payload,
  };
};

const getAiAlertList = (payload) => {
  return {
    type: reportActions.GET_AI_ALERT_LIST,
    payload: payload,
  };
};
const getAiAlertListSuccess = (payload) => {
  return {
    type: reportActions.GET_AI_ALERT_LIST_SUCCESS,
    payload: payload,
  };
};
const getAiAlertListFail = (payload) => {
  return {
    type: reportActions.GET_AI_ALERT_LIST_FAIL,
    payload: payload,
  };
};
const getAiAlertListReset = (payload) => {
  return {
    type: reportActions.GET_AI_ALERT_LIST_RESET,
    payload: payload,
  };
};
const getAssessmentCandidates = (payload) => {
  return {
    type: reportActions.GET_ASSESSMENT_CANDIDATES_DATA,
    payload: payload,
  };
};
const getAssessmentCandidatesSuccess = (payload) => {
  return {
    type: reportActions.GET_ASSESSMENT_CANDIDATES_DATA_SUCCESS,
    payload: payload,
  };
};

const getAssessmentCandidatesFail = (payload) => {
  return {
    type: reportActions.GET_ASSESSMENT_CANDIDATES_DATA_FAIL,
    payload: payload,
  };
};

const getAssessmentCandidatesReset = (payload) => {
  return {
    type: reportActions.GET_ASSESSMENT_CANDIDATES_DATA_RESET,
    payload: payload,
  };
};

const getReportCandidates = (payload) => {
  return {
    type: reportActions.GET_CANDIDATES_DATA,
    payload: payload,
  };
};

const candidateStatus = (payload) => {
  return {
    type: reportActions.CANDIDATE_PROCTORING_STATUS,
    payload: payload,
  };
};
const candidateStatusSuccess = (payload) => {
  return {
    type: reportActions.CANDIDATE_PROCTORING_STATUS_SUCCESS,
    payload: payload,
  };
};
const candidateStatusFail = (payload) => {
  return {
    type: reportActions.CANDIDATE_PROCTORING_STATUS_FAIL,
    payload: payload,
  };
};
const getReportCandidatesSuccess = (payload) => {
  return {
    type: reportActions.GET_CANDIDATES_DATA_SUCCESS,
    payload: payload,
  };
};

const getReportCandidatesFail = (payload) => {
  return {
    type: reportActions.GET_CANDIDATES_DATA_FAIL,
    payload: payload,
  };
};

const getReportCandidatesReset = (payload) => {
  return {
    type: reportActions.GET_CANDIDATES_DATA_RESET,
    payload: payload,
  };
};

const downloadCandidatesReport = (payload) => {
  return {
    type: reportActions.DOWNLOAD_CANDIDATES_REPORT,
    payload: payload,
  };
};

const downloadCandidatesReportSuccess = (payload) => {
  return {
    type: reportActions.DOWNLOAD_CANDIDATES_REPORT_SUCCESS,
    payload: payload,
  };
};

const downloadCandidatesReportFail = (payload) => {
  return {
    type: reportActions.DOWNLOAD_CANDIDATES_REPORT_FAIL,
    payload: payload,
  };
};

const downloadCandidatesReportReset = (payload) => {
  return {
    type: reportActions.DOWNLOAD_CANDIDATES_REPORT_RESET,
    payload: payload,
  };
};

const getExamVideoLink = (payload) => {
  return {
    type: reportActions.GET_EXAM_VIDEO_LINK,
    payload: payload,
  };
};

const getExamVideoLinkSuccess = (payload) => {
  return {
    type: reportActions.GET_EXAM_VIDEO_LINK_SUCCESS,
    payload: payload,
  };
};

const getExamVideoLinkFail = (payload) => {
  return {
    type: reportActions.GET_EXAM_VIDEO_LINK_FAIL,
    payload: payload,
  };
};

const getExamVideoLinkReset = (payload) => {
  return {
    type: reportActions.GET_EXAM_VIDEO_LINK_RESET,
    payload: payload,
  };
};

const downloadCandidatesInDateRangeReport = (payload) => {
  return {
    type: reportActions.DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT,
    payload: payload,
  };
};

const downloadCandidatesInDateRangeReportSuccess = (payload) => {
  return {
    type: reportActions.DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT_SUCCESS,
    payload: payload,
  };
};

const downloadCandidatesInDateRangeReportFail = (payload) => {
  return {
    type: reportActions.DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT_FAIL,
    payload: payload,
  };
};

export {
  candidateStatus,
  candidateStatusSuccess,
  candidateStatusFail,
  getAssessmentReport,
  getAssessmentReportSuccess,
  getAssessmentReportFail,
  getCandidateDetailReport,
  getCandidateDetailReportSuccess,
  getCandidateDetailReportFail,
  getCandidateDetailReportReset,
  getCandidateViolationList,
  getCandidateViolationListSuccess,
  getCandidateViolationListFail,
  getCandidateViolationListReset,
  getViolationList,
  getViolationListSuccess,
  getViolationListFail,
  getViolationListReset,
  getReviewSessionDetails,
  getReviewSessionDetailsSuccess,
  getReviewSessionDetailsFail,
  getReviewSessionDetailsReset,
  getAiAlertList,
  getAiAlertListSuccess,
  getAiAlertListFail,
  getAiAlertListReset,
  getAssessmentCandidates,
  getAssessmentCandidatesFail,
  getAssessmentCandidatesReset,
  getAssessmentCandidatesSuccess,
  getReportCandidates,
  getReportCandidatesFail,
  getReportCandidatesReset,
  getReportCandidatesSuccess,
  downloadCandidatesReport,
  downloadCandidatesReportSuccess,
  downloadCandidatesReportFail,
  downloadCandidatesReportReset,
  getExamVideoLink,
  getExamVideoLinkSuccess,
  getExamVideoLinkFail,
  getExamVideoLinkReset,
  downloadCandidatesInDateRangeReport,
  downloadCandidatesInDateRangeReportSuccess,
  downloadCandidatesInDateRangeReportFail,
};
