import { useCallback, useEffect, useRef, useState } from 'react';
import { localStorageHelper } from '../utils/storageHelper';
const useWebSocket = (url, roomId, getRoomIdFn, onWebSocketOpen, onMessage) => {
  const webSocketInstance = useRef(null);
  const messageQueue = useRef([]);
  const [isSocketOpen, setIsSocketOpen] = useState(false);
  const resetWebSocket = useCallback(() => {
    if (
      webSocketInstance?.current?.readyState === 1 ||
      webSocketInstance?.current?.readyState === 0
    ) {
      webSocketInstance?.current?.close?.();
    }
    webSocketInstance.current = null;
    setIsSocketOpen(false);
  }, []);
  useEffect(() => {
    return () => {
      resetWebSocket();
    };
  }, []);
  useEffect(() => {
    if (roomId && url) {
      if (webSocketInstance.current) {
        resetWebSocket();
      }
      webSocketInstance.current = new WebSocket(
        `${url}/${localStorageHelper.get(
          'tenant'
        )}/lms/live_updates/api/v1/${roomId}`
      );
      webSocketInstance.current.onopen = (event) => {
        if (
          event?.type === 'open' &&
          webSocketInstance?.current?.readyState === 1
        ) {
          onWebSocketOpen(webSocketInstance.current);
          setIsSocketOpen(true);
        }
      };
    }
  }, [roomId, url, resetWebSocket, onWebSocketOpen]);

  useEffect(() => {
    if (isSocketOpen) {
      webSocketInstance.current.onmessage = (event) => {
        onMessage(event);
      };
      webSocketInstance.current.onerror = () => {
        webSocketInstance.current = null;
        setIsSocketOpen(false);
        getRoomIdFn();
      };
    }
  }, [onMessage, isSocketOpen, getRoomIdFn]);

  const getWebSocket = useCallback(() => {
    return webSocketInstance?.current;
  }, []);
  const sendMessage = useCallback((message, keep = true) => {
    if (webSocketInstance.current?.readyState === 1) {
      webSocketInstance?.current?.send?.(message);
    } else if (keep) {
      messageQueue?.current?.push?.(message);
    }
  }, []);
  useEffect(() => {
    if (webSocketInstance?.current?.readyState === 1) {
      messageQueue?.current?.splice?.(0).forEach?.((message) => {
        sendMessage(message);
      });
    }
  }, [webSocketInstance?.current?.readyState, sendMessage]);
  useEffect(() => {
    return () => {
      webSocketInstance.current = null;
      messageQueue.current = null;
      setIsSocketOpen(false);
    };
  }, []);
  return {
    webSocketInstance: webSocketInstance?.current,
    isSocketOpen,
    getWebSocket: getWebSocket,
    sendMessage: sendMessage,
  };
};
export default useWebSocket;
