export const localStorageHelper = {
  set: (name, value) => {
    try {
      localStorage.setItem(name, value);
    } catch (e) {
      if (e.code === 22) {
        return false;
      }
    }
  },
  get: (name) => {
    try {
      let value = localStorage.getItem(name);

      switch (value) {
        case undefined:
          value = null;
          break;
        case null:
          value = null;
          break;
        default:
          return value;
      }
      return value;
    } catch (error) {
      return false;
    }
  },
  removeItem: (name) => {
    try {
      localStorage.removeItem(name);
    } catch (error) {
      return false;
    }
  },
  clear: () => {
    localStorage.clear();
  },
};
