export const dashboardActions = {
  GET_SUPER_ADMIN_DASHBOARD_DATA: 'GET_SUPER_ADMIN_DASHBOARD_DATA',
  GET_SUPER_ADMIN_DASHBOARD_DATA_SUCCESS:
    'GET_SUPER_ADMIN_DASHBOARD_DATA_SUCCESS',
  GET_SUPER_ADMIN_DASHBOARD_DATA_FAIL: 'GET_SUPER_ADMIN_DASHBOARD_DATA_FAIL',
  GET_SUPER_ADMIN_DASHBOARD_DATA_RESET: 'GET_SUPER_ADMIN_DASHBOARD_DATA_RESET',

  GET_TENANT_ADMIN_DASHBOARD_DATA: 'GET_TENANT_ADMIN_DASHBOARD_DATA',
  GET_TENANT_ADMIN_DASHBOARD_DATA_SUCCESS:
    'GET_TENANT_ADMIN_DASHBOARD_DATA_SUCCESS',
  GET_TENANT_ADMIN_DASHBOARD_DATA_FAIL: 'GET_TENANT_ADMIN_DASHBOARD_DATA_FAIL',
  GET_TENANT_ADMIN_DASHBOARD_DATA_RESET:
    'GET_TENANT_ADMIN_DASHBOARD_DATA_RESET',

  GET_PROCTORS_DASHBOARD_DATA: 'GET_PROCTORS_DASHBOARD_DATA',
  GET_PROCTORS_DASHBOARD_DATA_SUCCESS: 'GET_PROCTORS_DASHBOARD_DATA_SUCCESS',
  GET_PROCTORS_DASHBOARD_DATA_FAIL: 'GET_PROCTORS_DASHBOARD_DATA_FAIL',
  GET_PROCTORS_DASHBOARD_DATA_RESET: 'GET_PROCTORS_DASHBOARD_DATA_RESET',

  UPCOMING_ASSESSMENT_DATA: 'UPCOMING_ASSESSMENT_DATA',
  UPCOMING_ASSESSMENT_DATA_SUCCESS: 'UPCOMING_ASSESSMENT_DATA_SUCCESS',
  UPCOMING_ASSESSMENT_DATA_FAIL: 'UPCOMING_ASSESSMENT_DATA_FAIL',
  UPCOMING_ASSESSMENT_DATA_RESET: 'UPCOMING_ASSESSMENT_DATA_RESET',
};
