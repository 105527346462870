import { onboardingActions } from '../../constants/proctorOnboardingFromActionTypes';

const getProctors = (role) => {
  return {
    type: onboardingActions.GET_PROCTORS,
    payload: role,
  };
};
const getProctorsSuccess = (proctors) => {
  return {
    type: onboardingActions.GET_PROCTORS_SUCCESS,
    payload: proctors,
  };
};
const getProctorsFail = (error) => {
  return {
    type: onboardingActions.GET_PROCTORS_FAIL,
    payload: error,
  };
};

const getProctorById = (id) => {
  return {
    type: onboardingActions.GET_PROCTOR_DETAILS,
    payload: id,
  };
};
const getProctorDetailsSuccess = (proctor) => {
  return {
    type: onboardingActions.GET_PROCTOR_DETAILS_SUCCESS,
    payload: proctor,
  };
};
const getProctorDetailsFail = (error) => {
  return {
    type: onboardingActions.GET_PROCTOR_DETAILS_FAIL,
    payload: error,
  };
};

const addProctor = (proctor) => {
  return {
    type: onboardingActions.ADD_PROCTOR,
    payload: proctor,
  };
};
const addProctorSuccess = () => {
  return {
    type: onboardingActions.ADD_PROCTOR_SUCCESS,
  };
};
const addProctorFail = (error) => {
  return {
    type: onboardingActions.ADD_PROCTOR_FAIL,
    payload: error,
  };
};
const addProctorReset = (payload) => {
  return {
    type: onboardingActions.ADD_PROCTOR_RESET,
    payload: payload,
  };
};

const updateProctor = (data) => {
  return {
    type: onboardingActions.SET_PROCTOR_DETAILS,
    payload: data,
  };
};
const updateProctorSuccess = (data) => {
  return {
    type: onboardingActions.SET_PROCTOR_DETAILS_SUCCESS,
    payload: data,
  };
};
const updateProctorFail = (error) => {
  return {
    type: onboardingActions.SET_PROCTOR_DETAILS_FAIL,
    payload: error,
  };
};
const updateProctorReset = (payload) => {
  return {
    type: onboardingActions.SET_PROCTOR_DETAILS_RESET,
    payload: payload,
  };
};

const deleteProctor = (paginatedData, id) => {
  return {
    type: onboardingActions.DELETE_PROCTOR,
    payload: { paginatedData, id },
  };
};

const deleteProctorSuccess = (proctor) => {
  return {
    type: onboardingActions.DELETE_PROCTOR_SUCCESS,
    payload: proctor,
  };
};

const deleteProctorFail = (error) => {
  return {
    type: onboardingActions.DELETE_PROCTOR_FAIL,
    payload: error,
  };
};

export {
  getProctors,
  getProctorsSuccess,
  getProctorsFail,
  getProctorById,
  getProctorDetailsSuccess,
  getProctorDetailsFail,
  addProctor,
  addProctorSuccess,
  addProctorFail,
  addProctorReset,
  updateProctor,
  updateProctorSuccess,
  updateProctorFail,
  updateProctorReset,
  deleteProctor,
  deleteProctorSuccess,
  deleteProctorFail,
};
