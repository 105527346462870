import axios from 'axios';
import { userAuthenticationAPI as userAuth } from './constants/apis/apiEndpoints';
import { translator } from './constants/actionTypes';
import { localStorageHelper } from './utils/storageHelper';
import { statusCode } from './constants/apis/apiStatus';
import { placeParams } from './utils/helper';
import { ROUTES } from './constants/routesConstants';

const urlPath = window.location.href;
const axiosInstance = axios.create({});

const fetchRefreshToken = () => {
  return axios
    .post(
      `${axiosInstance.defaults.baseURL}/${localStorageHelper.get('tenant')}/${
        userAuth.refreshTokenEndpoint
      }`,
      {
        refresh: localStorageHelper.get('refresh'),
      }
    )
    .then((r) => r)
    .catch((e) => e);
};

axiosInstance.interceptors.request.use(function (config) {
  config.baseURL = `${config?.baseURL}/${localStorageHelper.get('tenant')}/`;
  if (localStorageHelper.get('access'))
    config.headers.Authorization = `Bearer ${
      localStorageHelper.get('access') || ''
    }`;

  config.headers['Access-Control-Allow-Origin'] = '*';
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const status = error?.response?.status;
    const errorData = error?.response?.data;
    const requestData = error?.config;
    if (
      (errorData?.error?.code === 'token_not_valid' ||
        status === statusCode.Unauthorized401) &&
      !requestData._retry
    ) {
      requestData._retry = true;
      try {
        let response = await fetchRefreshToken();
        if (response?.status === statusCode.Ok200) {
          let { access, refresh } = response?.data?.data;
          localStorageHelper.set('access', access);
          localStorageHelper.set('refresh', refresh);

          axiosInstance.request(requestData);
        } else {
          let tenant = localStorage.getItem('tenant');
          location.href = placeParams(ROUTES.LOGIN, { tenant });
        }
      } catch (err) {
        return Promise.reject(err);
      }
    } else {
      //TODO: Call to logout when ready
    }

    return Promise.reject(error?.response);
  }
);

if (urlPath.includes(translator.baseURL)) {
  axiosInstance.defaults.baseURL = translator.baseURL;
} else if (urlPath.includes(translator.localhost3000)) {
  axiosInstance.defaults.baseURL = translator.baseURL;
} else if (urlPath.includes(translator.localhost3001)) {
  axiosInstance.defaults.baseURL = translator.baseURL;
} else if (urlPath.includes(translator.qaBaseURL)) {
  axiosInstance.defaults.baseURL = translator.qaBaseURL;
} else if (urlPath.includes(translator.prefBaseURL)) {
  axiosInstance.defaults.baseURL = translator.prefBaseURL;
} else if (urlPath.includes(translator.stageBaseURL)) {
  axiosInstance.defaults.baseURL = translator.stageBaseURL;
} else if (urlPath.includes(translator.prodBaseURL)) {
  axiosInstance.defaults.baseURL = translator.prodBaseURL;
} else if (urlPath.includes(translator.uatBaseURL)) {
  axiosInstance.defaults.baseURL = translator.uatBaseURL;
} else if (urlPath.includes(translator.hotfixBaseURL)) {
  axiosInstance.defaults.baseURL = translator.hotfixBaseURL;
} else {
  axiosInstance.defaults.baseURL = translator.baseURL;
}

export default axiosInstance;
