import axiosInstance from '../../translator';
import {
  userAuthenticationAPI as userAuth,
  onboardingFormAPI,
  tenantAPI,
} from '../../constants/apis/apiEndpoints';
import { translator } from '../../constants/actionTypes';

// Login
export const getLoginDetails = async (payload) => {
  return axiosInstance
    .post(`${userAuth?.login}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const logoutUser = async (payload) => {
  return axiosInstance
    .post(`${userAuth?.logoutEndpoint}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const resetPassword = async (payload) => {
  return axiosInstance
    .post(`${userAuth?.reset_password}/${payload?.uid}/${payload?.token}`, {
      password: payload?.password,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const changePassword = async (payload) => {
  return axiosInstance
    .post(`${userAuth?.changePassword}`, {
      old_password: payload?.old_password,
      new_password: payload?.new_password,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const editProfileDetails = async (payload) => {
  return axiosInstance
    .put(`${userAuth?.profileDetails}/${payload?.id}`, {
      first_name: payload?.first_name,
      last_name: payload?.last_name,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const forgotPassword = async (payload) => {
  return axiosInstance
    .post(userAuth?.reset_password, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const generateQueryParams = (payload) => {
  const params = {};
  params.limit = payload?.limit || 5;
  params.offset = payload?.offset || 1;
  params.search = payload?.search || '';
  params.ordering = payload?.ordering || '-date_joined';
  return params;
};

// Proctor Onboarding
export const getAllProctors = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .post(
      `${onboardingFormAPI?.proctors}`,
      {
        roles: ['proctor', 'approver', 'superproctor', 'squad'],
      },
      { params }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteProctorAPI = async (payload) => {
  const params = generateQueryParams(payload?.payload?.paginatedData);

  return axiosInstance
    .delete(`${onboardingFormAPI?.deleteProctor}/${payload?.payload?.id}`, {
      params,
      data: {
        roles: ['proctor', 'approver'],
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getProctor = async (id) => {
  return axiosInstance
    .get(`${onboardingFormAPI?.proctor}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const registerProctor = async (payload) => {
  return axiosInstance
    .post(`${onboardingFormAPI?.addProctor}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateProctor = async (action) => {
  return axiosInstance
    .put(
      `${onboardingFormAPI?.updateProctor}/${action.payload.id}`,
      action.payload.data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const tenantGeneralDetails = async (payload) => {
  //PUT Call
  if (payload.isEdit) {
    delete payload.isEdit;
    return axiosInstance
      .put(`${tenantAPI.tenant_general_details}/${payload.id}`, payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  return axiosInstance
    .post(tenantAPI.tenant_general_details, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const tenantInfrastructure = async (payload) => {
  //PUT Call
  if (payload.isEdit) {
    delete payload.isEdit;
    const api = tenantAPI.tenant_infrastructure + '/' + payload?.tenant_id;
    return axiosInstance
      .patch(api, payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  return axiosInstance
    .post(tenantAPI.tenant_infrastructure, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCandidateOnboardingAndVerificationRules = async (payload) => {
  const api =
    tenantAPI.candidate_onboarding_and_verification_rule + '/' + payload?.id;

  return axiosInstance
    .get(api, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const candidateOnboardingAndVerificationRules = async (payload) => {
  const api = tenantAPI.candidate_onboarding_and_verification_rule;
  return axiosInstance
    .post(api, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProctoringRules = async (payload) => {
  const api = tenantAPI.proctoring_rule + '/' + payload?.id;

  return axiosInstance
    .get(api, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const proctoringRules = async (payload) => {
  const api = tenantAPI.proctoring_rule;
  return axiosInstance
    .post(api, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTenantGeneralDetails = async (payload) => {
  return axiosInstance
    .get(`${tenantAPI.tenant_general_details}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTenantInfrastructureDetails = async (payload) => {
  return axiosInstance
    .get(`${tenantAPI.tenant_infrastructure}/${payload.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
