import { loginActions } from '../../constants/actionTypes';

const loginUserError = (payload) => ({
  type: loginActions.LOGIN_ACTION_ERROR,
  payload: payload,
});

const loginUserAction = (payload) => ({
  type: loginActions.LOGIN_ACTION,
  payload: payload,
});

const loginUserSuccess = (payload) => ({
  type: loginActions.LOGIN_ACTION_SUCCESS,
  payload: payload,
});

const loginUserReset = (payload) => ({
  type: loginActions.LOGIN_ACTION_RESET,
  payload: payload,
});

const logoutUserAction = (payload) => ({
  type: loginActions.LOGOUT_ACTION,
  payload: payload,
});

const logoutUserSuccess = (payload) => ({
  type: loginActions.LOGOUT_ACTION_SUCCESS,
  payload: payload,
});

const logoutUserError = (payload) => ({
  type: loginActions.LOGOUT_ACTION_ERROR,
  payload: payload,
});

const logoutUserReset = (payload) => ({
  type: loginActions.LOGOUT_ACTION_RESET,
  payload: payload,
});

const forgetPasswordError = (payload) => ({
  type: loginActions.FORGET_PASSWORD_ACTION_ERROR,
  payload: payload,
});

const forgetPasswordAction = (payload) => ({
  type: loginActions.FORGET_PASSWORD_ACTION,
  payload: payload,
});

const forgetPasswordSuccess = (payload) => ({
  type: loginActions.FORGET_PASSWORD_ACTION_SUCCESS,
  payload: payload,
});
const forgetPasswordActionReset = (payload) => ({
  type: loginActions.FORGET_PASSWORD_ACTION_RESET,
  payload: payload,
});

const resetPasswordError = (payload) => ({
  type: loginActions.RESET_PASSWORD_ACTION_ERROR,
  payload: payload,
});

const resetPasswordAction = (payload) => ({
  type: loginActions.RESET_PASSWORD_ACTION,
  payload: payload,
});

const resetPasswordSuccess = (payload) => ({
  type: loginActions.RESET_PASSWORD_ACTION_SUCCESS,
  payload: payload,
});

const resetPasswordActionReset = (payload) => ({
  type: loginActions.RESET_PASSWORD_ACTION_RESET,
  payload: payload,
});

const changePasswordError = (payload) => ({
  type: loginActions.CHANGE_PASSWORD_ACTION_ERROR,
  payload: payload,
});

const changePasswordAction = (payload) => ({
  type: loginActions.CHANGE_PASSWORD_ACTION,
  payload: payload,
});

const changePasswordSuccess = (payload) => ({
  type: loginActions.CHANGE_PASSWORD_ACTION_SUCCESS,
  payload: payload,
});

const changePasswordActionReset = (payload) => ({
  type: loginActions.CHANGE_PASSWORD_ACTION_RESET,
  payload: payload,
});

const editProfileDetailsError = (payload) => ({
  type: loginActions.EDIT_PROFILE_DETAILS_ERROR,
  payload: payload,
});

const editProfileDetailsAction = (payload) => ({
  type: loginActions.EDIT_PROFILE_DETAILS_ACTION,
  payload: payload,
});

const editProfileDetailsSuccess = (payload) => ({
  type: loginActions.EDIT_PROFILE_DETAILS_SUCCESS,
  payload: payload,
});

const editProfileDetailsdActionReset = (payload) => ({
  type: loginActions.EDIT_PROFILE_DETAILS_RESET,
  payload: payload,
});

export {
  loginUserError,
  loginUserSuccess,
  loginUserAction,
  loginUserReset,
  logoutUserAction,
  logoutUserSuccess,
  logoutUserError,
  logoutUserReset,
  forgetPasswordError,
  forgetPasswordAction,
  forgetPasswordSuccess,
  forgetPasswordActionReset,
  resetPasswordAction,
  resetPasswordError,
  resetPasswordSuccess,
  resetPasswordActionReset,
  changePasswordError,
  changePasswordAction,
  changePasswordSuccess,
  changePasswordActionReset,
  editProfileDetailsError,
  editProfileDetailsAction,
  editProfileDetailsSuccess,
  editProfileDetailsdActionReset,
};
