import React from 'react';

function VoiceCallEnd() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_12618"
        data-name="Group 12618"
        transform="translate(-604 -68)"
      >
        <circle
          id="Ellipse_715"
          data-name="Ellipse 715"
          cx="16"
          cy="16"
          r="16"
          transform="translate(608 72)"
          fill="#bc1b19"
        />
        <g
          id="Icon_Button_40"
          data-name="Icon Button 40"
          transform="translate(604 68)"
        >
          <rect id="Base" width="40" height="40" fill="none" />
          <g id="ico_call" transform="translate(8 8)">
            <rect
              id="Base-2"
              data-name="Base"
              width="24"
              height="24"
              fill="none"
            />
            <path
              id="bxs-phone"
              d="M20.487,17.14l-4.065-3.7a1,1,0,0,0-1.391.043l-2.393,2.461a5.741,5.741,0,0,1-2.926-1.66,5.713,5.713,0,0,1-1.66-2.926l2.459-2.394a1,1,0,0,0,.043-1.391L6.859,3.513a1,1,0,0,0-1.391-.087L3.3,5.287a1,1,0,0,0-.29.649A15.1,15.1,0,0,0,7.3,16.7,15.142,15.142,0,0,0,17.7,21c.2,0,.326-.006.359-.008a.992.992,0,0,0,.648-.291l1.86-2.171a1,1,0,0,0-.085-1.39Z"
              transform="translate(0.001 -0.186)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default VoiceCallEnd;
