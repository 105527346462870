import axiosInstance from '../../translator';
import { assessmentAPI } from '../../constants/apis/apiEndpoints';

const generateParams = (payload) => {
  const params = {};
  params.ordering = payload?.ordering || '-starts_at';
  return params;
};

export const getAssessmentList = async (payload) => {
  const params = generateParams(payload);
  return axiosInstance
    .post(`${assessmentAPI?.assessments}`, payload, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getOnGoingExam = async () => {
  return axiosInstance
    .get(`${assessmentAPI?.onGoingExam}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssessmentDetails = async (id) => {
  return axiosInstance
    .get(`${assessmentAPI?.assessmentDetails}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAssessmentInfo = async (id) => {
  return axiosInstance
    .get(`${assessmentAPI?.assessmentInfo}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const generateQueryParams = (payload) => {
  const params = {};
  params.proctor_id = payload?.proctor_id;
  params.test_taker_email = payload?.test_taker_email;
  return params;
};

export const getAssessmentJanusDetails = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .get(
      `${assessmentAPI?.assessmentJanusDetails}/${payload.id}/${payload.role}`,
      {
        params,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const endAssessment = async (data) => {
  return axiosInstance
    .post(`${assessmentAPI?.endAssessment}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const examEvent = async (data) => {
  return axiosInstance
    .post(`${assessmentAPI?.examEvent}/${data?.exam_event}`, data?.data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExamRoomDetails = async (id) => {
  return axiosInstance
    .get(`${assessmentAPI?.onGoingExamRoomDetails}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
