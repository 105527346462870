export const proctoringActions = {
  GET_CANDIDATE_DETAIL: 'GET_CANDIDATE_DETAIL',
  GET_CANDIDATE_DETAIL_SUCCESS: 'GET_CANDIDATE_DETAIL_SUCCESS',
  GET_CANDIDATE_DETAIL_FAIL: 'GET_CANDIDATE_DETAIL_FAIL',
  GET_CANDIDATE_DETAIL_RESET: 'GET_CANDIDATE_DETAIL_RESET',

  SET_DETAIL_VIEW_CANDIDATE: 'SET_DETAIL_VIEW_CANDIDATE',
  SET_DETAIL_VIEW_CANDIDATE_SUCCESS: 'SET_DETAIL_VIEW_CANDIDATE_SUCCESS',
  SET_DETAIL_VIEW_CANDIDATE_FAIL: 'SET_DETAIL_VIEW_CANDIDATE_FAIL',
  SET_DETAIL_VIEW_CANDIDATE_RESET: 'SET_DETAIL_VIEW_CANDIDATE_RESET',

  GET_EXAM_DETAILS: 'GET_EXAM_DETAILS',
  GET_EXAM_DETAILS_SUCCESS: 'GET_EXAM_DETAILS_SUCCESS',
  GET_EXAM_DETAILS_FAIL: 'GET_EXAM_DETAILS_FAIL',
  GET_EXAM_DETAILS_RESET: 'GET_EXAM_DETAILS_RESET',

  GET_ALERT_PROOFS: 'GET_ALERT_PROOFS',
  GET_ALERT_PROOFS_SUCCESS: 'GET_ALERT_PROOFS_SUCCESS',
  GET_ALERT_PROOFS_FAIL: 'GET_ALERT_PROOFS_FAIL',
  GET_ALERT_PROOFS_RESET: 'GET_ALERT_PROOFS_RESET',

  POST_EXAM_DETAILS: 'POST_EXAM_DETAILS',
  POST_EXAM_DETAILS_SUCCESS: 'POST_EXAM_DETAILS_SUCCESS',
  POST_EXAM_DETAILS_FAIL: 'POST_EXAM_DETAILS_FAIL',
  POST_EXAM_DETAILS_RESET: 'POST_EXAM_DETAILS_RESET',
};
