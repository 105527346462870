import { notificationActions } from '../../constants/actionTypes';

const initialState = {
  show: false,
  data: '',
  severity: 'success',
  duration: 2000,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActions.SET_NOTIFICATION:
      return {
        ...state,
        show: true,
        data: action.payload?.data || '',
        severity: action.payload?.severity || 'success',
        duration: action.payload?.duration || 2000,
      };

    case notificationActions.RESET_NOTIFICATION:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default notificationReducer;
