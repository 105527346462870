import * as React from 'react';

const LogoutSvg = () => {
  return (
    <svg
      id="logout_black_24dp_1_"
      data-name="logout_black_24dp (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_12172" data-name="Group 12172">
        <path
          id="Path_43623"
          data-name="Path 43623"
          d="M0,0H24V24H0Z"
          fill="none"
        />
      </g>
      <g id="Group_12173" data-name="Group 12173">
        <path
          id="Path_43624"
          data-name="Path 43624"
          d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default LogoutSvg;
