import { put, takeLatest, all, call } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  getAllProctors,
  getProctor,
  registerProctor,
  updateProctor,
  deleteProctorAPI,
} from '../api';
import {
  getProctorsSuccess,
  getProctorsFail,
  getProctorDetailsSuccess,
  getProctorDetailsFail,
  addProctorSuccess,
  addProctorFail,
  updateProctorSuccess,
  updateProctorFail,
  deleteProctor,
  deleteProctorSuccess,
  deleteProctorFail,
} from '../actions/proctorOnboardingFormActions';
import { setNotification } from '../actions/notificationActions';
import { onboardingActions } from '../../constants/proctorOnboardingFromActionTypes';
import { statusCode } from '../../constants/apis/apiStatus';

export function* getProctorsFunction(action) {
  try {
    const response = yield call(getAllProctors, action.payload);
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getProctorsSuccess(response));
    } else {
      yield put(getProctorsFail(response?.response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* deleteIndividualProctor(payload) {
  try {
    const response = yield call(deleteProctorAPI, payload);
    if (response?.status.code === statusCode.Ok200) {
      yield put(deleteProctorSuccess(response));
      yield put(
        setNotification({
          data: response?.status?.message || i18n.t('something.wrong'),
          severity: 'success',
        })
      );
    } else {
      yield put(deleteProctorFail(response?.response));
      yield put(
        setNotification({
          data: response?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getProctorDetails(action) {
  try {
    const response = yield call(getProctor, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getProctorDetailsSuccess(response));
    } else {
      yield put(getProctorDetailsFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* registerProctorDetails(action) {
  try {
    const response = yield call(registerProctor, action.payload);
    if (response?.status === statusCode.Created201) {
      yield put(addProctorSuccess(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(addProctorFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* updateProctorDetails(action) {
  try {
    const response = yield call(updateProctor, action);
    if (response?.status === statusCode.Ok200) {
      yield put(updateProctorSuccess(response));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(updateProctorFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* watchDummy() {
  yield takeLatest(onboardingActions.GET_PROCTORS, getProctorsFunction);
  yield takeLatest(onboardingActions.GET_PROCTOR_DETAILS, getProctorDetails);
  yield takeLatest(onboardingActions.ADD_PROCTOR, registerProctorDetails);
  yield takeLatest(onboardingActions.SET_PROCTOR_DETAILS, updateProctorDetails);
  yield takeLatest(onboardingActions.DELETE_PROCTOR, deleteIndividualProctor);
}

export default function* onboardingFormSaga() {
  yield all([watchDummy()]);
}
