import React from 'react';

function PauseSession() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_12620"
        data-name="Group 12620"
        transform="translate(-604 -68)"
      >
        <circle
          id="Ellipse_715"
          data-name="Ellipse 715"
          cx="16"
          cy="16"
          r="16"
          transform="translate(608 72)"
          fill="#ff9700"
        />
        <g
          id="Icon_Button_40"
          data-name="Icon Button 40"
          transform="translate(604 68)"
        >
          <rect id="Base" width="40" height="40" fill="none" />
          <g id="ico_pause" transform="translate(8 8)">
            <path
              id="bx-pause"
              d="M13.333,11.667h5V28.334h-5Zm8.333,0h5V28.334h-5Z"
              transform="translate(-8.333 -7.668)"
              fill="#fff"
            />
            <rect
              id="Base-2"
              data-name="Base"
              width="24"
              height="24"
              fill="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PauseSession;
