import axiosInstance from '../../translator';
import { dashboardAPI } from '../../constants/apis/apiEndpoints';

export const getSuperAdminDashboardApi = async (payload) => {
  return axiosInstance
    .get(dashboardAPI?.superAdmin)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getTenantAdminDashboardApi = async (payload) => {
  return axiosInstance
    .get(dashboardAPI?.tenantAdmin)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getProctorDashboardApi = async (payload) => {
  return axiosInstance
    .get(`${dashboardAPI?.proctors}/${payload?.user_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getUpcomingAssignmentApi = async (payload) => {
  return axiosInstance
    .post(dashboardAPI?.upcomingAssessment, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
