import axiosInstance from '../../translator';
import { tenantAPI } from '../../constants/apis/apiEndpoints';
import { translator } from '../../constants/actionTypes';

const generateQueryParams = (payload) => {
  const params = {};
  params.limit = payload?.limit || 5;
  params.offset = payload?.offset || 1;
  params.search = payload?.search || '';
  params.ordering = payload?.ordering || '-created_at';
  return params;
};

export const getTenantListAPI = async (payload) => {
  const params = generateQueryParams(payload);
  return axiosInstance
    .get(`${tenantAPI?.tenantList}`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const checkTenantNameAvailabilityAPI = async (payload) => {
  const api = tenantAPI.tenant_name_availability + '/' + payload.tenant_name;
  return axiosInstance
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const tenantInfrastructureSetupApi = async (payload) => {
  const api = tenantAPI.tenant_infrastructure_setup + '/' + payload?.tenant_id;
  return axiosInstance
    .post(api)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
