import { put, takeLatest, all, call } from 'redux-saga/effects';
import i18n from 'i18next';
import _ from 'lodash';
import { tenantActions } from '../../constants/actionTypes';
import { statusCode } from '../../constants/apis/apiStatus';
import {
  tenantGeneralDetailsActionError,
  tenantGeneralDetailsActionSuccess,
  tenantInfrastructureActionError,
  tenantInfrastructureActionSuccess,
  getTenantListSuccess,
  getTenantListFail,
  getCandidateOnboardingAndVerificationRulesListSuccess,
  getProctoringRulesListSuccess,
  getTenantGeneralDetailsSuccess,
  getTenantInfrastructureDetailsSuccess,
  proctoringRulesActionSuccess,
  proctoringRulesActionError,
  candidateOnboardingAndVerificationRulesActionSuccess,
  candidateOnboardingAndVerificationRulesActionError,
  getTenantInfrastructureDetailsFail,
  tenantNameAvailability,
  tenantNameAvailabilitySuccess,
  tenantInfrastructureSetupSuccess,
  tenantInfrastructureSetupError,
} from '../actions/tenantActions';
import {
  tenantGeneralDetails as tenantGeneralDetailsAPI,
  tenantInfrastructure as tenantInfrastructureAPI,
  getCandidateOnboardingAndVerificationRules as getCandidateOnboardingAndVerificationRulesAPI,
  getProctoringRules as getProctoringRulesAPI,
  proctoringRules as proctoringRulesAPI,
  candidateOnboardingAndVerificationRules as candidateOnboardingAndVerificationRulesAPI,
  getTenantGeneralDetails as getTenantGeneralDetailsAPI,
  getTenantInfrastructureDetails as getTenantInfrastructureDetailsAPI,
} from '../api/api';

import {
  checkTenantNameAvailabilityAPI,
  getTenantListAPI,
  tenantInfrastructureSetupApi,
} from '../api';

import { setNotification } from '../actions';

export function* tenantGeneralDetails({ payload }) {
  const response = yield call(tenantGeneralDetailsAPI, payload);
  try {
    if (
      response?.data?.status?.code === statusCode.Created201 ||
      response?.data?.status?.code === statusCode.Ok200
    ) {
      yield put(tenantGeneralDetailsActionSuccess(response?.data?.data));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(tenantGeneralDetailsActionError(payload));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(tenantGeneralDetailsActionError(payload));
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}

export function* getTenantListFunction(action) {
  const response = yield call(getTenantListAPI, action.payload);
  try {
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getTenantListSuccess(response));
    } else {
      yield put(getTenantListFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* tenantInfrastructure({ payload }) {
  const response = yield call(tenantInfrastructureAPI, payload);
  try {
    if (
      response?.data?.status?.code === statusCode.Created201 ||
      response?.data?.status?.code === statusCode.Ok200
    ) {
      yield put(tenantInfrastructureActionSuccess(payload));
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(tenantInfrastructureActionError(payload));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(tenantGeneralDetailsActionError(payload));
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}

export function* getCandidateOnboardingAndVerificationRulesFunction(action) {
  const response = yield call(
    getCandidateOnboardingAndVerificationRulesAPI,
    action.payload
  );
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(
        getCandidateOnboardingAndVerificationRulesListSuccess(
          response?.data?.data
        )
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}
export function* getProctoringRulesFunction(action) {
  const response = yield call(getProctoringRulesAPI, action.payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(getProctoringRulesListSuccess(response?.data?.data));
    }
  } catch (err) {
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}

export function* postProctoringRulesFunction(action) {
  const response = yield call(proctoringRulesAPI, action.payload);
  try {
    if (
      response?.data?.status?.code === statusCode.Ok200 ||
      response?.data?.status?.code === statusCode.Created201
    ) {
      yield put(proctoringRulesActionSuccess(response?.data?.data));
    } else {
      yield put(
        proctoringRulesActionError({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      proctoringRulesActionError({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}

export function* postCandidateOnboardingAndVerificationRulesFunction(action) {
  const response = yield call(
    candidateOnboardingAndVerificationRulesAPI,
    action.payload
  );
  try {
    if (
      response?.data?.status?.code === statusCode.Ok200 ||
      response?.data?.status?.code === statusCode.Created201
    ) {
      yield put(
        candidateOnboardingAndVerificationRulesActionSuccess(
          response?.data?.data
        )
      );
    } else {
      yield put(
        candidateOnboardingAndVerificationRulesActionError(action.payload)
      );
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      candidateOnboardingAndVerificationRulesActionError(action.payload)
    );
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}

export function* getTenantGeneralDetailsFunction(action) {
  const response = yield call(getTenantGeneralDetailsAPI, action.payload);
  try {
    if (response?.data?.status?.code === statusCode.Ok200) {
      yield put(getTenantGeneralDetailsSuccess(response?.data?.data));
    }
  } catch (err) {
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}

export function* getTenantInfrastructureDetailsFunction(action) {
  const response = yield call(
    getTenantInfrastructureDetailsAPI,
    action.payload
  );

  try {
    const result = response?.data?.data ? response?.data?.data : null;
    if (
      response?.data?.status?.code === statusCode.Ok200 &&
      !_.isEmpty(result)
    ) {
      yield put(getTenantInfrastructureDetailsSuccess(result));
    } else {
      yield put(getTenantInfrastructureDetailsFail());
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: response?.data?.status?.message || i18n.t('something.wrong'),
        severity: 'error',
      })
    );
  }
}

export function* tenantNameAvailabilityFunction(action) {
  const response = yield call(checkTenantNameAvailabilityAPI, action.payload);
  try {
    if (response?.status?.code === statusCode.Ok200) {
      yield put(tenantNameAvailabilitySuccess(response?.data?.isAvailable));
    } else {
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* tenantInfrastructureSetupFunction(action) {
  const response = yield call(tenantInfrastructureSetupApi, action.payload);
  try {
    if (response?.data?.status?.code === statusCode.Created201) {
      yield put(tenantInfrastructureSetupSuccess());
      yield put(
        setNotification({
          data: response?.data?.status?.message,
          severity: 'success',
        })
      );
    } else {
      yield put(tenantInfrastructureSetupError());
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (err) {
    yield put(tenantInfrastructureSetupError());
    yield put(
      setNotification({
        data: i18n.t('server-error'),
        severity: 'error',
      })
    );
  }
}

export function* watchDummy() {
  yield takeLatest(
    tenantActions.TENANT_NAME_AVAILABILITY,
    tenantNameAvailabilityFunction
  );
  yield takeLatest(tenantActions.GET_TENANT_LIST, getTenantListFunction);
  yield takeLatest(
    tenantActions.TENANT_GENERAL_DETAILS_ACTION,
    tenantGeneralDetails
  );
  yield takeLatest(tenantActions.TENANT_INFRA_ACTION, tenantInfrastructure);
  yield takeLatest(
    tenantActions.GET_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_LIST,
    getCandidateOnboardingAndVerificationRulesFunction
  );

  yield takeLatest(
    tenantActions.GET_PROCTORING_RULES_LIST,
    getProctoringRulesFunction
  );

  yield takeLatest(
    tenantActions.GET_TENANT_GENERAL_DETAILS,
    getTenantGeneralDetailsFunction
  );

  yield takeLatest(
    tenantActions.GET_TENANT_INFRA_DETAILS,
    getTenantInfrastructureDetailsFunction
  );

  yield takeLatest(
    tenantActions.TENANT_PROCTORING_RULES_ACTION,
    postProctoringRulesFunction
  );

  yield takeLatest(
    tenantActions.TENANT_CANDIDATE_ONBOARDING_AND_VERIFICATION_RULES_ACTION,
    postCandidateOnboardingAndVerificationRulesFunction
  );

  yield takeLatest(
    tenantActions.TENANT_INFRA_SETUP,
    tenantInfrastructureSetupFunction
  );
}

export default function* tenantSaga() {
  yield all([watchDummy()]);
}
