//Vendors
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTheme } from 'iiht-proctoring-ui-components/build/components/Styles';
import Box from 'iiht-proctoring-ui-components/build/components/Box';
import IconButton from 'iiht-proctoring-ui-components/build/components/IconButton';
import Typography from 'iiht-proctoring-ui-components/build/components/Typography';
import useWebSocket from '../../../customHooks/useWebSocket';
import { translator } from '../../../constants/actionTypes';
import { resetParticipantAlert } from '../../../store/actions/janusActions';
import EndExamDialog from './EndExamDialog';
import {
  endAssessments,
  examEvent,
} from '../../../store/actions/assessmentActions';
import EndAssessment from '../../proctoring/EndAssessment';
import { CircularProgress } from 'iiht-proctoring-ui-components/build/components';

//Icons
import VoiceCall from '../../../icons/VoiceCall';
import RoomScan from '../../../icons/RoomScan';
import PauseSession from '../../../icons/PauseSession';
import EndSession from '../../../icons/EndSession';
import VoiceCallEnd from '../../../icons/VoiceCallEnd';

//Style
import './headerStyle.scss';

const HeaderIcons = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [audioCallEnabled, setAudioCallEnabled] = useState(false);
  const [isRoomScanRequested, setIsRoomScanRequested] = useState(false);
  const [isPauseExamRequested, setIsPauseExamRequested] = useState(false);
  const [roomScanAttempt, setRoomScanAttempt] = useState(null);
  const [pauseExamAttempt, setPauseExamAttempt] = useState(null);
  const [isEndAssessmentRequested, setIsEndAssessmentRequested] =
    useState(false);
  const [thresholdExceed, setTresholdExceed] = useState(false);

  const { detailViewCandidate, candidateDetails, examDetails } = useSelector(
    (state) => state.proctoringReducer
  );
  const { userDetails } = useSelector((state) => state.userAuthentication);
  const {
    isAssessmentEnded,
    isAssessmentEndedLoading,
    assessmentList,
    janusDetails,
  } = useSelector((state) => state.assessmentReducer);

  const [examDialogOpen, setExamDialogOpen] = useState(false);

  const generateExamEventData = (eventType) => {
    return {
      data: {
        test_taker_id: detailViewCandidate?.id,
        proctor_id: userDetails?.id,
        room_id: janusDetails?.room_number,
        exam_id: janusDetails?.exam_id,
        event_type: eventType,
        event_on: new Date().toISOString(),
      },
      exam_event: eventType,
    };
  };

  useEffect(() => {
    if (!_.isEmpty(examDetails?.proctoring_rules)) {
      let roomScan = examDetails?.proctoring_rules?.find(
        (rule) => rule?.rule_tag === 'room_scan_request'
      );
      let pauseExam = examDetails?.proctoring_rules?.find(
        (rule) => rule?.rule_tag === 'pause_exam_request'
      );
      if (roomScan?.usable)
        setRoomScanAttempt(Number(roomScan?.param2_selected_values));
      if (pauseExam?.usable)
        setPauseExamAttempt(Number(pauseExam?.param2_selected_values));
    }
  }, [examDetails?.proctoring_rules]);

  const onAudioCallClick = () => {
    setAudioCallEnabled(true);
    sendCallMessage({
      data: {
        alert_type: 'call',
        alert_for: detailViewCandidate?.id,
        call_status: true,
        alert_from: userDetails?.id,
      },
    });
  };

  useEffect(() => {
    if (!_.isNull(roomScanAttempt) && roomScanAttempt === -1) {
      setTresholdExceed(true);
      setTimeout(() => {
        setExamDialogOpen(true);
      }, 1000);
    }
  }, [roomScanAttempt]);
  useEffect(() => {
    if (!_.isNull(pauseExamAttempt) && pauseExamAttempt === -1) {
      setTresholdExceed(true);
      setTimeout(() => {
        setExamDialogOpen(true);
      }, 1000);
    }
  }, [pauseExamAttempt]);

  const onRoomScanRequestClick = () => {
    !_.isNull(roomScanAttempt) && setRoomScanAttempt(roomScanAttempt - 1);
    setIsRoomScanRequested(true);
    sendCallMessage({
      data: {
        alert_type: 'roomScan',
        alert_for: detailViewCandidate?.id,
        status: 'request',
        alert_from: userDetails?.id,
      },
    });
    common.sendMessage(
      JSON.stringify({
        data: {
          exam_event: 'room_scan',
          alert_for: candidateDetails?.email_id,
        },
      })
    );
    const examEventDetails = generateExamEventData('room_scan');
    dispatch(examEvent(examEventDetails));
  };

  const onRoomScanSuccessClick = () => {
    setIsRoomScanRequested(false);
    sendCallMessage({
      data: {
        alert_type: 'roomScan',
        alert_for: detailViewCandidate?.id,
        status: 'success',
        alert_from: userDetails?.id,
      },
    });
  };

  const makeCall = (isCall) => {
    let testTaker = document.getElementById(
      `remote-detail-${detailViewCandidate?.id}`
    );
    testTaker.muted = !isCall;
  };
  const onEndAudioCallClick = () => {
    setAudioCallEnabled(false);
    makeCall(false);
    dispatch(
      resetParticipantAlert({
        alert_type: 'call',
        participant_id: detailViewCandidate?.id,
      })
    );
    sendCallMessage({
      data: {
        alert_type: 'call',
        alert_for: detailViewCandidate?.id,
        call_status: false,
        alert_from: userDetails?.id,
      },
    });
  };

  //
  const roomId = `${detailViewCandidate?.id}_audio`; // TODO: Attach Proctor Id
  const url = translator.wsUrl;

  const onMessage = useCallback((event) => {
    let data = JSON.parse(event.data);
    if (data?.message.data.alert_for === detailViewCandidate?.id) {
      switch (data?.message.data.alert_type) {
        case 'call': {
          makeCall(data.message.data.call_status);
        }
      }
    }
  }, []);

  const onWebSocketOpen = useCallback((ws) => {
    console.log('onWebSocketOpen', ws);
  }, []);

  const { sendMessage } = useWebSocket(
    url,
    roomId,
    () => {},
    onWebSocketOpen,
    onMessage
  );

  const sendCallMessage = useCallback(
    (value) => {
      sendMessage(JSON.stringify(value));
    },
    [sendMessage]
  );

  // WebSocket communication with LMS Start

  const onCommonChannelMessage = useCallback((event) => {
    console.log(event.data);
  }, []);

  const commonChannelRoomDetails = `${
    assessmentList?.filter(
      (exam) => exam?.exam_id === janusDetails?.exam_id
    )?.[0]?.exam_id_from_lms
  }-commonchannel`;

  const onCommonChannelWebSocketOpen = useCallback((ws) => {}, []);

  const common = useWebSocket(
    url,
    commonChannelRoomDetails,
    () => {},
    onCommonChannelWebSocketOpen,
    onCommonChannelMessage
  );
  //WebSocket Section End

  useEffect(() => {
    // Automatic Call Connect when candidate requested for call
    if (detailViewCandidate?.alerts?.includes('call')) {
      onAudioCallClick();
    }

    return () => {
      setAudioCallEnabled(false);
      setIsRoomScanRequested(false);
      sendCallMessage({
        data: {
          alert_type: 'call',
          alert_for: detailViewCandidate?.id,
          alert_message: false,
        },
      });
    };
  }, []);

  const endAssessmentHandler = () => {
    setExamDialogOpen(true);
  };

  const onConfirm = (value) => {
    dispatch(
      endAssessments({
        test_taker_list: [detailViewCandidate?.id],
        exam_status: 'aborted',
        exam_end_comment: value,
      })
    );
    common.sendMessage(
      JSON.stringify({
        data: {
          exam_event: 'terminate_exam',
          alert_for: candidateDetails?.email_id,
          terminated_by: 'proctor',
        },
      })
    );
    const examEventDetails = generateExamEventData('terminate_exam');
    dispatch(examEvent(examEventDetails));
    setIsEndAssessmentRequested(true);
    setExamDialogOpen(false);
  };

  const onDismiss = () => {
    setExamDialogOpen(false);
  };

  const onPauseSessionHandlerRequest = () => {
    !_.isNull(pauseExamAttempt) && setPauseExamAttempt(pauseExamAttempt - 1);
    setIsPauseExamRequested(true);
    common.sendMessage(
      JSON.stringify({
        data: {
          exam_event: 'pause_exam',
          alert_for: candidateDetails?.email_id,
        },
      })
    );
    const examEventDetails = generateExamEventData('pause_exam');
    dispatch(examEvent(examEventDetails));
  };

  const onPauseSessionHandlerSuccess = () => {
    common.sendMessage(
      JSON.stringify({
        data: {
          exam_event: 'resume_exam',
          alert_for: candidateDetails?.email_id,
        },
      })
    );
    setIsPauseExamRequested(false);
    const examEventDetails = generateExamEventData('resume_exam');
    dispatch(examEvent(examEventDetails));
  };

  return (
    <>
      <Box
        className="headerIconWrapper"
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Box className="headerIconContainer">
          <Box
            className="iconsWrapper"
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <IconButton
              sx={{ p: 0 }}
              onClick={
                audioCallEnabled ? onEndAudioCallClick : onAudioCallClick
              }
            >
              {audioCallEnabled ? <VoiceCallEnd /> : <VoiceCall />}
            </IconButton>
            <Typography
              sx={{
                ...theme.typography.caption,
                color: theme.palette.common.white,
              }}
            >
              {audioCallEnabled ? t('end.call') : t('voice.call')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton
              sx={{ p: 0 }}
              onClick={
                isRoomScanRequested
                  ? onRoomScanSuccessClick
                  : onRoomScanRequestClick
              }
            >
              <RoomScan />
            </IconButton>
            <Typography
              sx={{
                ...theme.typography.caption,
                color: theme.palette.common.white,
              }}
            >
              {isRoomScanRequested ? t('approve') : t('room.scan')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton
              sx={{ p: 0 }}
              onClick={
                isPauseExamRequested
                  ? onPauseSessionHandlerSuccess
                  : onPauseSessionHandlerRequest
              }
            >
              <PauseSession />
            </IconButton>
            <Typography
              sx={{
                ...theme.typography.caption,
                color: theme.palette.common.white,
              }}
            >
              {isPauseExamRequested ? t('resume.session') : t('pause.session')}
            </Typography>
          </Box>
          {isEndAssessmentRequested && <EndAssessment />}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton sx={{ p: 0 }} onClick={endAssessmentHandler}>
              {isAssessmentEndedLoading ? (
                <CircularProgress size="1rem" />
              ) : (
                <EndSession />
              )}
            </IconButton>
            <Typography
              sx={{
                ...theme.typography.caption,
                color: theme.palette.common.white,
              }}
            >
              {t('end.session')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <EndExamDialog
        open={examDialogOpen}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        testTakerName={detailViewCandidate?.name || ''}
        thresholdExceed={thresholdExceed}
      />
    </>
  );
};

export default HeaderIcons;
