import { put, takeLatest, all, call } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  getAssessmentList,
  getOnGoingExam,
  getAssessmentDetails,
  getAssessmentInfo,
  getAssessmentJanusDetails,
  endAssessment,
  examEvent,
} from '../api';
import {
  getAssessmentsSuccess,
  getAssessmentsFail,
  getOnGoingExamSuccess,
  getOnGoingExamFail,
  getAssessmentDetailsSuccess,
  getAssessmentDetailsFail,
  getAssessmentInfoSuccess,
  getAssessmentInfoFail,
  getAssessmentJanusDetailsSuccess,
  getAssessmentJanusDetailsFail,
  endAssessments,
  endAssessmentsSuccess,
  endAssessmentsFail,
  examEventSuccess,
  examEventFail,
} from '../actions/assessmentActions';
import { assessmentActions } from '../../constants/assessmentActionTypes';
import { statusCode } from '../../constants/apis/apiStatus';
import { setNotification } from '../actions/notificationActions';

export function* getAssessmentsFunction(action) {
  try {
    const response = yield call(getAssessmentList, action.payload);
    if (response?.status?.code === statusCode.Ok200) {
      yield put(getAssessmentsSuccess(response));
    } else {
      yield put(getAssessmentsFail(response?.response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getOnGoingExamFunction(action) {
  try {
    const response = yield call(getOnGoingExam, action.payload);
    if (response?.data.status.code === statusCode.Ok200) {
      yield put(getOnGoingExamSuccess(response));
    } else {
      yield put(getOnGoingExamFail(response?.response));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getAssessmentDetailsFunction(action) {
  try {
    const response = yield call(getAssessmentDetails, action.payload);
    // TODO: Need to remove when updated with actual API
    yield put(getAssessmentDetailsSuccess(response));
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentDetailsSuccess(response));
    } else {
      yield put(getAssessmentDetailsFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getAssessmentInfoFunction(action) {
  try {
    const response = yield call(getAssessmentInfo, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentInfoSuccess(response));
    } else {
      yield put(getAssessmentInfoFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getAssessmentJanusDetailsFunction(action) {
  try {
    const response = yield call(getAssessmentJanusDetails, action.payload);
    if (response?.status === statusCode.Ok200) {
      yield put(getAssessmentJanusDetailsSuccess(response));
    } else {
      yield put(getAssessmentJanusDetailsFail(response?.response));
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* endAssessmentsFunction(action) {
  try {
    const response = yield call(endAssessment, action.payload);

    if (response?.status === statusCode.Ok200) {
      yield put(endAssessmentsSuccess(response));
      setNotification({
        data: response?.data?.status?.message,
        severity: 'success',
      });
    } else {
      yield put(endAssessmentsFail());
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* examEventFunction(action) {
  try {
    const response = yield call(examEvent, action.payload);

    if (response?.status === statusCode.Ok200) {
      yield put(examEventSuccess(response));
      setNotification({
        data: response?.data?.status?.message,
        severity: 'success',
      });
    } else {
      yield put(examEventFail());
      yield put(
        setNotification({
          data: response?.data?.status?.message || i18n.t('something.wrong'),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* watchDummy() {
  yield takeLatest(assessmentActions.GET_ASSESSMENTS, getAssessmentsFunction);
  yield takeLatest(assessmentActions.GET_ONGOING_EXAM, getOnGoingExamFunction);
  yield takeLatest(
    assessmentActions.GET_ASSESSMENT_DETAILS,
    getAssessmentDetailsFunction
  );
  yield takeLatest(
    assessmentActions.GET_ASSESSMENT_INFO,
    getAssessmentInfoFunction
  );
  yield takeLatest(
    assessmentActions.GET_ASSESSMENT_JANUS_DETAILS,
    getAssessmentJanusDetailsFunction
  );
  yield takeLatest(assessmentActions.END_ASSESSMENTS, endAssessmentsFunction);
  yield takeLatest(assessmentActions.EXAM_EVENT, examEventFunction);
}

export default function* assessmentSaga() {
  yield all([watchDummy()]);
}
