export const reportActions = {
  GET_ASSESSMENT_REPORT_DATA: 'GET_ASSESSMENT_REPORT_DATA',
  GET_ASSESSMENT_REPORT_DATA_SUCCESS: 'GET_ASSESSMENT_REPORT_DATA_SUCCESS',
  GET_ASSESSMENT_REPORT_DATA_FAIL: 'GET_ASSESSMENT_REPORT_DATA_FAIL',
  GET_ASSESSMENT_REPORT_DATA_RESET: 'GET_ASSESSMENT_REPORT_DATA_RESET',

  GET_CANDIDATE_DETAIL_REPORT: 'GET_CANDIDATE_DETAIL_REPORT',
  GET_CANDIDATE_DETAIL_REPORT_SUCCESS: 'GET_CANDIDATE_DETAIL_REPORT_SUCCESS',
  GET_CANDIDATE_DETAIL_REPORT_FAIL: 'GET_CANDIDATE_DETAIL_REPORT_FAIL',
  GET_CANDIDATE_DETAIL_REPORT_RESET: 'GET_CANDIDATE_DETAIL_REPORT_RESET',

  GET_CANDIDATE_VIOLATION_LIST: 'GET_CANDIDATE_VIOLATION_LIST',
  GET_CANDIDATE_VIOLATION_LIST_SUCCESS: 'GET_CANDIDATE_VIOLATION_LIST_SUCCESS',
  GET_CANDIDATE_VIOLATION_LIST_FAIL: 'GET_CANDIDATE_VIOLATION_LIST_FAIL',
  GET_CANDIDATE_VIOLATION_LIST_RESET: 'GET_CANDIDATE_VIOLATION_LIST_RESET',

  GET_VIOLATION_LIST: 'GET_VIOLATION_LIST',
  GET_VIOLATION_LIST_SUCCESS: 'GET_VIOLATION_LIST_SUCCESS',
  GET_VIOLATION_LIST_FAIL: 'GET_VIOLATION_LIST_FAIL',
  GET_VIOLATION_LIST_RESET: 'GET_VIOLATION_LIST_RESET',

  GET_REVIEW_SESSION_DETAILS: 'GET_REVIEW_SESSION_DETAILS',
  GET_REVIEW_SESSION_DETAILS_SUCCESS: 'GET_REVIEW_SESSION_DETAILS_SUCCESS',
  GET_REVIEW_SESSION_DETAILS_FAIL: 'GET_REVIEW_SESSION_DETAILS_FAIL',
  GET_REVIEW_SESSION_DETAILS_RESET: 'GET_REVIEW_SESSION_DETAILS_RESET',

  GET_AI_ALERT_LIST: 'GET_AI_ALERT_LIST',
  GET_AI_ALERT_LIST_SUCCESS: 'GET_AI_ALERT_LIST_SUCCESS',
  GET_AI_ALERT_LIST_FAIL: 'GET_AI_ALERT_LIST_FAIL',
  GET_AI_ALERT_LIST_RESET: 'GET_AI_ALERT_LIST_RESET',

  CANDIDATE_PROCTORING_STATUS: 'CANDIDATE_PROCTORING_STATUS',
  CANDIDATE_PROCTORING_STATUS_SUCCESS: 'CANDIDATE_PROCTORING_STATUS_SUCCESS',
  CANDIDATE_PROCTORING_STATUS_FAIL: 'CANDIDATE_PROCTORING_STATUS_FAIL',

  GET_ASSESSMENT_CANDIDATES_DATA: 'GET_ASSESSMENT_CANDIDATES_DATA',
  GET_ASSESSMENT_CANDIDATES_DATA_SUCCESS:
    'GET_ASSESSMENT_CANDIDATES_DATA_SUCCESS',
  GET_ASSESSMENT_CANDIDATES_DATA_FAIL: 'GET_ASSESSMENT_CANDIDATES_DATA_FAIL',
  GET_ASSESSMENT_CANDIDATES_DATA_RESET: 'GET_ASSESSMENT_CANDIDATES_DATA_RESET',

  GET_CANDIDATES_DATA: 'GET_CANDIDATES_DATA',
  GET_CANDIDATES_DATA_SUCCESS: 'GET_CANDIDATES_DATA_SUCCESS',
  GET_CANDIDATES_DATA_FAIL: 'GET_CANDIDATES_DATA_FAIL',
  GET_CANDIDATES_DATA_RESET: 'GET_CANDIDATES_DATA_RESET',

  DOWNLOAD_CANDIDATES_REPORT: 'DOWNLOAD_CANDIDATES_REPORT',
  DOWNLOAD_CANDIDATES_REPORT_SUCCESS: 'DOWNLOAD_CANDIDATES_REPORT_SUCCESS',
  DOWNLOAD_CANDIDATES_REPORT_FAIL: 'DOWNLOAD_CANDIDATES_REPORT_FAIL',
  DOWNLOAD_CANDIDATES_REPORT_RESET: 'DOWNLOAD_CANDIDATES_REPORT_RESET',

  GET_EXAM_VIDEO_LINK: 'GET_EXAM_VIDEO_LINK',
  GET_EXAM_VIDEO_LINK_SUCCESS: 'GET_EXAM_VIDEO_LINK_SUCCESS',
  GET_EXAM_VIDEO_LINK_FAIL: 'GET_EXAM_VIDEO_LINK_FAIL',
  GET_EXAM_VIDEO_LINK_RESET: 'GET_EXAM_VIDEO_LINK_RESET',

  DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT: 'DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT',
  DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT_SUCCESS: 'DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT_SUCCESS',
  DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT_FAIL: 'DOWNLOAD_CANDIDATES_IN_DATE_RANGE_REPORT_FAIL',
};
