import React from 'react';
import Container from 'iiht-proctoring-ui-components/build/components/Container';
import HeaderContainer from '../header/headerContainer';
import FooterContainer from '../footer/footerContainer';
import './layoutStyle.scss';

const LayoutView = ({ children }) => {
  return (
    <>
      <div className="layoutContainer">
        <HeaderContainer />
        <Container maxWidth="xl" sx={{ mt: 6, mb: 6, minHeight: '80vh' }}>
          {children}
        </Container>
        <FooterContainer />
      </div>
    </>
  );
};

export default LayoutView;
