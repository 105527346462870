import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import loginReducer from './loginReducer';
import onboardingFormReducer from './proctorOnboardingFormReducer';
import notificationReducer from './notificationReducer';
import tenantReducer from './tenantReducer';
import tenantAdminReducer from './tenantAdminReducer';
import assessmentReducer from './assessmentReducer';
import chatReducer from './chatReducer';
import proctoringReducer from './proctoringReducer';
import janusReducer from './janusReducer';
import approverReducer from './approverReducer';
import reportReducer from './reportReducer';
import dashboardReducer from './dashboardReducer';

const MainReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    userAuthentication: loginReducer,
    onboardingForm: onboardingFormReducer,
    notificationReducer: notificationReducer,
    tenantReducer: tenantReducer,
    tenantAdminReducer: tenantAdminReducer,
    assessmentReducer: assessmentReducer,
    chatReducer: chatReducer,
    proctoringReducer: proctoringReducer,
    janusReducer: janusReducer,
    approverReducer: approverReducer,
    reportReducer: reportReducer,
    dashboardReducer: dashboardReducer,
  });

export default MainReducer;
