import { all, fork } from 'redux-saga/effects';
import Authentication from './Authentication';
import loginSaga from './loginSaga';
import onboardingFormSaga from './proctorOnboardingFormSaga';
import tenantSaga from './tenantSaga';
import tenantAdminSaga from './tenantAdminSaga';
import assessmentSaga from './assessmentSaga';
import proctoringSaga from './proctoringSaga';
import approverSaga from './approverSaga';
import reportSaga from './reportSaga';
import dashboardSaga from './dashboardSaga';

export default function* rootSaga() {
  yield all([
    fork(Authentication),
    fork(loginSaga),
    fork(onboardingFormSaga),
    fork(tenantSaga),
    fork(tenantAdminSaga),
    fork(assessmentSaga),
    fork(proctoringSaga),
    fork(approverSaga),
    fork(reportSaga),
    fork(dashboardSaga),
  ]);
}
