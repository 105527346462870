import * as React from 'react';

function ProctorLogo(props) {
  return (
    <svg
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      width="192"
      height="36"
      viewBox="0 0 192 36"
    >
      <rect
        id="Rectangle_4019"
        data-name="Rectangle 4019"
        width="192"
        height="36"
        fill="#0a2972"
      />
      <g
        id="Group_12126"
        data-name="Group 12126"
        transform="translate(-32.5 -8)"
      >
        <g
          id="Iconly_Bold_Profile"
          data-name="Iconly/Bold/Profile"
          transform="translate(45.326 16.97)"
        >
          <g id="Profile" transform="translate(0 0)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M5.94,9.782c3.222,0,5.94.523,5.94,2.543s-2.737,2.525-5.94,2.525c-3.221,0-5.94-.523-5.94-2.543S2.736,9.782,5.94,9.782ZM5.94,0A3.929,3.929,0,1,1,2.009,3.929,3.916,3.916,0,0,1,5.94,0Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Icon_feather-search"
          data-name="Icon feather-search"
          transform="translate(40.5 14)"
        >
          <path
            id="Path_2"
            data-name="Path 2"
            d="M26.217,15.358A10.858,10.858,0,1,1,15.358,4.5,10.858,10.858,0,0,1,26.217,15.358Z"
            transform="translate(-4.5 -4.5)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M30.879,30.879l-5.9-5.9"
            transform="translate(-6.448 -6.448)"
            fill="#00bcd4"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
      <text
        id="Proctor.ai"
        transform="translate(44 25)"
        fill="#fff"
        fontSize="21"
        fontFamily="Muli-Black, Muli"
        fontWeight="800"
        letterSpacing="0.05em"
      >
        <tspan x="0" y="0">
          Proctor.ai
        </tspan>
      </text>
    </svg>
  );
}

export default ProctorLogo;
