export const onboardingActions = {
  GET_PROCTORS: 'GET_PROCTORS',
  GET_PROCTORS_SUCCESS: 'GET_PROCTORS_SUCCESS',
  GET_PROCTORS_FAIL: 'GET_PROCTORS_FAIL',

  GET_PROCTOR_DETAILS: 'GET_PROCTOR_DETAILS',
  GET_PROCTOR_DETAILS_SUCCESS: 'GET_PROCTOR_DETAILS_SUCCESS',
  GET_PROCTOR_DETAILS_FAIL: 'GET_PROCTOR_DETAILS_FAIL',

  ADD_PROCTOR: 'ADD_PROCTOR',
  ADD_PROCTOR_SUCCESS: 'ADD_PROCTOR_SUCCESS',
  ADD_PROCTOR_FAIL: 'ADD_PROCTOR_FAIL',
  ADD_PROCTOR_RESET: 'ADD_PROCTOR_RESET',

  SET_PROCTOR_DETAILS: 'SET_PROCTOR_DETAILS',
  SET_PROCTOR_DETAILS_SUCCESS: 'SET_PROCTOR_DETAILS_SUCCESS',
  SET_PROCTOR_DETAILS_FAIL: 'SET_PROCTOR_DETAILS_FAIL',
  SET_PROCTOR_DETAILS_RESET: 'SET_PROCTOR_DETAILS_RESET',

  DELETE_PROCTOR: 'DELETE_PROCTOR',
  DELETE_PROCTOR_SUCCESS: 'DELETE_PROCTOR_SUCCESS',
  DELETE_PROCTOR_FAIL: 'DELETE_PROCTOR_FAIL',
};
