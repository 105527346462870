import { janusActions } from '../../constants/actionTypes';

export const janusStart = (payload) => ({
  type: janusActions.START,
  payload: payload,
});

export const janusConnectLocal = (payload) => ({
  type: janusActions.CONNECT_LOCAL,
  payload: payload,
});

export const addParticipant = (payload) => ({
  type: janusActions.ADD_PARTICIPANT,
  payload: payload,
});

export const removeParticipant = (payload) => ({
  type: janusActions.REMOVE_PARTICIPANT,
  payload: payload,
});

export const resetParticipant = (payload) => ({
  type: janusActions.RESET_PARTICIPANT,
  payload: payload,
});

export const addScreenSharing = (payload) => ({
  type: janusActions.ADD_SCREEN_SHARING,
  payload: payload,
});

export const setParticipantAlert = (payload) => ({
  type: janusActions.SET_PARTICIPANT_ALERT,
  payload: payload,
});

export const resetParticipantAlert = (payload) => ({
  type: janusActions.RESET_PARTICIPANT_ALERT,
  payload: payload,
});

export const resetParticipantAllAlerts = (payload) => ({
  type: janusActions.RESET_PARTICIPANT_ALL_ALERTS,
  payload: payload,
});

export const janusEnd = (payload) => ({
  type: janusActions.END,
  payload: payload,
});

export const janusReset = (payload) => ({
  type: janusActions.RESET,
  payload: payload,
});

export const candidateRemove = (payload) => ({
  type: janusActions.CANDIDATE_REMOVE,
  payload: payload,
});

export const candidateRemoveReset = (payload) => ({
  type: janusActions.CANDIDATE_REMOVE_RESET,
  payload: payload,
});
