//Vendors
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from 'iiht-proctoring-ui-components/build/components/Dialog';
import DialogTitle from 'iiht-proctoring-ui-components/build/components/Dialog/DialogTitle';
import DialogContent from 'iiht-proctoring-ui-components/build/components/DialogContent';
import DialogContentText from 'iiht-proctoring-ui-components/build/components/DialogContentText';
import DialogActions from 'iiht-proctoring-ui-components/build/components/DialogActions';
import MuiButton from 'iiht-proctoring-ui-components/build/components/Button';
import CircularProgress from 'iiht-proctoring-ui-components/build/components/Progress/CircularProgress';
import TextField from 'iiht-proctoring-ui-components/build/components/TextField';
import { useTheme } from 'iiht-proctoring-ui-components/build/components/Styles';

function EndExamDialog(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isError, setError] = useState(false);
  const { open, onDismiss, onConfirm, testTakerName, thresholdExceed } = props;

  const buttonLoading = false;

  useEffect(() => {
    return () => {
      setValue('');
    };
  }, []);

  useEffect(() => {
    if (value !== '') {
      if (/^(?![\s-])[\w\s-]+$/.test(value)) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [value]);

  return (
    <Dialog open={open} onClose={!thresholdExceed && onDismiss}>
      <DialogTitle sx={{ ...theme.typography.h4, pr: 4, pl: 4 }}>
        {t('end.session.title')}
      </DialogTitle>
      <DialogContent sx={{ padding: '32px 32px 24px' }}>
        <DialogContentText
          sx={{
            borderTop: `1px solid ${theme.palette.warning.main}`,
            pt: 4,
            color: theme.palette.grey.P800,
          }}
        >
          {thresholdExceed
            ? t('threshold.exceed', { name: testTakerName })
            : t('end.session.desc', { name: testTakerName })}
        </DialogContentText>
        {!thresholdExceed && (
          <TextField
            label={t('end.session.comment')}
            type="string"
            value={value}
            onChange={(e) => {
              setValue(e?.target?.value);
              value?.length >= 16 && setError(false);
            }}
            error={isError}
            helperText={
              isError
                ? t('valid.comment')
                : value?.length < 16 && t('end.session.error')
            }
            variant="standard"
            sx={{
              mt: 3,
              width: 0.5,
              '& .MuiInputLabel-root': {
                ...theme.typography.bodyText,
              },
            }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ p: 4, gap: 2 }}>
        {!thresholdExceed && (
          <MuiButton
            color="secondary"
            variant="outlined"
            size="medium"
            onClick={onDismiss}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {t('cancel')}
          </MuiButton>
        )}
        {!thresholdExceed && (
          <MuiButton
            color="secondary"
            variant="contained"
            disabled={value === '' || value.length < 16 || isError}
            onClick={() => {
              onConfirm?.(value);
            }}
            size="medium"
            sx={{
              color: theme.typography.button.color,
            }}
          >
            {t('end.session.btn')}
            {buttonLoading && (
              <CircularProgress
                size="1rem"
                sx={{ color: theme.palette.common.white, pl: '4px' }}
              />
            )}
          </MuiButton>
        )}
        {thresholdExceed && (
          <MuiButton
            color="secondary"
            variant="contained"
            onClick={() => {
              onConfirm?.(
                'Multiple room scan/pause exam has been done still too many violations happened!'
              );
            }}
            size="medium"
            sx={{
              color: theme.typography.button.color,
            }}
          >
            {t('okay')}
          </MuiButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default EndExamDialog;
