import { loginActions } from '../../constants/actionTypes';

const INITIAL_STATE = {
  isLoginDetailsLoading: false,
  loginDetails: {},
  loginDetailsError: '',
  userDetails: {
    first_name: '',
    last_name: '',
    role: '',
    id: '',
    isProfileDetailsEdited: false,
  },
  resourcePermissions: [
    {
      module: 'home',
      permission: 'view, add, edit, delete',
    },
    {
      module: 'proctor',
      permission: 'view, add, edit, delete',
    },
    {
      module: 'report',
      permission: 'view',
    },
    {
      module: 'assessment',
      permission: 'view, add, edit',
    },
  ],
  resourceLisPermissionsError: '',
  isConfirmPasswordLoading: false,
  isConfirmPasswordSent: false,
  isConfirmPasswordError: false,
  isForgotPasswordLoading: false,
  isForgotPasswordSent: false,
  isForgotPasswordError: false,
  isChangePasswordSuccess: false,
  isLogoutActionSuccess: false,
};

const proctorReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case loginActions.LOGIN_ACTION: {
      return {
        ...state,
        isLoginDetailsLoading: true,
      };
    }
    case loginActions.LOGIN_ACTION_ERROR: {
      return {
        ...state,
        isLoginDetailsLoading: false,
        loginDetailsError: action?.payload,
      };
    }
    case loginActions.LOGIN_ACTION_RESET: {
      return {
        ...state,
        loginDetailsError: '',
        isLoginDetailsLoading: false,
        loginDetails: {},
      };
    }
    case loginActions.LOGIN_ACTION_SUCCESS: {
      return {
        ...state,
        isLoginDetailsLoading: false,
        loginDetails: action?.payload,
        resourcePermissions: action?.payload?.resource_list,
        userDetails: {
          first_name: action?.payload?.first_name,
          last_name: action?.payload?.last_name,
          email: action?.payload?.email,
          id: action?.payload?.id,
          role:
            action?.payload?.roles?.length > 1
              ? action?.payload?.roles?.join(' ')
              : action?.payload?.roles[0],
        },
      };
    }
    case loginActions.RESET_PASSWORD_ACTION: {
      return {
        ...state,
        isConfirmPasswordLoading: true,
      };
    }
    case loginActions.RESET_PASSWORD_ACTION_SUCCESS: {
      return {
        ...state,
        isConfirmPasswordLoading: false,
        isConfirmPasswordSent: true,
        isConfirmPasswordError: false,
      };
    }
    case loginActions.RESET_PASSWORD_ACTION_ERROR: {
      return {
        ...state,
        isConfirmPasswordLoading: false,
        isConfirmPasswordSent: false,
        isConfirmPasswordError: true,
      };
    }
    case loginActions.RESET_PASSWORD_ACTION_RESET: {
      return {
        ...state,
        isConfirmPasswordLoading: false,
        isConfirmPasswordSent: false,
        isConfirmPasswordError: false,
      };
    }
    case loginActions.FORGET_PASSWORD_ACTION: {
      return {
        ...state,
        isForgotPasswordLoading: true,
      };
    }
    case loginActions.FORGET_PASSWORD_ACTION_ERROR: {
      return {
        ...state,
        isForgotPasswordLoading: false,
        isForgotPasswordSent: false,
        isForgotPasswordError: true,
      };
    }
    case loginActions.FORGET_PASSWORD_ACTION_SUCCESS: {
      return {
        ...state,
        isForgotPasswordLoading: false,
        isForgotPasswordSent: true,
        isForgotPasswordError: false,
      };
    }
    case loginActions.FORGET_PASSWORD_ACTION_RESET: {
      return {
        ...state,
        isForgotPasswordLoading: false,
        isForgotPasswordSent: false,
        isForgotPasswordError: false,
      };
    }
    case loginActions.CHANGE_PASSWORD_ACTION_SUCCESS: {
      return {
        ...state,
        isChangePasswordSuccess: true,
      };
    }
    case loginActions.CHANGE_PASSWORD_ACTION_ERROR: {
      return {
        ...state,
        isChangePasswordSuccess: false,
      };
    }
    case loginActions.CHANGE_PASSWORD_ACTION_RESET: {
      return {
        ...state,
        isChangePasswordSuccess: false,
      };
    }
    case loginActions.EDIT_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          first_name: action?.payload?.first_name,
          last_name: action?.payload?.last_name,
          isProfileDetailsEdited: true,
        },
      };
    }
    case loginActions.EDIT_PROFILE_DETAILS_ERROR: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isProfileDetailsEdited: false,
        },
      };
    }
    case loginActions.EDIT_PROFILE_DETAILS_RESET: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isProfileDetailsEdited: false,
        },
      };
    }
    case loginActions.LOGOUT_ACTION_SUCCESS: {
      return {
        ...state,
        isLogoutActionSuccess: true,
      };
    }
    case loginActions.LOGOUT_ACTION_ERROR: {
      return {
        ...state,
        isLogoutActionSuccess: false,
      };
    }
    case loginActions.LOGOUT_ACTION_RESET: {
      return {
        ...state,
        isLogoutActionSuccess: false,
      };
    }
    default:
      return state;
  }
};

export default proctorReducer;
