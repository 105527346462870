//venders
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import localStorage from 'redux-persist/es/storage';
import { useTheme } from 'iiht-proctoring-ui-components/build/components/Styles';
import CardHeader from 'iiht-proctoring-ui-components/build/components/CardHeader';
import AppBar from 'iiht-proctoring-ui-components/build/components/AppBar';
import Box from 'iiht-proctoring-ui-components/build/components/Box';
import Toolbar from 'iiht-proctoring-ui-components/build/components/Toolbar';
import IconButton from 'iiht-proctoring-ui-components/build/components/IconButton';
import Typography from 'iiht-proctoring-ui-components/build/components/Typography';
import Menu from 'iiht-proctoring-ui-components/build/components/Menu';
import Container from 'iiht-proctoring-ui-components/build/components/Container';
import Avatar from 'iiht-proctoring-ui-components/build/components/Avatar';
import Badge from 'iiht-proctoring-ui-components/build/components/Badge';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from 'iiht-proctoring-ui-components/build/components/Menu/MenuItem';
import Link from 'iiht-proctoring-ui-components/build/components/Link';
import { placeParams } from '../../../utils/helper';
import { ROUTES } from '../../../constants/routesConstants';
import NotificationSvg from '../../../icons/Notification';
import SettingsSvg from '../../../icons/Settings';
import LogoutSvg from '../../../icons/Logout';
import { Role } from '../../../data/roles';
import ProctorLogo from '../../../icons/ProctorLogo';
import {
  loginUserReset,
  logoutUserAction,
  logoutUserReset,
} from '../../../store/actions';
import { setNotification } from '../../../store/actions/notificationActions';
import HeaderIcons from './headerIcons';
import { endAssessmentsReset } from '../../../store/actions/assessmentActions';
import { Logo } from '../../../icons/Logo';
import { getWaitingCandidateList } from '../../../store/api/proctoring';
import { useWebSocket } from '../../../customHooks';
import { translator } from '../../../constants/actionTypes';

//css
import './headerStyle.scss';

const HeaderView = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  let history = useHistory();
  const { tenant, id } = useParams();
  const location = useLocation();
  const [notifications, setNotifications] = useState([])
  const [newUnseenNotifications, setNewUnseenNotifications] = useState(false);
  const notificationDuration = 1000 * 60 * 5;
  // const notificationElement = useRef();

  useEffect(() => {
    dispatch(endAssessmentsReset({}));
  }, []);

  const isProctorView =
    location.pathname.includes(
      placeParams(ROUTES.PROCTORING, { tenant, id })
    ) && location.search.includes(`?view=detail`);

  const { loginDetails, userDetails, isLogoutActionSuccess } = useSelector(
    ({ userAuthentication }) => userAuthentication
  );

  const [anchorElUser, setAnchorElUser] = useState(null);

  const getTimeOfNotification = () => {
    const d = new Date();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const onWebSocketOpen = useCallback((ws) => {
    console.log('onWebSocketOpen', ws);
  }, []);

  const onReceiveMessageOnNotificationChannel = useCallback((event) => {
    const data = JSON.parse(event.data);
    console.log(data, 'socket response')
    if (data?.message?.exam_id) {
      const updatedNotifications = [...notifications];
      const strTime = getTimeOfNotification();
      updatedNotifications.unshift({
        title: data?.message?.exam_name,
        description: data?.message?.message,
        exam_id: data?.message?.exam_id,
        time: strTime,
        type: data?.message?.type,
        email_id: data?.message?.email_id,
        test_taker_name: data?.message?.test_taker_name
      });
      setNotifications(updatedNotifications);
      setNewUnseenNotifications(true);

      dispatch(
        setNotification({
          data: `${data?.message?.exam_name} : ${data?.message?.type === 'verification_complete' ? data?.message?.email_id : ''} ${data?.message?.message}`,
          severity: 'info',
          duration: notificationDuration,
        })
      );
      // if (notificationElement?.current) {
      //   setAnchorElUser(notificationElement.current)
      // }
    }
  });

  if (userDetails?.id && userDetails?.role?.includes('approver')) {
    const url = translator.wsUrl;
    const notifiationWebSocket = useWebSocket(
      url,
      `${userDetails?.id}_verification`,
      () => { },
      onWebSocketOpen,
      onReceiveMessageOnNotificationChannel
    );
  }

  useEffect(() => {
    if (userDetails?.id && userDetails?.role?.includes('approver')) {
      getWaitingCandidates(userDetails?.id);
    }
  }, [userDetails.id])

  const getWaitingCandidates = async(id) => {
    const { status, data } = await getWaitingCandidateList(id);
    if (status >= 400 && status <= 599) {
      return;
    }
    if (data?.data?.length) {
      const initialNotifications = [];
      const strTime = getTimeOfNotification();
      data?.data.forEach(element => {
        initialNotifications.push({
          title: element.exam_name,
          description: data.status.message,
          exam_id: element.exam_id,
          time: strTime,
        });

        dispatch(
          setNotification({
            data: `${element.exam_name} : ${data.status.message}`,
            severity: 'info',
            duration: notificationDuration,
          })
        );
      });
      setNewUnseenNotifications(true);
      setNotifications(initialNotifications);
    }
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setNewUnseenNotifications(false);
    setAnchorElUser(null);
  };

  const onLogOut = () => {
    localStorage
      .getItem('refresh')
      .then((token) => {
        dispatch(logoutUserAction({ refresh: token }));
      })
      .catch((err) => console.log('logout error', err));
  };

  const handleLogout = () => {
    dispatch(logoutUserReset());

    history.push(placeParams(ROUTES.LOGIN, { tenant }));
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: theme.palette.info.light,
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.info.main}`,
        width: '36px',
        height: '36px',
        fontSize: theme.typography.fieldHeadings.fontSize,
        fontWeight: theme.typography.fieldHeadings.fontWeight,
      },
      children: name?.split(' ')[1]
        ? `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`
        : name?.split(' ')[0][0],
    };
  };

  useEffect(() => {
    if (isLogoutActionSuccess) {
      handleLogout();
    }
  }, [isLogoutActionSuccess]);

  return (
    <header>
      <AppBar position="fixed" color="primary" className="app-bar-header">
        <Container maxWidth="false" className="display-flex">
          <Toolbar disableGutters>
            <Box
              component="img"
              sx={{
                height: theme.mixins.logo.height,
                flex: 1,
              }}
              alt="logo"
              src={Logo}
            />
          </Toolbar>
          {isProctorView && <HeaderIcons />}
          <div className="notification-section">
            <Box
              sx={{
                ml: '20px',
                display: { md: 'flex' },
              }}
            >
              {/* <div ref={notificationElement}></div> */}
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {newUnseenNotifications && <span className="new-notifications"></span>}
                <Badge color="secondary">
                  <NotificationSvg />
                </Badge>
              </IconButton>
              <Menu
                sx={{ mt: '35px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorReference={"anchorEl"}
                PaperProps={{
                  style: {
                    maxHeight: '250px',
                    width: '250px',
                  },
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {notifications.map((item, index) => (
                  <MenuItem key={index} sx={{ borderBottom: `1px solid ${theme.palette.grey.P300}` }}>
                    <div style={{ width: '100%' }}>
                      {item?.exam_id ?
                        <Link textAlign="left" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: theme.typography.headline.fontSize, fontFamily: theme.typography.headline.fontFamily }} onClick={() => {
                          if (item?.type === 'verification_complete') {
                            history.push(placeParams(ROUTES.PROCTORING, { tenant, id: item?.exam_id }));
                          } else {
                            history.push(placeParams(ROUTES.VIEW_ASSESSMENT, { tenant, id: item?.exam_id }));
                          }
                          handleCloseUserMenu();
                        }}>{item?.title}</Link>
                        : <Typography textAlign="left" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: theme.typography.headline.fontSize, fontFamily: theme.typography.headline.fontFamily }}>{item?.time} {item?.title}</Typography>
                      }
                      <Typography textAlign="left" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: theme.typography.caption.fontSize, fontFamily: theme.typography.caption.fontFamily }}>{item?.description}</Typography>
                      { item?.email_id ? <Typography textAlign="left" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: theme.typography.caption.fontSize, fontFamily: theme.typography.caption.fontFamily }}>{item?.email_id} - {item?.test_taker_name}</Typography> : null }
                      <Typography textAlign="left" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: theme.typography.caption.fontSize, fontFamily: theme.typography.caption.fontFamily }}>{item?.time}</Typography>
                    </div>
                  </MenuItem>
                ))}
                {!notifications.length &&
                  <MenuItem>
                    <Typography textAlign="center">No Notifications</Typography>
                  </MenuItem>
                }
              </Menu>
            </Box>
            <Tooltip title={t('setting')}>
              <>
                <Box
                  className="qa-system-settings-button"
                  sx={{
                    ml: '20px',
                    cursor: 'pointer',
                    display: { md: 'flex' },
                  }}
                  onClick={() => {
                    history.push(
                      placeParams(ROUTES.PROFILESETTINGS, { tenant })
                    );
                  }}
                >
                  <SettingsSvg />
                </Box>
              </>
            </Tooltip>
            <Box sx={{ ml: '20px' }}>
              <CardHeader
                avatar={
                  <Avatar
                    alt={`${userDetails?.first_name} ${userDetails?.last_name}`}
                    {...stringAvatar(
                      `${userDetails?.first_name} ${userDetails?.last_name}`
                    )}
                  />
                }
                subheader={
                  userDetails?.role === Role.tenantadmin
                    ? userDetails?.is_primary
                      ? `${t(userDetails?.role)} (${t('primary')})`
                      : userDetails?.role
                    : userDetails?.role
                }
                subheaderTypographyProps={{
                  fontSize: theme.typography.caption.fontSize,
                  fontFamily: theme.typography.caption.fontFamily,
                  color: theme.typography.headerCaption.color,
                }}
                title={`${userDetails?.first_name} ${userDetails?.last_name}`}
                titleTypographyProps={{
                  fontSize: theme.typography.headline.fontSize,
                  fontFamily: theme.typography.headline.fontFamily,
                  color: theme.typography.headerHeadline.color,
                }}
              />
            </Box>
            <Tooltip title={t('logout')}>
              <>
                <Box
                  className="qa-user-logout-button"
                  onClick={onLogOut}
                  sx={{
                    ml: '25px',
                    cursor: 'pointer',
                    display: { md: 'flex' },
                  }}
                >
                  <LogoutSvg />
                </Box>
              </>
            </Tooltip>
          </div>
        </Container>
      </AppBar>
    </header>
  );
};

export default HeaderView;
