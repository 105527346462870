import { notificationActions } from '../../constants/actionTypes';

export const setNotification = (data) => {
  return {
    type: notificationActions.SET_NOTIFICATION,
    payload: data,
  };
};

export const resetNotification = () => {
  return {
    type: notificationActions.RESET_NOTIFICATION,
  };
};
