import { approverAction } from '../../constants/actionTypes';

export const getRoomScanChecklist = (payload) => ({
  type: approverAction.GET_ROOM_SCAN_CHECK_LIST_ACTION,
  payload: payload,
});

export const getRoomScanChecklistSuccess = (payload) => ({
  type: approverAction.GET_ROOM_SCAN_CHECK_LIST_ACTION_SUCCESS,
  payload: payload,
});

export const getRoomScanChecklistError = (payload) => ({
  type: approverAction.GET_ROOM_SCAN_CHECK_LIST_ACTION_ERROR,
  payload: payload,
});

export const matchFaceScanData = (payload) => {
  return {
    type: approverAction.MATCH_FACE_SCAN_DATA,
    payload: payload,
  };
};
export const matchFaceScanDataSuccess = (payload) => {
  return {
    type: approverAction.MATCH_FACE_SCAN_DATA_SUCCESS,
    payload: payload,
  };
};
export const matchFaceScanDataFail = (payload) => {
  return {
    type: approverAction.MATCH_FACE_SCAN_DATA_FAIL,
    payload: payload,
  };
};
export const matchFaceScanDataReset = (payload) => {
  return {
    type: approverAction.MATCH_FACE_SCAN_DATA_RESET,
    payload: payload,
  };
};

export const getCandidateDetails = (payload) => {
  return {
    type: approverAction.GET_CANDIDATE_DETAILS,
    payload: payload,
  };
};
export const getCandidateDetailsSuccess = (payload) => {
  return {
    type: approverAction.GET_CANDIDATE_DETAILS_SUCCESS,
    payload: payload,
  };
};
export const getCandidateDetailsFail = (payload) => {
  return {
    type: approverAction.GET_CANDIDATE_DETAILS_FAIL,
    payload: payload,
  };
};
export const getCandidateDetailsReset = (payload) => {
  return {
    type: approverAction.GET_CANDIDATE_DETAILS_RESET,
    payload: payload,
  };
};

export const updateTestTakerVerificationStatus = (payload) => {
  return {
    type: approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS,
    payload: payload,
  };
};
export const updateTestTakerVerificationStatusSuccess = (payload) => {
  return {
    type: approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS_SUCCESS,
    payload: payload,
  };
};
export const updateTestTakerVerificationStatusFail = (payload) => {
  return {
    type: approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS_FAIL,
    payload: payload,
  };
};
export const updateTestTakerVerificationStatusReset = (payload) => {
  return {
    type: approverAction.UPDATE_TEST_TAKER_VERIFICATION_STATUS_RESET,
    payload: payload,
  };
};
