import React from 'react';

function EndSession() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_12606"
        data-name="Group 12606"
        transform="translate(-604 -68)"
      >
        <circle
          id="Ellipse_715"
          data-name="Ellipse 715"
          cx="16"
          cy="16"
          r="16"
          transform="translate(608 72)"
          fill="#bc1b19"
        />
        <g
          id="Icon_Button_40"
          data-name="Icon Button 40"
          transform="translate(604 68)"
        >
          <rect id="Base" width="40" height="40" fill="none" />
          <g
            id="ico_Broadcast_message"
            data-name="ico_Broadcast message"
            transform="translate(8 8)"
          >
            <rect
              id="Rectangle_4219"
              data-name="Rectangle 4219"
              width="24"
              height="24"
              fill="none"
            />
            <g id="bx-chat" transform="translate(1 1)">
              <path
                id="Path_43935"
                data-name="Path 43935"
                d="M5.547,19.187v3.2l1.286-.772,4.043-2.425h4.01a1.7,1.7,0,0,0,1.7-1.7V10.7a1.7,1.7,0,0,0-1.7-1.7H4.7A1.7,1.7,0,0,0,3,10.7v6.791a1.7,1.7,0,0,0,1.7,1.7ZM4.7,10.7H14.885v6.791H10.405l-3.161,1.9v-1.9H4.7Z"
                transform="translate(0 -2.604)"
                fill="#fff"
              />
              <path
                id="Path_43936"
                data-name="Path 43936"
                d="M20.885,3H10.7A1.7,1.7,0,0,0,9,4.7H19.187a1.7,1.7,0,0,1,1.7,1.7v6.791a1.7,1.7,0,0,0,1.7-1.7V4.7A1.7,1.7,0,0,0,20.885,3Z"
                transform="translate(-2.604)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EndSession;
